import React, { useState, useEffect } from 'react';

import { Avatar, Box, ListItem, Divider, ListItemAvatar, ListItemText, Slider } from '@mui/material/';

import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

import { useSnackbar } from 'notistack';


interface IEASToolsPerceptionOfInfluencersItemProps {
  influencer: IInfluencer;
  index: number;
  updateInfluencer:(Influencer : IInfluencer) => void;
}  


export const EASToolsPerceptionOfInfluencersItem: React.FC<IEASToolsPerceptionOfInfluencersItemProps> = ({ influencer, index, updateInfluencer }) => {

  const { enqueueSnackbar } = useSnackbar();

  const [itemInfluencer, setItemInfluencer] = useState<IInfluencer>();
  const [valueSider, setValueSider] = useState<number>(1);
  const [loading, setLoading] = useState(true);

  const colorGroupInfluencer = (()=> { 
    if(influencer.group_influencer){
      switch(true) {
          case (parseInt(influencer.group_influencer)>=9): return '#4D8000';
          case (parseInt(influencer.group_influencer)<=6): return '#c23531';
          default: return '#bbacac';
      }
    }
    else {
      return '#c4ccd3';
    }
    })();

  useEffect(() => {
    async function loadItemInfluencer() {
      if(influencer){
        setItemInfluencer(influencer);
        if(influencer.group_influencer){
          setValueSider(parseFloat(influencer.group_influencer));
        }
        setLoading(false);
      }
    }
    loadItemInfluencer();
  }, []);


  const handleClickEditDistanceChart = (newValue?:number | number[]) => {
    if (typeof newValue === 'number' && itemInfluencer) {
      setItemInfluencer({group_influencer: newValue.toString(),});   
    }
  };

  const handleClickEditDistanceStore = (newValue?:number | number[]) => {
    if (typeof newValue === 'number' && influencer) {
      if(newValue.toString() != influencer.group_influencer) {
        const Newinfluencer: IInfluencer = {
          id_influencer: influencer.id_influencer,
          id_inscricao: influencer.id_inscricao,
          id_equipe: influencer.id_equipe,
          id_mentoria_tean: influencer.id_mentoria_tean,
          name_influencer: influencer.name_influencer,
          order_influencer: influencer.order_influencer,
          positive_influencer: influencer.positive_influencer,
          negative_influencer: influencer.negative_influencer,
          distance_influencer: influencer.distance_influencer,
          group_influencer: newValue.toString(),
        };   
        InfluencersService.updateInfluencers(Newinfluencer)
        .then((influencerResponse) => {
          if (influencerResponse instanceof Error) {
            enqueueSnackbar('Erro ao salvar distanciamento do Influenciador.', { variant:'error'});
          } 
          else{
           updateInfluencer(Newinfluencer);
          }
        }).catch(() => {
          enqueueSnackbar('Erro ao atualizar Influenciador.', { variant:'error'});
        });
      }
    } 
  };

  return (
    <>
      {!loading && (
        <>            
          <ListItem
            sx={{padding: '10px 5px'}}
            key={influencer.id_influencer}
            secondaryAction={
              <Box style={{ display: 'flex', justifyContent:'center', width:'160px'}}>                 
                <Slider
                    aria-label="Distância"
                    defaultValue={valueSider}
                    valueLabelDisplay="auto"
                    step={1}
                    min={0}
                    max={10}
                    sx={{mt:1, mr:2}}
                    onChange={(event, value) => handleClickEditDistanceChart(value)}
                    onChangeCommitted={(event, value) => handleClickEditDistanceStore(value)}
                />
              </Box>
            }
          >
              <ListItemAvatar>
                <Avatar sx={{backgroundColor: colorGroupInfluencer}}>{ (index+10).toString(36).toUpperCase()}</Avatar>
              </ListItemAvatar>
              <ListItemText  primary={influencer.name_influencer} />
          </ListItem>
          <Divider  component="li" />
        </>
      )}
    </>
  );
};
