import { useState, useEffect } from 'react';
import { CircularProgress, Box, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, Link, Card }from '@mui/material';

import { IObjective, IObjectiveSample } from '../../contexts/ObjectiveContext/types';
import { useCourseContext , useTeamContext} from '../../contexts';

interface IObjectivesNewSampleProps {
  objectiveSample?: IObjectiveSample;
  saveObjectiveSample:(Objective : IObjective | boolean) => void;
}

  
export const EASObjectivesNewSample: React.FC<IObjectivesNewSampleProps> = ({ objectiveSample, saveObjectiveSample }) => {
  
  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();

  
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    function loadEditObjectiveDetail() {
      if(objectiveSample){
        setOpenDialog(true);
      }
      
    }
    loadEditObjectiveDetail();
  }, [objectiveSample]);


  const selectSample = (obs_objective_sample:string | boolean) => {
    setLoading(true);
    if(obs_objective_sample !== false && obs_objective_sample !== true) {
      const objective: IObjective = {
        id_objective: '',
        id_inscricao: (team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao),
        id_equipe: team.id_equipe,
        id_mentoria_tean: team.id_mentoria_tean,
        status_objective: '0',
        text_objective: objectiveSample?.name_objective_sample,
        date_objective: new Date().toLocaleDateString('pt-BR'),
        sample_objective: obs_objective_sample.toString(),
      }; 
      
      saveObjectiveSample(objective);
    }
    else {
      saveObjectiveSample(false);
    }
    setLoading(false);
    setOpenDialog(false);
  };

  return (
    <Dialog 
      open={openDialog}
      keepMounted
      onClose={() => setOpenDialog(false)}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <DialogTitle>{'Adicionar Ações Sugeridas'}</DialogTitle>
      <DialogContent>
        {loading && (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress variant='indeterminate' />
        </Box>
        )}
        {!loading && (
        <Box style={{ display: 'flex', justifyContent: 'center', padding:'8px 0 0 8px' }}>
          <Grid container style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0'}} spacing={2}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center'}}>
              <Typography sx={{textAlign: 'justify',  fontSize: '18px', marginBottom:'15px'}}>
                A competência &quot;<strong>{objectiveSample?.name_objective_sample}</strong>&quot; contém sugestões de ações que uma pessoa excelente nesta competência faria. Você pode adicionar estas sugestões em seu programa. 
              </Typography> 
            </Grid> 
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center'}}> 
              <Typography sx={{textAlign: 'justify',  fontSize: '18px', marginBottom:'15px'}}>
              <strong>Lembre-se de atribuir notas iniciais para cada ação, assim como definir as prioridades e excluir aquelas que não se aplicam à sua função.</strong>
              </Typography>  
            </Grid> 

            {objectiveSample?.obs_objective_sample && (objectiveSample.obs_objective_sample.map((obs_objective_sample) => (
            <Grid item key={obs_objective_sample} xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
              <Card sx={{p:'8px',textAlign:'center', width:'100%', border:'2px solid transparent', '&:hover':{borderColor:'#EA5B0C', cursor:'pointer'}}}>
                <Link onClick={() => selectSample(obs_objective_sample)} sx={{textAlign:'center', width:'100%'}} underline="none">
                  <Typography variant="h6" paragraph sx={{p:'0', m:'0', fontSize: '16px'}}>
                    Sim, adicione as ações sugeridas.  
                  </Typography>
                </Link>
              </Card>
            </Grid>  
          )))}  
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
              <Card sx={{p:'8px',textAlign:'center', width:'100%', border:'2px solid transparent', '&:hover':{borderColor:'#EA5B0C', cursor:'pointer'}}}>
                <Link onClick={() => selectSample('')} sx={{textAlign:'center', width:'100%'}} underline="none">
                  <Typography variant="h6" paragraph sx={{p:'0', m:'0', fontSize: '16px'}}>
                    Não, adicione apenas a competência.
                  </Typography>
                </Link>
              </Card>
            </Grid>  
          </Grid> 
        </Box> 
        )}
      </DialogContent>
      <DialogActions sx={{mr:2, mb:1}}>
        <Button onClick={() => selectSample(false)} variant="contained">Desistir</Button>
      </DialogActions>
    </Dialog>
  );
};
