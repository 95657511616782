import { IUser } from '../../../contexts/AuthContext/types';
import { Api } from '../axios-config';

interface IAuth extends IUser{
  authToken: string;
}

const auth = async (email: string, password: string): Promise<IAuth | Error> => {
  try {
    const { data } = await Api.post('/auth', { email:email, password:password } );

    if (data) {
      return data;
    }

    return new Error('Erro no login.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro no login.');
  }
};

const authTeams = async (email: string): Promise<IAuth | Error> => {
  try {
    const { data } = await Api.post('/authteams', { email:email });
    
    if (data) {
      return data;
    }

    return new Error('Erro no login.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro no login.');
  }
};


export const AuthService = {
  auth,
  authTeams
};
