import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDrawerContext, useAuthContext } from '../shared/contexts';
import {
  Dashboard,
  Objectives,
  TaskPlan,
  Learning,
  Influencers,
  Tools,
  ToolsDetail,
  EvaluationPartial,
  EvaluationPartialRelatory,
  EvaluationPartialEdit,
  EvaluationFinal,
  Presentations,
  ResultsPresentationPrint,
  ResultsPresentation,
  Chat,
  Files,
  Videos,
  VideosDetail,
  Weblinks,
  Tips,
  Library,
} from '../pages';



export const AppRoutes = () => {
  const { setDrawerOptions } = useDrawerContext();
  const { ...user } = useAuthContext();

  
  useEffect(() => {
      setDrawerOptions([
        {
          icon: 'home',
          path: '/pagina-inicial',
          label: 'Página inicial',
        },
        {
          icon: 'adjust',
          path: '/detalhamento-de-objetivos',
          label: 'Detalhamento de Objetivos',
        },
        {
          icon: 'task',
          path: '/plano-de-tarefas',
          label: 'Plano de Tarefas',
        },
        {
          icon: 'historyEdu',
          path: '/diario-de-aprendizagem',
          label: 'Diário de Aprendizagem',
        },
        /*{
          icon: 'hail',
          path: '/influenciadores-e-rotulos',
          label: 'Influenciadores e Rótulos',
        },*/
        {
          icon: 'connect_without_contact',
          path: '/ferramentas-auxiliares',
          label: 'Ferramentas Auxiliares',
        },
        {
          icon: 'person_search',
          path: '/avaliacao-parcial',
          label: 'Avaliação Parcial',
        },
        {
          icon: 'how_to_reg',
          path: '/avaliacao-final',
          label: 'Avaliação Final',
        },
        {
          icon: 'co_present',
          path: '/apresentacoes',
          label: 'Apresentações',
        },
        {
          icon: 'video_library',
          path: '/biblioteca',
          label: 'Biblioteca',
        },
      ]);
   /* else
    setDrawerOptions([
      {
        icon: 'home',
        path: '/pagina-inicial',
        label: 'Página inicial',
      },
      {
        icon: 'adjust',
        path: '/detalhamento-de-objetivos',
        label: 'Detalhamento de Objetivos',
      },
      {
        icon: 'task',
        path: '/plano-de-tarefas',
        label: 'Plano de Tarefas',
      },
      {
        icon: 'historyEdu',
        path: '/diario-de-aprendizagem',
        label: 'Diário de Aprendizagem',
      },
      {
        icon: 'person_search',
        path: '/avaliacao-parcial',
        label: 'Avaliação Parcial',
      },
      {
        icon: 'how_to_reg',
        path: '/avaliacao-final',
        label: 'Avaliação Final',
      },
      {
        icon: 'co_present',
        path: '/apresentacoes',
        label: 'Apresentações',
      },
      {
        icon: 'video_library',
        path: '/biblioteca',
        label: 'Biblioteca',
      },
    ]);*/
      
  }, []);

  return (
    <Routes>
      <Route path="/pagina-inicial" element={<Dashboard />} />
      <Route path="/detalhamento-de-objetivos" element={<Objectives />} />
      <Route path="/plano-de-tarefas" element={<TaskPlan />} />
      <Route path="/diario-de-aprendizagem" element={<Learning />} />
      <Route path="/influenciadores-e-rotulos" element={<Influencers />} />
      <Route path="/ferramentas-auxiliares" element={<Tools />} />
      <Route path="/ferramentas-auxiliares/influenciadores-de-reputacao" element={<ToolsDetail />} />
      <Route path="/ferramentas-auxiliares/mapa-de-proximidade" element={<ToolsDetail />} />
      <Route path="/ferramentas-auxiliares/mapeamento-dos-rotulos" element={<ToolsDetail />} />
      <Route path="/ferramentas-auxiliares/score-dos-influneciadores" element={<ToolsDetail />} />
      <Route path="/avaliacao-parcial" element={<EvaluationPartial />} />
      <Route path="/avaliacao-parcial/resultados-consolidados" element={<EvaluationPartialRelatory />} />
      <Route path="/avaliacao-parcial/detalhes/:id" element={<EvaluationPartialEdit />} />
      <Route path="/avaliacao-final" element={<EvaluationFinal />} />
      <Route path="/apresentacoes" element={<Presentations />} />
      <Route path="/apresentacoes/apresentacao-dos-resultados" element={<ResultsPresentation />} />
      <Route path="/apresentacoes/imprimir-resultados" element={<ResultsPresentationPrint />} />
      <Route path="/dicas" element={<Tips />} />
      <Route path="/chat" element={<Chat />} />
      <Route path="/videos" element={<Videos />} />
      <Route path="/arquivos" element={<Files />} />
      <Route path="/weblinks" element={<Weblinks />} />
      <Route path="/biblioteca" element={<Library />} />
      <Route path="/video/:id" element={<VideosDetail />} />
      <Route path="*" element={<Navigate to="/pagina-inicial" />} />
    </Routes>
  );
};
