import { useState, useEffect } from 'react';
import { CircularProgress, Box, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button }from '@mui/material';

import { useSnackbar } from 'notistack';

import * as yup from 'yup';

import { IObjective} from '../../contexts/ObjectiveContext/types';
import { ObjectiveService } from '../../services/api/objective/ObjectiveService';

interface IObjectiveEditEditProps {
  objective: IObjective | null;
  finishEditObjective:(Objective : IObjective) => void;
}

export const EASObjectivesEdit: React.FC<IObjectiveEditEditProps> = ({ objective, finishEditObjective }) => {
  
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [editObjective, setEditObjective] = useState<IObjective>();
  const [textEditObjective, setTextEditObjective] = useState<string>('');
  const [textEditObjectiveError, setTextEditObjectiveError] = useState<string>('');

  const objectiveEditSchema = yup.object().shape({
    textEditObjective: yup.string().required('Escreva a Competência'),
  });

  useEffect(() => {
    function loadEditObjective() {
      if(objective){
        setEditObjective(objective);
        if(objective.text_objective)
          setTextEditObjective(objective.text_objective);
        setOpenDialog(true);
      }
      
    }
    loadEditObjective();
  }, [objective]);




  const dialogSave = () => {
    
    if (!loading) {
      
    objectiveEditSchema
      .validate({ textEditObjective }, { abortEarly: false })
      .then(validateEditValues => {
        if(editObjective) {
          setSuccess(false);
          setLoading(true);
          const objective: IObjective = {
            id_objective: editObjective.id_objective,
            text_objective: validateEditValues.textEditObjective,
          };
          ObjectiveService.updateObjective(objective)
          .then((objectiveResponse) => {
            if (objectiveResponse instanceof Error) {
              setLoading(false);
              setSuccess(false);
              enqueueSnackbar('Erro ao atualizar competência.', { variant:'error'});
            } else {
              setLoading(false);
              setSuccess(true);
              enqueueSnackbar('Competência atualizada com sucesso!', { variant:'success'});
              setOpenDialog(false);
              finishEditObjective(objective);
              //if(allObjectives){
              //  const newAllObjectives = allObjectives.filter((objective) => objective.id_task_plan !== editObjective.id_task_plan);
               // setAllObjectives(newAllObjectives);
              //} 
            }
          }).catch((error) => {
            //alert(objective);
            setLoading(false);
            setSuccess(false);
            enqueueSnackbar('Erro ao atualizar competência.', { variant:'error'});
          });
        }
        else{
          setLoading(false);
          setSuccess(false);
          enqueueSnackbar('Erro ao atualizar competência.', { variant:'error'});
        }
      })
      .catch((errors: yup.ValidationError) => {
        setLoading(false);
        //enqueueSnackbar('Erro ao cadastrar tarefa.', { variant:'error'});
        errors.inner.forEach(error => {
          if (error.path === 'textObjective') {
            setTextEditObjectiveError(error.message);
          } 
        });
      });
    }
  };

  return (
    <Dialog 
      open={openDialog}
      keepMounted
      onClose={() => setOpenDialog(false)}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <DialogTitle>{'Editar Competência'}</DialogTitle>
      <DialogContent>
        {loading && (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress variant='indeterminate' />
        </Box>
        )}
        {!loading && (
        <Box style={{ display: 'flex', justifyContent: 'center', padding:'8px 0 0 8px' }}>
          <TextField
            label="Competência"
            value={textEditObjective}
            onChange={(event) => setTextEditObjective(event.target.value)}
            onFocus={() => setTextEditObjectiveError('')}
            fullWidth
            error={!!textEditObjectiveError}
            helperText={textEditObjectiveError}
            InputProps={{ inputProps: { min: 0, max: 10 } }}
          />      
        </Box> 
        )}
      </DialogContent>
      <DialogActions sx={{mr:2, mb:1}}>
        <Button onClick={() => setOpenDialog(false)} variant="contained">Cancelar</Button>
        <Button onClick={dialogSave} variant="contained" autoFocus>Salvar</Button>
      </DialogActions>
    </Dialog>
  );
};
