import { Box, styled, Container,  Typography, LinearProgress } from '@mui/material';
import { useConnectContext, useAppThemeContext } from '../../contexts';
import { EASCard } from '../eas-card/EASCard';

interface IEASConnectProps {
  children: React.ReactNode;
}
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 640,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  //flexDirection: 'column',
  textAlign: 'center', 
  alignItems: 'center',
  //padding: theme.spacing(12, 0)
}));

export const EASConnect: React.FC<IEASConnectProps> = ({ children }) => {

  //const isConnected = true;
  const { themeName } = useAppThemeContext();
  const { isConnected } = useConnectContext();

  if (isConnected === true) 
  return (
    <>{children}</>
  );

  if (isConnected === false) 
  return (
    <Box width='100vw'  display='flex' alignItems='center' justifyContent='center' sx={{overflowX:'hidden',}}>
      <Container>
        <ContentStyle >
          <EASCard>
            <Typography variant="h4" paragraph>
              Problemas na conexão!
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Pedimos desculpas, mas não estamos conseguindo conectar com o servidor de serviços do aplicatvo.
              Tente novamente mais tarde. Caso o problema persista, informe o proprietário do aplicativo.
            </Typography>
            <Box
              component="img"
              src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
              sx={{  mx: 'auto', my: { xs: 5, sm: 5 } }}
            />
          </EASCard>
        </ContentStyle>
      </Container>
    </Box>
  );

  //handleConnect();
  return (
    <Box width='100vw' display='flex' alignItems='center' justifyContent='center' sx={{overflowX:'hidden',}}>
      <Container>
        <ContentStyle>
          <EASCard>
            <Typography variant="h4" paragraph>
              Conectando com o servidor!
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>
              Aguarde uns instantes, estamos conectando com o servidor de serviços do aplicatvo.
              Este processo näo deve demorar mais que alguns segundos.
            </Typography>

            <Box
              component="img"
              src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
              sx={{  mx: 'auto', my: { xs: 5, sm: 5 } }}
            />
            <LinearProgress color="primary" />
          </EASCard>
        </ContentStyle>
      </Container>
    </Box> 
  );
  
};

