import { LayoutBaseDePagina } from '../../shared/layouts';
import { EASFiles } from '../../shared/components';

export const Files = () => {
    return (
        <LayoutBaseDePagina
        titulo='&nbsp;Arquivos'
        iconTitle='file_present'
        >
            <EASFiles />
        </LayoutBaseDePagina>
        );
};