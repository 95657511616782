import { styled, Card, CardHeader, CardContent, CardMedia, CardActions, Typography, useTheme } from '@mui/material';


const SBDCCard = styled(Card)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  //margin: '10px'
}));

const SBDCCardHeader = styled(CardHeader) ({
  backgroundColor: '#333333',
  textAlign: 'center',
});

const TypographySBDC = styled(Typography) ({
  fontSize: '12px !important',
  color:'#c8c8c8',
  padding: 0,
  margin: 0,
});

interface IEASCard {
  titleCard?: string;
  children: React.ReactNode;
}

export const EASCard:React.FC<IEASCard> = ({ children, titleCard}) => {

  const theme = useTheme();
  //const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  
  //const { isDrawerOpen, drawerOptions, toggleDrawerOpen } = useDrawerContext();
  //const { toggleTheme } = useAppThemeContext();
  
  return (
    <SBDCCard sx={{ boxSizing : 'border-box', borderLeft: !titleCard ? '8px solid #EA5B0C' : 'none'}}>
      {(titleCard) && (
        <SBDCCardHeader
          title={<TypographySBDC>{titleCard}</TypographySBDC>}
        />
      )}
      <CardContent>
        {children}
      </CardContent>
    </SBDCCard>
  );
};