import React, {useState} from 'react';
import { Avatar, Divider, Drawer, List, Box, Typography, useMediaQuery, useTheme, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import { useNavigate, useLocation} from 'react-router-dom';

import { useSnackbar } from 'notistack';
import { IEvaluationFinal } from '../../contexts/EvaluationFinalContext/types';
import { EvaluationFinalService } from '../../services/api/evaluation-final/EvaluationFinalService';
import { useDrawerContext, useAuthContext, useCourseContext, useTeamContext } from '../../contexts';
import { Environment } from '../../environment';
import { EASBtnSidebar } from '../eas-btn-sidebar/EASBtnSidebar';
import { EASBoxSidebar } from '../eas-box-sidebar/EASBoxSidebar';

interface IListItemLinkProps {
  to: string;
  icon: string;
  label: string;
  onClick: (() => void) | undefined;
}

const ListItemLink: React.FC<IListItemLinkProps> = ({ to, icon, label, onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to);
    onClick?.();
  };

  return (
    <EASBtnSidebar to={to} icon={icon} label={label} onClick={handleClick}></EASBtnSidebar>
  );
};
interface ISidebarProps {
  children: React.ReactNode;
}

export const EASSidebar: React.FC<ISidebarProps> = ({ children }) => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { ...user } = useAuthContext();
  const { ...course } = useCourseContext();
  const { ...team } = useTeamContext();
  const [open, setOpen] = useState(false);

  const { isDrawerOpen, drawerOptions, toggleDrawerOpen } = useDrawerContext();
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const handleReset = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirme = () => {
    setOpen(false);
   // setIsLoading(true);
    const finishEvaluationFinal: IEvaluationFinal = {
      id_inscricao: (team.type_tean=='1' ? team.id_inscricao : course.id_inscricao),
      id_equipe: team.id_equipe,
      id_mentoria_tean: team.id_mentoria_tean,
      status_evaluation_final: '5',
    }; 
    EvaluationFinalService.updateEvaluationFinal(finishEvaluationFinal).then((responseEvaluationFinal) => {
      if (responseEvaluationFinal instanceof Error) {
        enqueueSnackbar('Erro ao tentar reabrir programa', { variant:'error'});
        //setIsLoading(false);
      } else {
        if(team.type_tean=='1'){
          team.status_mentory_inscricao = '0';
          team.accessTeam(team);
        }
        else{
          course.status_mentory_inscricao = '0';
          course.accessCourse(course);
        }
          
        //setUpdateFinish(!updateFinish);
        enqueueSnackbar('Programa reaberto com sucesso.', { variant:'success'});
      }
    });
  };



  return (
    <>
    {location.pathname != '/apresentacoes/imprimir-resultados' && location.pathname != '/apresentacoes/apresentacao-dos-resultados'? (
    <>
      <Drawer open={isDrawerOpen} variant={mdDown ? 'temporary' : 'permanent'} onClose={toggleDrawerOpen}>
        <Box width={theme.spacing(38)} height="100%" display="flex" flexDirection="column">
          <Box width="100%" sx={{ mt: theme.spacing(1)}}  display="flex" alignItems="center" justifyContent="center">
            <Avatar
              sx={{ border:'3px solid #EA5B0C', height: theme.spacing(10), width: theme.spacing(10) }}
              src={user.avatar && ('https://online.sbdc.com.br/media/images/avatar/' + user.avatar)}

            />
          </Box>
          <Box width="100%" display="flex" alignItems="center" justifyContent="center">
            <Typography sx={{mb:'0px'}}>
              {user.name}
            </Typography>
          </Box>
          <Box width="100%" display="flex" alignItems="center" justifyContent="center">
            <Typography sx={{mb:'5px',fontSize:'12px'}}>
              {course.name_evento}
            </Typography>
          </Box>
         { /*
          <Box width="100%" display="flex" alignItems="center" justifyContent="center">
            <Typography sx={{mb:theme.spacing(3),fontSize:'12px'}}>
              Curso Iniciado em 10/05/2022
            </Typography>
          </Box>
          */ }
          <Divider />
          <Box width="100%" display="flex" alignItems="center" justifyContent="center">
            <EASBoxSidebar onClick={mdDown ? toggleDrawerOpen : undefined} />
          </Box>
          {(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && course.status_mentory_inscricao =='1' ) && (
          <Box width="100%" display="block" sx={{textAlign:'center'}} alignItems="center" justifyContent="center">
            <Typography sx={{mt:theme.spacing(1),fontSize:'12px'}}>
              Este programa foi encerrado
            </Typography>
            <Button variant="contained" onClick={handleReset} sx={{ mb: 1, mr: 1 }}>
                Reabrir Programa
            </Button>
          </Box>
          )}
          <Box flex={1}>
            <List component="nav">
              {drawerOptions.map(drawerOption => (
                <ListItemLink
                  to={drawerOption.path}
                  key={drawerOption.path}
                  icon={drawerOption.icon}
                  label={drawerOption.label}
                  onClick={mdDown ? toggleDrawerOpen : undefined}
                />
              ))}
            </List>
          </Box>
          <Box flex={1}>
            <Box width="100%" display="flex" alignItems="center" justifyContent="center">
              <Typography sx={{mb:'0px',fontSize:'10px'}}>
                V:{Environment.VERSION}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Box height="100vh" marginLeft={mdDown ? 0 : theme.spacing(38)}>
        {children}
      </Box>

      {(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && course.status_mentory_inscricao =='1' ) && (
        <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            {'Reabrir Programa'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Você tem certeza que deseja reabrir o programa que foi finalizado pelo mentorado ?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{mr:2, mb:1}}>
            <Button onClick={handleCancel} variant="contained">Desistir</Button>
            <Button onClick={handleConfirme} variant="contained" autoFocus>
              Sim, Reabrir
            </Button>
          </DialogActions>
        </Dialog>    
      )}  

    </>
    ):(
      <Box height="100vh">
        {children}
      </Box>
    )}
    </>
  );
};
