
import React, { useState , useEffect } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import {Box, Grid, Typography, Container, Alert, List, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle  } from '@mui/material/';

import { EASInfluencersListAdd } from './EASInfluencersListAdd';
import { EASInfluencersListEdit } from './EASInfluencersListEdit';
import { EASInfluencersListItem } from './EASInfluencersListItem';
import { EASInfluencersListChart } from './EASInfluencersListChart';

import { useTeamContext, useCourseContext } from '../../contexts';

import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

import { useSnackbar } from 'notistack';

interface IColumnItem {
  id?: string;
  list?: Array<IInfluencer>;
}  

interface IColumnGroup extends IColumnItem {
  Influenciadores: IColumnItem;
}  




interface IInfluencersListProps {
  allInfluencers: Array<IInfluencer> | null;
  setAllInfluencers:(allInfluencers : Array<IInfluencer>) => void;
  updateList:() => void;
}  

export const EASInfluencersList: React.FC<IInfluencersListProps> = ({ allInfluencers, updateList, setAllInfluencers }) => {

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [idInfluencer, setIdInfluencer] = useState<string | null>(null);
  const [influencer, setInfluencer] = useState<IInfluencer | null>(null);
  const [collumn, setColumn] = useState<IColumnItem>({id:'Influenciadores',list: [],});

  useEffect(() => {
    async function getInfluencers() {
      const newColunm : IColumnItem ={id:'Influenciadores',list: [],};
      if(allInfluencers){
        allInfluencers.map(influencer => {
            newColunm.list?.push(influencer);
        }); 
      }
      setColumn(newColunm);
      setLoading(false);
    }
    getInfluencers();
  }, [team.id_equipe]);

  const handleClickCancelEdit = () => {
      setInfluencer(null);
  };

  const handleClickDelete  = (id_influencer?:string) => {
    if(id_influencer){
      setIdInfluencer(id_influencer);
      setOpen(true);
    }
  }; 

  const handleConfirme = () => {
    deleteInfluencer();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setIdInfluencer(null);
  };
  
  const deleteInfluencer = () => {

    if(idInfluencer){
      InfluencersService.deleteInfluencers(idInfluencer).then((influencer) => {
        if (influencer instanceof Error) {
          setIdInfluencer(null);
          setInfluencer(null);
          enqueueSnackbar('Erro ao tentar remover o Influenciador!', { variant:'error'});
        } else {
            updateList();
            enqueueSnackbar('Influenciador removido com sucesso!', { variant:'success'});
            setIdInfluencer(null);   
        }
      });
    }
  };

  const uploadOrder = ( influencer:IInfluencer, newOrder:number) => {

    if(influencer && newOrder) {
    
      const moveInfluencer: IInfluencer = {
        id_influencer: influencer.id_influencer,
        id_inscricao: influencer.id_inscricao,
        id_equipe: influencer.id_equipe,
        id_mentoria_tean: influencer.id_mentoria_tean,
        order_influencer: newOrder.toString(),
        distance_influencer: influencer.order_influencer,
      };    
      InfluencersService.moveInfluencer(moveInfluencer)
        .then((influencerResponse) => {
          if (influencerResponse instanceof Error) {
            enqueueSnackbar('Erro ao mover influenciador.', { variant:'error'});
          } 
          else {
            influencer.order_influencer = newOrder.toString();
            updateInfluencer(influencer);
          }
        }).catch(() => {
          enqueueSnackbar('Erro ao mover influenciador.', { variant:'error'});
      });
    }
    else{
      enqueueSnackbar('Erro ao mover influenciador.', { variant:'error'});
    }
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;
    if (source === undefined || source === null) return null;
    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    ) return null;

    const start = (collumn as any);   
    const end = (collumn as any);
  
    
    uploadOrder(start.list[source.index], destination.index );

    const newList = start.list.filter(
      (_: any, idx: number) => idx !== source.index
    );

    //alert(JSON.stringify(start));

      // Then insert the item at the right location
    newList.splice(destination.index, 0, start.list[source.index]);
    
    //alert(JSON.stringify(newList));

    // Then create a new copy of the column object
    const newCol = {
      id: start.id,
      list: newList
    };

    // Update the state
    setColumn(newCol);
    //setColumn(state => ({ ...state, [newCol.id]: newCol }));
    return null;


      

  };

  const addInfluencer = () => {
    updateList(); 
  };

  const updateAllInfluencers = () => {
      updateList();
  };


  const updateInfluencer = (influencerUpdate?:IInfluencer) => {
    if (influencerUpdate && allInfluencers) {
      const newAllInfluencers = allInfluencers.map(influencer => {
        if (influencerUpdate.id_influencer !== influencer.id_influencer) {
          return influencer;
        } else {
          return influencerUpdate;
        }
      });
      setAllInfluencers(newAllInfluencers);
    }
  };



  return (
    <Container>
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" sx={{textAlign: 'center', marginBottom:'5px'}}>
          Quem são as pessoas que influenciam em sua reputação na organização e o quão próximo você está delas?
        </Typography>
      </Box>
      <Grid container alignItems="center" spacing={2} sx={{marginTop:'-10px', width:'100%'}}>
        <Grid item xs={12} sm={6}  md={6}  lg={6} xl={6} >
          {(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' ) ? (
          <Box sx={{ p: 3 }}>
            <Typography variant="h5">Este programa foi encerrado</Typography>
            {((team.type_tean === '0')  && (
              <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
            ))}
          </Box>
          ):(
          <Box sx={{ p: 3 }}>
            <EASInfluencersListAdd addInfluencer={() => addInfluencer()} />
          </Box>
          )}
          <EASInfluencersListEdit finishEditInfluencer={() => updateAllInfluencers()} cancelEditInfluencer={() => handleClickCancelEdit()} influencer={influencer}  />
          <List>
            <DragDropContext onDragEnd={onDragEnd}>
              {!loading && collumn.id ?  (
              <Droppable droppableId={collumn.id}>
                {provided => (
                  <div  {...provided.droppableProps} ref={provided.innerRef}>
                  {collumn.list && collumn.list.length > 0 && (collumn.list.map((influencer, index) => (
                    <EASInfluencersListItem 
                      index={index}
                      key={influencer.id_influencer} 
                      influencer={influencer} 
                      setInfluencer={(e) => setInfluencer(e)} 
                      handleClickDelete={(e) => handleClickDelete(e)}
                      updateInfluencer={(e) => updateInfluencer(e)}
                    />
                  )))}

                  {provided.placeholder}
                  </div>
                )}
              </Droppable>
              ): (
              <Alert severity="warning">Nenhum influenciador cadastrado!</Alert>
              )}
            </DragDropContext>
          </List>
        </Grid>
        <Grid item xs={12} sm={6}  md={6}  lg={6} xl={6} sx={{height: '100%'}}>
          <EASInfluencersListChart allInfluencers={allInfluencers} />
        </Grid>
      </Grid>
      <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {'Excluir Influenciador'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Você tem certeza que deseja excluir este Influenciador?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{mr:2, mb:1}}>
          <Button onClick={handleCancel} variant="contained">Desistir</Button>
          <Button onClick={handleConfirme}  variant="contained" autoFocus>
            Sim, Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};