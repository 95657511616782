import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IEvaluationPartialDetail, IEvaluationPartialDetailResponse } from '../../../contexts/EvaluationPartialContext/types';

interface IEvaluationPartialDetailService {
  Teams?: string;
}

interface IEvaluationPartialDetailListResponse extends IEvaluationPartialDetail {
  id_evaluation_partial?: string;
  type_evaluation_partial?: string;
  mail_evaluation_partial?: string;
  date_evaluation_partial?: string;
  text_evaluation_partial?: string;
  status_evaluation_partial?: string;
  total?: number | null;
  data?: IEvaluationPartialDetailResponse | null;
}


const createEvaluationPartialDetail = async (evaluationPartialDetail: object): Promise<IEvaluationPartialDetailService | Error> => {
  try {
    const { data } = await Api.post('/evaluationpartialdetail', { evaluationPartialDetail: evaluationPartialDetail } );
  
    if (data) 
      return data;
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateEvaluationPartialDetail = async (evaluationPartialDetail: IEvaluationPartialDetail): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/evaluationpartialdetail/${evaluationPartialDetail.id_evaluation_partial_detail}`, evaluationPartialDetail);
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const deleteEvaluationPartialDetail = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api.delete(`/evaluationpartialdetail/${id}`);
    if (data) 
      return data;   
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getEvaluationPartialDetail = async (userEnrollment: string, client_id: string): Promise<IEvaluationPartialDetailService | Error> => {
  try {
    const { data } = await Api.post('/evaluationpartial', { userEnrollment: userEnrollment, client_id: client_id } );
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const getAllEvaluationPartialDetail = async (id_inscricao = '', id_equipe = '', id_mentoria_tean = '', id_evaluation_partial_detail = '', filterEvaluationPartial = 0, page = 1, filter = '' ): Promise<IEvaluationPartialDetailListResponse | Error> => {
  try {
    if(!id_inscricao || !id_equipe || !id_mentoria_tean)
      return new Error('Requisição imcompleta.');

    
    const urlRelativa = `/evaluationpartialdetail?public_token=${Environment.PUBLIC_TOKEN}&i=${id_inscricao}&e=${id_equipe}&t=${id_mentoria_tean}&d=${id_evaluation_partial_detail}&f=${filterEvaluationPartial}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${filter}`;

    const  {data} = await Api.get(urlRelativa);
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

export const EvaluationPartialDetailService = {
  getAllEvaluationPartialDetail,
  getEvaluationPartialDetail,
  createEvaluationPartialDetail,
  updateEvaluationPartialDetail,
  deleteEvaluationPartialDetail,
};
