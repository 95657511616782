import React, { useState , useEffect } from 'react';
import {Paper, Typography, Box, Container, Button, LinearProgress,  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle  } from '@mui/material/';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { EASInfluencersList } from './EASInfluencersList';
import { EASInfluencersEvaluations } from './EASInfluencersEvaluations';
import { EASInfluencersGoups } from './EASInfluencersGoups';
import { EASInfluencersResultsCharts } from './EASInfluencersResultsCharts';

import { useCourseContext , useTeamContext } from '../../contexts';

import { IInfluencer } from '../../contexts/InfluencersContext/types';

import { InfluencersService } from '../../services/api/influencers/InfluencersService';

import { useSnackbar } from 'notistack';
import { EASCard } from '../eas-card/EASCard';

interface IInfluencersProps {
  children?: () => void;
}  

export const EASInfluencers: React.FC<IInfluencersProps> = () => {

  const { enqueueSnackbar } = useSnackbar();
  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();

  const [isLoading, setIsLoading] = useState(true);

  const [updateList, setUpdateList] = useState(false);

  const [allInfluencers, setAllInfluencers] = useState<Array<IInfluencer> | null>(null);
  const [totalCount, setTotalCount] = useState(0);

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    async function getInfluencers() {
      if(enrollment.id_inscricao){
        setIsLoading(true);
        const influencers = await InfluencersService.getAllInfluencers((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), team.id_equipe, team.id_mentoria_tean);
        if (influencers instanceof Error) {
          setAllInfluencers(null);
          setTotalCount(0);
          setIsLoading(false);
        } else {
          if(influencers.data && influencers.total){
            const myInfluencers:Array<IInfluencer> = JSON.parse(JSON.stringify(influencers.data));
            setAllInfluencers(myInfluencers);
            setIsLoading(false);
            if(influencers.total)
              setTotalCount(influencers.total);
              let a = 3;
              myInfluencers.map(influencerMap => {
                if (influencerMap.group_influencer == '0'|| influencerMap.group_influencer == undefined || influencerMap.group_influencer == null ) {
                  a = 0;
                } 
              });

              setActiveStep(a);
   

          }
          else{
            setAllInfluencers(null);
            setTotalCount(0);
            setIsLoading(false);
          }
        }
      }
    }
    getInfluencers();
  }, [updateList, team.id_equipe]);



  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <EASInfluencersList 
                  updateList={() => setUpdateList(!updateList)} 
                  allInfluencers={allInfluencers} 
                  setAllInfluencers={(e) => setAllInfluencers(e)} />;
      case 1:
        return <EASInfluencersEvaluations 
                  updateList={() => setUpdateList(!updateList)} 
                  allInfluencers={allInfluencers} 
                  setAllInfluencers={(e) => setAllInfluencers(e)} />;
      case 2:
        return <EASInfluencersGoups 
                  updateList={() => setUpdateList(!updateList)} 
                  allInfluencers={allInfluencers} 
                  setAllInfluencers={(e) => setAllInfluencers(e)} />;
                  
      default:
        throw new Error('Unknown step');
    }
  }
  
  const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
 
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleInit = () => {
    setActiveStep(0);
  };

  const steps = ['Definir Influenciadores', 'Descrever Rótulos', 'Definir  Grupos'];
  return (
    <Container>
      {isLoading && (
        <LinearProgress variant='indeterminate' sx={{ mt:'15px'}} />
      )}
      {!isLoading && (
      <Box style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0' }}>
        <EASCard>
          <Typography component="h1" variant="h5" align="center">
            Influenciadores de Reputação e Mapeamento dos Rótulos
          </Typography>
          <Typography variant="subtitle1" align="center">
            Aqui vai o texto dizendo o que representa esta ferramenta.
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 3 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <EASInfluencersResultsCharts allInfluencers={allInfluencers} />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  onClick={handleInit}
                  sx={{ mt: 3, ml: 1 }}
                >
                Ajustar Valores
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <Box>
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Etapa Anterior
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? 'Ver Resultados' : 'Próxima Etapa'}
                </Button>
              </Box>
            </React.Fragment>
            </Box>
          )}
          {(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' ) && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography variant="h5">Este programa foi encerrado</Typography>
              {((team.type_tean === '0')  && (
                <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
              ))}
            </Paper>
          )}
        </EASCard>
      </Box>
      )}
    </Container>
  );
};