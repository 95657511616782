import { AxiosRequestConfig } from 'axios';
import { Environment } from '../../../../environment';

export const requestInterceptor = (config: AxiosRequestConfig) => {
  
  const authorization =  localStorage.getItem(Environment.CLIENT_ID);

  if(authorization && authorization !== 'undefined'){
      config.headers = {
        Authorization: `Bearer ${authorization}`,
  //headers: { 'Api-key': Environment.PUBLIC_TOKEN },
    };
  }
    
  return config;
};
