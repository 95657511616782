import { createContext, useCallback, useEffect, useMemo, useContext, useState } from 'react';

import { CourseService } from '../../services/api/course/CourseService';

import { Environment } from '../../environment';
import { ICourseContextData, ICourseProviderProps, ICourse } from './types';

import { useAuthContext } from '..';

export const CourseContext = createContext<ICourseContextData>({} as ICourseContextData);

export const CourseProvider: React.FC<ICourseProviderProps> = ({ children }) => {
 
  const { ...user } = useAuthContext();

  const [idSigin, setIdSigin] = useState<string | null>(null);
  const [allCourses, setAllCourses] = useState<Array<ICourse> | null>(null);
  const [enrollment, setEnrollment] = useState<ICourse | null>(null);
  
  useEffect(() => {
    async function getCoruses() {
      
      if(user.id){
        const courses = await CourseService.getCourses(user.id, Environment.CLIENT_ID);
        if (courses instanceof Error) {
          setIdSigin('error');
          setEnrollment(null);
        } else {
          if(courses){
            const myCourses = JSON.parse(JSON.stringify(courses));
            
            setAllCourses(myCourses);
            
            if(Object.keys(myCourses).length > 1){
              setIdSigin(null);
              setEnrollment(null);
            }
            else{
              if(myCourses[0].id_inscricao){
                setEnrollment(myCourses[0]);
                setIdSigin(myCourses[0].id_inscricao);
              }
              else{
                setIdSigin('error');
                setEnrollment(null);
              }
            }
          }
          else{
            setIdSigin('error');
            setEnrollment(null);
          }
        }
      }
    }
    getCoruses();
  }, []);

  const handleCourse = useCallback(async (course: ICourse) => {
      if(course.id_inscricao){
        setIdSigin(course.id_inscricao);
        setEnrollment(course);
      }
  }, []);

  const isSelected = useMemo(() => ((idSigin == 'error') ? false : (!(idSigin) && !(allCourses) ? null : true )), [idSigin]);

  return (
    <CourseContext.Provider value={{ isSelected, allCourses, idSigin, ...enrollment, accessCourse:handleCourse }}>
      {children}
    </CourseContext.Provider>
  );
};

export const useCourseContext = () => useContext(CourseContext);

