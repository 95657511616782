
import React, { useState , useEffect, useMemo } from 'react';
import { useSearchParams} from 'react-router-dom';
import { Container, Pagination, Box, LinearProgress, Grid, Alert, TextField, Fab } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { EASWeblink } from './EASWeblink';
import { IWeblink } from '../../contexts/WeblinksContext/types';

import { EASCard } from '../eas-card/EASCard';
import { useCourseContext , useTeamContext} from '../../contexts';
import { Environment } from '../../environment';
import { WeblinksService } from '../../services/api/weblinks/WeblinksService';

export const EASWeblinks = () => {

  const [weblinks, setWeblinks] = useState<Array<IWeblink> | null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filterTaskPlan, setFilterTaskPlan] = useState<number>(0);
  const [updateList, setUpdateList] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState<string>('');

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();
  
  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  
  useEffect(() => {
    async function getWeblinks() {
      setIsLoading(true);
      if(enrollment.id_inscricao){
        const weblinks = await WeblinksService.getAllWeblinks((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), team.id_equipe, team.id_mentoria_tean, search, pagina);
        if (weblinks instanceof Error) {
          setWeblinks(null);
          setTotalCount(0);
          setIsLoading(false);
          setUpdateList(false);
        } else {
          if(weblinks.data && weblinks.total){
            const myWeblinks = JSON.parse(JSON.stringify(weblinks.data));
            setWeblinks(myWeblinks);
            setIsLoading(false);
            if(weblinks.total)
              setTotalCount(weblinks.total);
            setUpdateList(false);
          }
          else{
            setWeblinks(null);
            setTotalCount(0);
            setIsLoading(false);
            setUpdateList(false);
          }
        }
      }
    }
    getWeblinks();
  }, [filterTaskPlan, searchParams, updateList, team.id_equipe]);  


    
  return(
    <Container>
      <Box width="100%">
        <Box sx={{ display: 'flex', justifyContent: 'center', height: 'auto', marginBottom: '15px' }}>
          <EASCard>
            <Box style={{ display: 'flex', justifyContent: 'center', height: 'auto' }}>
              <TextField

                variant="outlined"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                fullWidth
                label={Environment.INPUT_DE_BUSCA}
                type={Environment.INPUT_DE_BUSCA}
              />
              <Box sx={{ m: 1, position: 'relative' }}>
                <Fab
                  size="small"
                  aria-label="save"
                  color="primary"
                  onClick={() => setSearchParams({ busca:search, pagina:'1' }, { replace: true })}
                >
                  {<SearchIcon />}
                </Fab>
              </Box>
            </Box>
          </EASCard>
        </Box>
        {isLoading && (
          <LinearProgress variant='indeterminate' />
        )}
        {!isLoading && (weblinks && weblinks.length>0 ? (
          <Grid container alignItems="center" spacing={2} sx={{ mb: 2}} >
          {weblinks.map((weblink, index) => (
            <Grid key={index} item xs={12} sm={12} sx={{width:'100%'}}>
              <EASWeblink weblink={weblink}/>
            </Grid>
          ))}
          </Grid>
        ) : (
          <Alert severity="warning">Nenhum weblinks encontrado!</Alert>
        ))}
        {!isLoading && totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
          <Box style={{ display: 'flex', justifyContent: 'center', padding:'20px 0 20px 0' }}>
            <Pagination
              page={pagina}
              count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
              onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
              variant="outlined" 
              shape="rounded"
              color="primary"
              siblingCount={3} 
              boundaryCount={1}
            />
          </Box>
        )}
      </Box>
    </Container>
  );
};