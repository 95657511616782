import { LayoutBaseDePagina } from '../../shared/layouts';
import { EASWeblinks } from '../../shared/components';

export const Weblinks = () => {
    return (
        <LayoutBaseDePagina
        titulo='&nbsp;Weblinks'
        iconTitle='screen_share'
        >
            <EASWeblinks />
        </LayoutBaseDePagina>
        );
};