import React, { useState , useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {Paper, Chip, Box, Pagination, Tabs, Tab, Typography, Container, styled , Alert, List, ListItem, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemText, ListItemSecondaryAction, IconButton, LinearProgress  } from '@mui/material/';

import { green } from '@mui/material/colors';

import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import AddTaskIcon from '@mui/icons-material/AddTask';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CoPresentIcon from '@mui/icons-material/CoPresent';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { EASTaskPlanNew } from '../eas-task-plan-new/EASTaskPlanNew';
import { EASTaskPlanEdit } from '../eas-task-plan-edit/EASTaskPlanEdit';

import { ITaskPlan } from '../../contexts/TaskPlanContext/types';
import { useCourseContext , useTeamContext} from '../../contexts';

import { Environment } from '../../environment';

import { TaskPlanService } from '../../services/api/task-plan/TaskPlanService';
import { useSnackbar } from 'notistack';

interface ITaskPlanProps {
  children?: () => void;
}  

const IconButtonChecked = styled(IconButton)(() => ({
  color: green[500]
}));


export const EASTaskPlan: React.FC<ITaskPlanProps> = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();
  
  const [open, setOpen] = useState(false);
  const [openFinish, setOpenFinish] = useState(false);
  
  const [updateList, setUpdateList] = useState(false);

  const [idTaskPlan, setIdTaskPlan] = useState<string | null>(null);
  const [allTaskPlans, setAllTaskPlans] = useState<Array<ITaskPlan> | null>(null);
  const [taskPlan, setTaskPlan] = useState<ITaskPlan | null>(null);
  const [taskPlanStore, setTaskPlanStore] = useState<ITaskPlan | null>(null);
  const [filterTaskPlan, setFilterTaskPlan] = useState<number>(0);
  const [totalCount, setTotalCount] = useState(0);

  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  useEffect(() => {
    async function getTaskPlans() {
      
      if(enrollment.id_inscricao){
        setIsLoading(true);
        const taskPlans = await TaskPlanService.getAllTaskPlan((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), team.id_equipe, team.id_mentoria_tean, filterTaskPlan, pagina);
        if (taskPlans instanceof Error) {
          setAllTaskPlans(null);
          setTotalCount(0);
          setIdTaskPlan('error');
          setTaskPlan(null);
          setIsLoading(false);
        } else {
          if(taskPlans.data && taskPlans.total){
            const myTaskPlans = JSON.parse(JSON.stringify(taskPlans.data));
            setAllTaskPlans(myTaskPlans);
            setIsLoading(false);
            if(taskPlans.total)
              setTotalCount(taskPlans.total);
          }
          else{
            setAllTaskPlans(null);
            setTotalCount(0);
            setIdTaskPlan('error');
            setTaskPlan(null);
            setIsLoading(false);
          }
        }
      }
    }
    getTaskPlans();
  }, [filterTaskPlan, searchParams, updateList, team.id_equipe]);

  const handleChangeFilter = (event: React.SyntheticEvent, newValue: number) => {
    setFilterTaskPlan(newValue);
  };

  const handleClickUnCheck = (task_plan?:ITaskPlan) => {
    if(task_plan && task_plan.id_task_plan){
      task_plan.status_task_plan = '0';
      TaskPlanService.updateTaskPlan(task_plan).then((idTaskPlan) => {
        if (idTaskPlan instanceof Error) {
          setIdTaskPlan(null);
          setTaskPlan(null);
          setIsLoading(false);
          enqueueSnackbar('Erro ao tentar reabrir a tarefa!', { variant:'error'});
        } else {
            setUpdateList(!updateList); 
            enqueueSnackbar('Tarefa reaberta com sucesso!', { variant:'success'});
            setIdTaskPlan(null);
            setIsLoading(false);
        }
      });
    }
  }; 

  const handleClickCheck  = (task_plan?:ITaskPlan) => {
    if(task_plan && task_plan.id_task_plan){
      setTaskPlanStore(task_plan);
      setOpenFinish(true);
    }
  }; 

  const handleStoreCancel = () => {
    setTaskPlanStore(null);
    setOpenFinish(false);
    //setIdTaskPlan(null);
  };

  const handleStoreConfirme  = (presentation:boolean) => {
    if(taskPlanStore && taskPlanStore.id_task_plan){
      taskPlanStore.status_task_plan = '1';
      taskPlanStore.presentation_task_plan = presentation ? '1' : '0';
      TaskPlanService.updateTaskPlan(taskPlanStore).then((idTaskPlan) => {
        if (idTaskPlan instanceof Error) {
          setIdTaskPlan(null);
          setTaskPlan(null);
          setIsLoading(false);
          enqueueSnackbar('Erro ao tentar finalizar a tarefa!', { variant:'error'});
        } else {
          setTaskPlanStore(null);
          setOpenFinish(false);
          setUpdateList(!updateList);  
          enqueueSnackbar('Tarefa finalizada com sucesso!', { variant:'success'});
          setIdTaskPlan(null);
          setIsLoading(false);
        }
      });
    }
  }; 

  const handleClickUnCheckPresentation = (task_plan?:ITaskPlan) => {
    if(task_plan && task_plan.id_task_plan){
      task_plan.presentation_task_plan = '0';
      TaskPlanService.updateTaskPlan(task_plan).then((idTaskPlan) => {
        if (idTaskPlan instanceof Error) {
          setIdTaskPlan(null);
          setTaskPlan(null);
          setIsLoading(false);
          enqueueSnackbar('Erro ao tentar remover a tarefa da apresentação final!', { variant:'error'});
        } else {
            setUpdateList(!updateList); 
            enqueueSnackbar('Tarefa removida da apreentação final com sucesso!', { variant:'success'});
            setIdTaskPlan(null);
            setIsLoading(false);
        }
      });
    }
  }; 

  const handleClickCheckPresentation = (task_plan?:ITaskPlan) => {
    if(task_plan && task_plan.id_task_plan){
      task_plan.presentation_task_plan = '1';
      TaskPlanService.updateTaskPlan(task_plan).then((idTaskPlan) => {
        if (idTaskPlan instanceof Error) {
          setIdTaskPlan(null);
          setTaskPlan(null);
          setIsLoading(false);
          enqueueSnackbar('Erro ao tentar adicionar a tarefa na apresentação final!', { variant:'error'});
        } else {
            setUpdateList(!updateList); 
            enqueueSnackbar('Tarefa adicionada na apreentação final com sucesso!', { variant:'success'});
            setIdTaskPlan(null);
            setIsLoading(false);
        }
      });
    }
  }; 



  const handleClickDelete  = (id_task_plan?:string) => {
    if(id_task_plan){
      setIdTaskPlan(id_task_plan);
      setOpen(true);
    }
  }; 

  const handleConfirme = () => {
    deleteTaskPlan();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setIdTaskPlan(null);
  };

  const deleteTaskPlan = () => {

    if(idTaskPlan){
      TaskPlanService.deleteTaskPlan(idTaskPlan).then((taskPlan) => {
        if (taskPlan instanceof Error) {
          setIdTaskPlan(null);
          setTaskPlan(null);
          setIsLoading(false);
          enqueueSnackbar('Erro ao tentar remover a tarefa!', { variant:'error'});
        } else {
            /*if(allTaskPlans){
              const newAllTaskPlans = allTaskPlans.filter((taskPlan) => taskPlan.id_task_plan !== idTaskPlan);
              setAllTaskPlans(newAllTaskPlans);
            } */
            setUpdateList(!updateList); 
            enqueueSnackbar('Tarefa removida com sucesso!', { variant:'success'});
            setIdTaskPlan(null);
            setIsLoading(false);
            
        }
      });
    }
  };

  const handleClickEdit = (task_plan?:ITaskPlan) => {
    if(task_plan){
      setTaskPlan(task_plan);
     // setOpenDialog(true);
    }
  };

  const handleClickCancelEdit = () => {
      setTaskPlan(null);
  };
  
  const updateAllTaskPlans = () => {
    setUpdateList(!updateList); 
  };

  const addTaskPlan = () => {
    setUpdateList(!updateList); 
  };

  return (
      <>
        {(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' ) ? (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography variant="h5">Este programa foi encerrado</Typography>
          {((team.type_tean === '0')  && (
            <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
          ))}
        </Paper>
        ):(
        <Box>
          <EASTaskPlanNew addTaskPlan={() => addTaskPlan() } />
        </Box> 
        
        )}
        <Paper sx={{ marginTop: '1em', display: 'flex', justifyContent: 'center' }}>
          <Tabs value={filterTaskPlan} onChange={handleChangeFilter} aria-label="Selecione a lista de Tarefas">
            <Tab icon={<DataSaverOnIcon />} label="PENDENTES" />
            <Tab icon={<TaskAltIcon />} label="CONCLUIDAS" />
            <Tab icon={<AddTaskIcon />} label="TODAS" />
          </Tabs>
        </Paper>
        <EASTaskPlanEdit finishEditTaskPlan={() => updateAllTaskPlans()} cancelEditTaskPlan={() => handleClickCancelEdit()} taskPlan={taskPlan} />
        <List sx={{ marginTop: '1em' }}>
          {isLoading && (
            <LinearProgress variant='indeterminate' />
          )}
          {!isLoading && (allTaskPlans && allTaskPlans.length ? (allTaskPlans.map((taskPlan) => (
            <div key={taskPlan.id_task_plan} style={{ marginTop: '0.5em' }}>
              <Paper style={{ padding: '0.5em 0em' }}>
                <ListItem>
                  <ListItemText  primary={taskPlan.text_task_plan} secondary={ taskPlan.date_task_plan ? ('Previsto para ' + (new Date(taskPlan.date_task_plan + 'T23:28:56.782Z').toLocaleDateString('pt-BR'))) : ''} />
                  {filterTaskPlan == 1 && (
                    (!(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' ))  ? ( 
                        (taskPlan.presentation_task_plan == '1' ? (
                          <IconButtonChecked title="Remover Tarefa da Apresentação Final" onClick={() => handleClickUnCheckPresentation(taskPlan)}>
                            <CoPresentIcon />
                          </IconButtonChecked>
                        ) : (
                          <IconButton title="Adionar Tarefa da Apresentação Final" onClick={() => handleClickCheckPresentation(taskPlan)}>
                            <CoPresentIcon />
                          </IconButton>
                        ))
                    ) : (
                      (taskPlan.presentation_task_plan == '1' && (
                        <Box title="Tarefa adicionada para ser exibida na apresentaçÃo final">
                        <CoPresentIcon color="primary" sx={{mr:'5px'}} />
                        </Box>
                      ))
                    ))}



                  {(!(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' )) ? ( 
                        (taskPlan.status_task_plan == '1' ? (
                          <IconButtonChecked title="Reabrir Tarefa" onClick={() => handleClickUnCheck(taskPlan)}>
                            <CheckCircleIcon />
                          </IconButtonChecked>
                        ) : (
                          <IconButton title="Concluir Tarefa" onClick={() => handleClickCheck(taskPlan)}>
                            <CheckCircleIcon />
                          </IconButton>
                        ))
                    ) : (
                      (taskPlan.status_task_plan == '1' && (
                      <Chip
                        label="Concluída"
                        color="primary"
                        avatar={<CheckCircleIcon />}
                      />
                      ))
                    )}  
                  {((team.type_tean=='0' || enrollment.id_inscricao == taskPlan.register_task_plan) && (team.type_tean=='1' && team.status_mentory_inscricao !='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao !='1' )) && ( 
                    <>
                      <IconButton title="Editar Tarefa" onClick={() => handleClickEdit(taskPlan)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="end" title="Excluir Tarefa" onClick={() => handleClickDelete(taskPlan.id_task_plan)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                   )} 
                </ListItem>
              </Paper>
            </div>
          ))) :
          <Alert severity="warning">Não foi encontrada nenhuma tarefa!</Alert>
          )}
        </List>
        {!isLoading && totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
        <Box style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0' }}>
          <Pagination
            page={pagina}
            count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
            onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
            variant="outlined" 
            shape="rounded"
            color="primary"
            siblingCount={3} 
            boundaryCount={1}
          />
        </Box>
        )}
        <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            {'Excluir Tarefa'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Você tem certeza que deseja excluir esta tarefa?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{mr:2, mb:1}}>
            <Button onClick={handleCancel} variant="contained">Desistir</Button>
            <Button onClick={handleConfirme}  variant="contained" autoFocus>
              Sim, Excluir
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openFinish}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            {'Adicionar Tarefa na Apresentação '}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Já vamos concluir sua tarefa. Você deseja também adicionar esta tarefa em sua apresentação final?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{mr:2, mb:1}}>
            <Button onClick={handleStoreCancel} variant="contained">Desistir</Button>
            <Button onClick={() => handleStoreConfirme(false)}  variant="contained" autoFocus>
              Não, Apenas Concluir
            </Button>
            <Button onClick={() => handleStoreConfirme(true)}  variant="contained" autoFocus>
              Sim, Adicionar
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
};