import { LayoutBaseDePagina } from '../../shared/layouts';


export const Chat = () => {

  return (
    <LayoutBaseDePagina
      titulo='Chat'
    >
      Chat
    </LayoutBaseDePagina>
  );
};
