import React from 'react';
import { Container } from '@mui/material';

import { LayoutBaseDePagina } from '../../shared/layouts';
import { EASTaskPlan } from '../../shared/components';

export const TaskPlan: React.FC = () => {

  return (
    <LayoutBaseDePagina
      titulo='&nbsp;Plano de Tarefas'
      iconTitle='task'
    >
      <Container>
        <EASTaskPlan />
      </Container>
    </LayoutBaseDePagina>
  );
};