
import React, { useState , useEffect, useMemo } from 'react';
import { useSearchParams} from 'react-router-dom';

import { Container, Box, TextField, Pagination, Grid, LinearProgress, Alert, Fab, Autocomplete } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { EASFileCard } from './EASFileCard';
import { IFile, ITagsFiles } from '../../contexts/FilesContext/types';

import { useCourseContext , useTeamContext} from '../../contexts';
import { Environment } from '../../environment';
import { FilesService } from '../../services/api/files/FilesService';
import { EASCard } from '../eas-card/EASCard';

export const EASFiles = () => {

    const [files, setFiles] = useState<Array<IFile> | null>(null);
    const [tagsFile, setTagsFile] = useState<Array<ITagsFiles> | null>(null);
    const [tagsIDFile, setTagsIDFile] = useState<Array<string>>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [updateList, setUpdateList] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState<string>('');
  
    const { ...enrollment } = useCourseContext();
    const { ...team } = useTeamContext();
    
    const busca = useMemo(() => {
      return searchParams.get('busca') || '';
    }, [searchParams]);
  
    const pagina = useMemo(() => {
      return Number(searchParams.get('pagina') || '1');
    }, [searchParams]);
    
    const tags = useMemo(() => {
      return searchParams.get('tags') || '';
    }, [searchParams]);
    
    useEffect(() => {
      async function getFiles() {
        setIsLoading(true);
        if(enrollment.id_inscricao){
          const responseFiles = await FilesService.getAllFiles((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), enrollment.id_evento, team.id_equipe, team.id_mentoria_tean, busca, tagsIDFile.toString(), pagina);
          if (responseFiles instanceof Error) {
            setFiles(null);
            setTotalCount(0);
            setIsLoading(false);
            setUpdateList(false);
          } else {
            if(responseFiles.tags){
              const myTagsVideo = JSON.parse(JSON.stringify(responseFiles.tags));
              setTagsFile(myTagsVideo);
            }
            if(responseFiles.data && responseFiles.total){
              const myFiles = JSON.parse(JSON.stringify(responseFiles.data));
              setFiles(myFiles);
              setIsLoading(false);
              if(responseFiles.total)
                setTotalCount(responseFiles.total);
              setUpdateList(false);
            }
            else{
              setFiles(null);
              setTotalCount(0);
              setIsLoading(false);
              setUpdateList(false);
            }
          }
        }
      }
      getFiles();
    }, [searchParams, updateList, team.id_equipe]);  


   
    return(
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center', height: 'auto', marginBottom: '15px' }}>
          <EASCard>
            <Box style={{ display: 'flex', justifyContent: 'center', height: 'auto' }}>
              <TextField

                variant="outlined"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                fullWidth
                label={Environment.INPUT_DE_BUSCA}
                type={Environment.INPUT_DE_BUSCA}
              />
              <Box sx={{ m: 1, position: 'relative' }}>
                <Fab
                  size="small"
                  aria-label="save"
                  color="primary"
                  onClick={() => setSearchParams({ busca:search, pagina:'1' }, { replace: true })}
                >
                  {<SearchIcon />}
                </Fab>
              </Box>
            </Box>
          </EASCard>
        </Box>
        {(tagsFile && tagsFile.length > 1) && (
          <Box sx={{ display: 'flex', justifyContent: 'center', height: 'auto', marginBottom: '15px' }}>
            <EASCard>
              <Box style={{ display: 'flex', justifyContent: 'center', height: 'auto' }}>
                <Autocomplete
                  multiple
                  options={tagsFile}
                  getOptionLabel={(option) => option.name_material_tag}
                  onChange={(event: any, newValue: Array<ITagsFiles> | null) => {

                    setTagsIDFile([]);
                    if(newValue){
                      newValue.map((value) => ( setTagsIDFile((prevTagsIDFile) => [ ...prevTagsIDFile, value.id_material_tag, ])));
                      setSearchParams({tags: tagsIDFile, pagina:'1' }, { replace: true });
                    }   
                    else{
                      setSearchParams({tags: '', pagina:'1' }, { replace: true });
                    }
                  }}

                  filterSelectedOptions
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tags"
                    />
                  )}
                />
              </Box>
            </EASCard>
          </Box>
        )}


        {isLoading && (
          <LinearProgress variant='indeterminate' />
        )}
        {!isLoading && (files && files.length>0 ? (
            <Grid container alignItems="center" spacing={2} sx={{ mb: 2}} >
              {files.map((file, index) => (
              <Grid key={index} 
                    item xs={12} 
                    sm={12} 
                    md={4} 
                    lg={3} 
                    sx={{width:'100%', display:{ sm: (index>5 ? 'none' : 'block'), md:(index>5 ? 'none' : 'block'), lg:'block'}}}
                >
                <EASFileCard key={index} file={file}/>
              </Grid>
              ))}
            </Grid>
          ) : (
            <Alert severity="warning">Não há Arquivos!</Alert>
          ))}
        {!isLoading && totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
          <Box style={{ display: 'flex', justifyContent: 'center', padding:'20px 0 20px 0' }}>
            <Pagination
              page={pagina}
              count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
              onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
              variant="outlined" 
              shape="rounded"
              color="primary"
              siblingCount={3} 
              boundaryCount={1}
            />
          </Box>
          )}
          </Container>
    );
};