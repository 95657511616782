import { ITaskPlan } from '../../../contexts/TaskPlanContext/types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';

interface ITaskPlanService {
  Teams?: string;
}

interface ITaskPlanListResponse extends ITaskPlan {
  page?: number | null;
  per_page?: number | null;
  total?: number | null;
  total_pages?: number | null;
  data?: ITaskPlan | null;
}


const createTaskPlan = async (taskPlan: object): Promise<ITaskPlanService | Error> => {
  try {
    const { data } = await Api.post('/taskplan', { taskPlan: taskPlan } );
  
    if (data) 
      return data;
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateTaskPlan = async (TaskPlan: ITaskPlan): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/taskplan/${TaskPlan.id_task_plan}`, TaskPlan);
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const deleteTaskPlan = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api.delete(`/taskplan/${id}`);
    if (data) 
      return data;   
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getTaskPlan = async (userEnrollment: string, client_id: string): Promise<ITaskPlanService | Error> => {
  try {
    const { data } = await Api.post('/taskplan', { userEnrollment: userEnrollment, client_id: client_id } );
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const getAllTaskPlan = async (id_inscricao = '', id_equipe = '', id_mentoria_tean = '', filterTaskPlan = 0, page = 1, filter = '' ): Promise<ITaskPlanListResponse | Error> => {
  try {
    if(!id_inscricao || !id_equipe || !id_mentoria_tean)
      return new Error('Requisição imcompleta.');

    const urlRelativa = `/taskplan?public_token=${Environment.PUBLIC_TOKEN}&i=${id_inscricao}&e=${id_equipe}&t=${id_mentoria_tean}&f=${filterTaskPlan}&page=${page}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${filter}`;

    const  {data} = await Api.get(urlRelativa);
    
    if (data) {
      return data;
    }
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

export const TaskPlanService = {
  getAllTaskPlan,
  getTaskPlan,
  createTaskPlan,
  updateTaskPlan,
  deleteTaskPlan,

};
