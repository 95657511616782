import React, { useState , useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Avatar, ListItem,  ListItemAvatar, ListItemText, Paper, Typography, Grid, Rating, CircularProgress, TextField, Fab,  Box, Pagination, Container, Chip , Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress, useMediaQuery, Theme  } from '@mui/material/';

import ListAltIcon from '@mui/icons-material/ListAlt';
import AddIcon from '@mui/icons-material/Add';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import DeleteIcon from '@mui/icons-material/Delete';

import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import { Environment } from '../../environment';
import { useCourseContext , useTeamContext, useAuthContext } from '../../contexts';
import { IEvaluationPartial } from '../../contexts/EvaluationPartialContext/types';
import { EvaluationPartialService } from '../../services/api/evaluation-partial/EvaluationPartialService';
import { EASCard } from '../eas-card/EASCard';

interface IEvaluationPartialProps {
  children?: () => void;
}  

export const EASEvaluationPartial: React.FC<IEvaluationPartialProps> = () => {

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();
  const { ...user } = useAuthContext();
  

  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingExt, setLoadingExt] = useState<boolean>(false);

  const [open, setOpen] = useState(false);
  const [openExternalEvaluationPartial, setOpenExternalEvaluationPartial] = useState<boolean>(false);

  const [updateList, setUpdateList] = useState(false);

  const [idEvaluationPartial, setIdEvaluationPartial] = useState<string | null>(null);
  const [allEvaluationPartial, setAllEvaluationPartial] = useState<Array<IEvaluationPartial> | null>(null);
  const [objectivesDetails, setObjectivesDetails] = useState<number | null>(0);

  const [emailEvaluationPartial, setEmailEvaluationPartial] = useState<string>('');
  const [emailEvaluationPartialError, setEmailEvaluationPartialError] = useState<string>('');
  const [filterEvaluationPartial, setFilterEvaluationPartial] = useState<number>(0);
  const [totalCount, setTotalCount] = useState(0);


  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const externalEvaluationPartialSchema = yup.object().shape({
    emailEvaluationPartial: yup.string().email('Informe um e-mail válido').required('Informe uma Nota')
  });

  useEffect(() => {
    async function getEvaluationsPartial() {
      if(enrollment.id_inscricao){
        setIsLoading(true);
        const evaluationPartial = await EvaluationPartialService.getAllEvaluationPartial((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), team.id_equipe, team.id_mentoria_tean, filterEvaluationPartial, pagina);
        if (evaluationPartial instanceof Error) {
          setAllEvaluationPartial(null);
          setTotalCount(0);
          setObjectivesDetails(0);
          setIdEvaluationPartial('error');
          setIsLoading(false);
        } else {
          
          setObjectivesDetails(evaluationPartial.objectives_details);
          if(evaluationPartial.data && evaluationPartial.total){
            const myEvaluationPartial = JSON.parse(JSON.stringify(evaluationPartial.data));
            setAllEvaluationPartial(myEvaluationPartial);
            setIsLoading(false);
            if(evaluationPartial.total)
              setTotalCount(evaluationPartial.total);
          }
          else{
            setAllEvaluationPartial(null);
            setTotalCount(0);
            setIdEvaluationPartial('error');
            setIsLoading(false);
          }
        }
      }
    }
    getEvaluationsPartial();
  }, [filterEvaluationPartial, searchParams, updateList, team.id_equipe]);

  const handleClickDelete  = (id_evaluation_partial?:string) => {
    if(id_evaluation_partial){
      setIdEvaluationPartial(id_evaluation_partial);
      setOpen(true);
    }
  }; 

  const handleConfirme = () => {
    deleteEvaluationPartial();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setIdEvaluationPartial(null);
  };

  const deleteEvaluationPartial = () => {
    if(idEvaluationPartial){
      EvaluationPartialService.deleteEvaluationPartial(idEvaluationPartial).then((responseEvaluationPartial) => {
        if (responseEvaluationPartial instanceof Error) {
          setIdEvaluationPartial(null);
          setIsLoading(false);
          enqueueSnackbar('Erro ao tentar remover a avaliação parcial!', { variant:'error'});
        } else {
          setUpdateList(!updateList); 
          enqueueSnackbar('Avaliação Parcial removida com sucesso!', { variant:'success'});
          setIdEvaluationPartial(null);
          setIsLoading(false);
        }
      });
    }
  };

  const handleClickNewEvaluationPartial = async () => {

    const token = uuidv4();

    setLoading(true);
    const evaluationPartial: IEvaluationPartial = {
      id_evaluation_partial: '',
      id_inscricao: (team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao),
      id_equipe: team.id_equipe,
      id_mentoria_tean: team.id_mentoria_tean,
      type_evaluation_partial: '0',
      status_evaluation_partial: '0',
      text_evaluation_partial: '',
      date_evaluation_partial: new Date().toLocaleDateString('pt-BR'),
      mail_evaluation_partial: '-',
      token_evaluation_partial: token.toString(),
    };  
    const evaluationPartialResponse = await EvaluationPartialService.createEvaluationPartial(evaluationPartial);
    if (evaluationPartialResponse instanceof Error) {
      setLoading(false);
      enqueueSnackbar('Erro ao iniciar nova autoavaliação parcial.', { variant:'error'});
    } else {
      setLoading(false);
      navigate(`/avaliacao-parcial/detalhes/${evaluationPartialResponse}`);
    }
  };


  const handleClickExternalEvaluationPartial = async (emailEPartial:string) => {
      const evaluationPartial: IEvaluationPartial = {
        id_evaluation_partial: '',
        id_inscricao: (team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao),
        id_equipe: team.id_equipe,
        id_mentoria_tean: team.id_mentoria_tean,
        type_evaluation_partial: '1',
        status_evaluation_partial: '0',
        text_evaluation_partial: '',
        date_evaluation_partial: new Date().toLocaleDateString('pt-BR'),    
        mail_evaluation_partial: emailEPartial,
        token_evaluation_partial: uuidv4(),
      }; 

      const evaluationPartialResponse = await EvaluationPartialService.createEvaluationPartial(evaluationPartial);
      if (evaluationPartialResponse instanceof Error) {
        setLoading(false);
        enqueueSnackbar('Erro ao criar convite para avaliação parcial externa.', { variant:'error'});
      } else {
        setLoadingExt(false);
        setUpdateList(!updateList); 
        enqueueSnackbar('Avaliação parcial criada e e-mail enviado com sucesso.', { variant:'success'});
      }
  };

  const handleConfirmeExternalEvaluationPartial = async () => {
      externalEvaluationPartialSchema
      .validate({ emailEvaluationPartial}, { abortEarly: false })
      .then(validateValues => {
        setOpenExternalEvaluationPartial(false);
        setLoadingExt(true);
        handleClickExternalEvaluationPartial(validateValues.emailEvaluationPartial);
      })
      .catch((errors: yup.ValidationError) => {
        //setLoading(false);
        errors.inner.forEach(error => {
          setEmailEvaluationPartialError(error.message);
        });
      });
  };

  const handleCancelExternalEvaluationPartial = () => {
    setOpenExternalEvaluationPartial(false);
  };

  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
        <Container>
          <Box style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0' }}>
            <EASCard>
            <Grid container style={{ display: 'flex', justifyContent: 'center'}} spacing={2}>
              <Grid item xs={12} sm={6} md={8} xl={10}>  
              <ListItem>
                <ListItemAvatar sx={{marginRight:'15px'}}>
                  <Avatar sx={{ border:'1px solid #EA5B0C', height: '60px', width: '60px' }} 
                            alt={(team.type_tean=='1' ? team.name : user.name)} 
                            src={(team.type_tean=='1' ? team.avatar : user.avatar) && 'https://online.sbdc.com.br/media/images/avatar/' + (team.type_tean=='1' ? team.avatar : user.avatar)} />
                </ListItemAvatar>    
                <ListItemText 
                  primary={<Typography variant="h6">{(team.type_tean=='1' ? team.name : user.name)}</Typography>} 
                  secondary={<Typography  sx={{fontSize:'12px'}}>MENTORADO</Typography>}
                  />
              </ListItem> 
              </Grid> 
              <Grid item xs={12} sm={6} md={4} xl={2}> 
              <Box sx={{ mt: 1, float:{xs:'inherit', sm:'right'}, textAlign:'center'}}>
                <Button variant="outlined" startIcon={<ListAltIcon />} onClick={() => navigate('/avaliacao-parcial/resultados-consolidados')} >
                  Resultados Consolidados
                </Button>
              </Box>
              </Grid>
            </Grid>
            </EASCard>
          </Box>
          {(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' ) && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography variant="h5">Este programa foi encerrado</Typography>
              {((team.type_tean === '0')  && (
                <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
              ))}
            </Paper>
          )}
          {isLoading && (
          <LinearProgress variant='indeterminate' sx={{ mt:'15px'}} />
          )}
          {(!isLoading && ( (objectivesDetails && objectivesDetails > 0 ) ? (
          <> 
          {(!(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' )) && (
          <Grid container style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0'}} spacing={2}>
            <Grid item xs={12} lg={6} style={{ display: 'flex', justifyContent: (lgDown ? 'center' : 'right') }}>
              <Fab 
                variant="extended"
                color="primary" 
                onClick={handleClickNewEvaluationPartial}
                sx={{marginRight:'10px' }}
                disabled={loading}
              >
              {loading ? (
                <CircularProgress
                  size={24}
                  color="inherit"
                />
              ):(
                <AddIcon />
              )}
                  &nbsp;Nova Autovaliação Parcial
              </Fab>
            </Grid>  
            <Grid item xs={12} lg={6} style={{ display: 'flex', justifyContent: (lgDown ? 'center' : 'left') }}>
              <Fab 
                variant="extended" 
                color="primary" 
                onClick={() => setOpenExternalEvaluationPartial(true)}
                disabled={loadingExt}
                >
                {loadingExt ? (
                  <CircularProgress
                    size={24}
                    color="inherit"
                  />
                ):(
                  <ForwardToInboxIcon />
                )}
                  &nbsp;Solicitar Avaliação Parcial Externa
              </Fab>
            </Grid>  
          </Grid>  
          )}
          <Grid container style={{ display: 'flex', justifyContent: 'center'}} spacing={2}>
          {!isLoading && (allEvaluationPartial && allEvaluationPartial.length ? (allEvaluationPartial.map((evaluationPartial) => (
            <Grid key={evaluationPartial.id_evaluation_partial} item xs={12} lg={6} xl={4} style={{ display: 'flex', justifyContent: 'center'}} >
              <Paper sx={{m:2, p:4, width:'285px' }}>
                <Grid container style={{ display: 'flex', justifyContent: 'center'}} spacing={2}>
                  <Grid item xs={8}>
                    <Typography variant="h6">{evaluationPartial.type_evaluation_partial == '0' ? 'Autoavaliação' : 'Avaliação Externa' }</Typography>
                    <Typography variant="subtitle2">{evaluationPartial.date_evaluation_partial ? new Date(evaluationPartial.date_evaluation_partial + 'T23:28:56.782Z').toLocaleDateString('pt-BR') : ''}</Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Box sx={{width:'90px', alignItems: 'baseline'}}>
                      <Box style={{ display:'flex', alignItems:'end', height: '50px', justifyContent: 'left', padding:'0' }}>
                        <Box sx={{ bgcolor: 'primary.main', width: 6, height: (evaluationPartial.star1_evaluation_partial && parseInt(evaluationPartial.star1_evaluation_partial) > 1 ? parseInt(evaluationPartial.star1_evaluation_partial) : 2), borderRadius:'15px', mx:0.85}}></Box>
                        <Box sx={{ bgcolor: 'primary.main', width: 6, height: (evaluationPartial.star2_evaluation_partial && parseInt(evaluationPartial.star2_evaluation_partial) > 1 ? parseInt(evaluationPartial.star2_evaluation_partial) : 2), borderRadius:'15px', mx:0.85}}></Box>
                        <Box sx={{ bgcolor: 'primary.main', width: 6, height: (evaluationPartial.star3_evaluation_partial && parseInt(evaluationPartial.star3_evaluation_partial) > 1 ? parseInt(evaluationPartial.star3_evaluation_partial) : 2), borderRadius:'15px', mx:0.85}}></Box>
                        <Box sx={{ bgcolor: 'primary.main', width: 6, height: (evaluationPartial.star4_evaluation_partial && parseInt(evaluationPartial.star4_evaluation_partial) > 1 ? parseInt(evaluationPartial.star4_evaluation_partial) : 2), borderRadius:'15px', mx:0.85}}></Box>
                        <Box sx={{ bgcolor: 'primary.main', width: 6, height: (evaluationPartial.star5_evaluation_partial && parseInt(evaluationPartial.star5_evaluation_partial) > 1 ? parseInt(evaluationPartial.star5_evaluation_partial) : 2), borderRadius:'15px', mx:0.85}}></Box>
                      </Box>
                      <Rating size="small" value={5} max={5} readOnly />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">itens avaliados</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ width: '240px', mr: 1 }}>
                        <LinearProgress variant="determinate" value={evaluationPartial.completed_evaluation_partial ? parseInt(evaluationPartial.completed_evaluation_partial) : 0} />
                      </Box>
                      <Box sx={{ width: 15 }}>
                        <Typography variant="body2" color="text.secondary">{(evaluationPartial.completed_evaluation_partial ? parseInt(evaluationPartial.completed_evaluation_partial) : 0) + '%'}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center',  justifyContent: 'left', mt:1}}>
                    {evaluationPartial.status_evaluation_partial == '2' ? (
                      <Chip label="Finalizada" color="primary" />
                    ) : (
                      <Chip label="Não Finalizada" color="primary" variant="outlined" />
                    )}
                  </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center',  justifyContent: 'right'}}>
                      {team.type_tean=='1' && (
                      <IconButton title="Excluir Avaliação Parcial" onClick={() => handleClickDelete(evaluationPartial.id_evaluation_partial)}>
                        <DeleteIcon />
                      </IconButton>
                      )}
                      <IconButton title="Abrir Avaliação Parcial" onClick={() => navigate(`/avaliacao-parcial/detalhes/${evaluationPartial.id_evaluation_partial}`)}>
                        <ImageSearchIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Paper >
            </Grid>
            ))) :
            <Grid item xs={12}>
              <Alert severity="warning">Não há nenhuma avaliação parcial iniciada.</Alert>
            </Grid>
            )}
          </Grid>
        </> 
        ) : ( 
        <Grid container style={{ display: 'flex', justifyContent: 'center'}} spacing={2}>
          <Grid item xs={12}>
            <Alert severity="warning">Não há nenhuma Competência e/ou Detalhamento cadastrado.</Alert>
          </Grid>
        </Grid>
        )))}
        {!isLoading && totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
        <Box style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0' }}>
          <Pagination
            page={pagina}
            count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
            onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
            variant="outlined" 
            shape="rounded"
            color="primary"
            siblingCount={3} 
            boundaryCount={1}
          />
          </Box>
          )}

          <Dialog
          open={openExternalEvaluationPartial}
          onClose={handleCancelExternalEvaluationPartial}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            {'Solicitar Avaliação Parcial Externa'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Informe o endereço de e-mail para que seja enviado o link contendo o formulário para preenchimento da avaliação parcial.

            </DialogContentText>
            <TextField  
              label="E-mail"
              value={emailEvaluationPartial} 
              onChange={(event) => setEmailEvaluationPartial(event.target.value)}  
              onFocus={() => setEmailEvaluationPartialError('')}
              error={!!emailEvaluationPartialError}
              helperText={emailEvaluationPartialError}
              fullWidth
            />  
          </DialogContent>
          <DialogActions sx={{mr:2, mb:1}}>
            <Button onClick={handleCancelExternalEvaluationPartial} variant="contained">Desistir</Button>
            <Button onClick={handleConfirmeExternalEvaluationPartial} variant="contained" autoFocus>
              Enviar
            </Button>
          </DialogActions>
        </Dialog>  
        <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            {'Excluir Avaliação Parcial'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Você tem certeza que deseja excluir esta avaliação parcial? Todos os dados desta avaliação serão perdidos.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{mr:2, mb:1}}>
            <Button onClick={handleCancel} variant="contained">Desistir</Button>
            <Button onClick={handleConfirme}  variant="contained" autoFocus>
              Sim, Excluir
            </Button>
          </DialogActions>
        </Dialog>    
      </Container>
  );
};