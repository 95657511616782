import React, { useState , useEffect, useMemo } from 'react';
import {Box, Paper, Typography, Chip, Pagination, Skeleton, Stack, Switch, Alert, List, ListItem, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemText,  IconButton  } from '@mui/material/';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useSnackbar } from 'notistack';

import { useSearchParams } from 'react-router-dom';

import { Environment } from '../../environment';
import { EASCard } from '../eas-card/EASCard';

import { useTeamContext, useCourseContext} from '../../contexts';

import { IObjective, IObjectiveDetail } from '../../contexts/ObjectiveContext/types';

import { EASObjectivesEdit } from '../eas-objectives-edit/EASObjectivesEdit';
import { EASObjectivesDetailNew } from '../eas-objectives-detail-new/EASObjectivesDetailNew';
import { EASObjectivesDetailEdit } from '../eas-objectives-detail-edit/EASObjectivesDetailEdit';

import { ObjectiveService } from '../../services/api/objective/ObjectiveService';
import { ObjectiveDetailService } from '../../services/api/objective-detail/ObjectiveDetailService';


interface IObjectivesDetailProps {
  children?: () => void;
  valueTab: number;
  allObjectives?: Array<IObjective> | null;
  removeObjective: () => void;
}  

export const EASObjectivesDetail: React.FC<IObjectivesDetailProps> = ({valueTab, allObjectives, removeObjective}) => {

  const { enqueueSnackbar } = useSnackbar();
  const { ...enrollment } =useCourseContext();
  const { ...team } = useTeamContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAll, setIsLoadingAll] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState(0);
  const [updateList, setUpdateList] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [idObjective, setIdObjective] = useState<string | null>(null);
  const [objective, setObjective] = useState<IObjective | null>(null);

  const [allObjectivesDetail, setAllObjectivesDetail] = useState<Array<IObjectiveDetail> | null>(null);
  const [objectiveDetail, setObjectiveDetail] = useState<IObjectiveDetail | null>(null);
  const [idObjectiveDetail, setIdObjectiveDetail] = useState<string | null>(null);

  useEffect(() => {
    async function getObjectivesDetail() {     
      if(valueTab){
        setIsLoading(true);
        const objectivesDetail = await ObjectiveDetailService.getAllObjectiveDetail(valueTab, pagina);
        if (objectivesDetail instanceof Error) {
          setAllObjectivesDetail(null);
          setTotalCount(0);
          setIsLoading(false);
        } else {
          if(objectivesDetail.data && objectivesDetail.total){
            const myObjectivesDetail = JSON.parse(JSON.stringify(objectivesDetail.data));
            setAllObjectivesDetail(myObjectivesDetail);
            setIsLoading(false);
            if(objectivesDetail.total)
              setTotalCount(objectivesDetail.total);
          }
          else{
            setAllObjectivesDetail(null);
            setTotalCount(0);
            setIsLoading(false);
          }
        }
      }
    }
    getObjectivesDetail();
  }, [valueTab, searchParams, updateList]);

  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const addObjectiveDetail = (objectiveDetail:IObjectiveDetail) => {
    //const teste = filterObjective + 1 - 1;
    setUpdateList(!updateList); 
  };

  const handleClickPriority  = (event: React.ChangeEvent<HTMLInputElement>, objectiveDetail?:IObjectiveDetail) => {
    if(objectiveDetail && objectiveDetail.id_objective_detail){
      objectiveDetail.priority_objective_detail = event.target.checked ? '1': '0';
      ObjectiveDetailService.updateObjectiveDetail(objectiveDetail).then((idObjectiveDetail) => {
        if (idObjectiveDetail instanceof Error) {
          enqueueSnackbar((event.target.checked ? 'Erro ao definir prioridade!' : 'Erro ao desmarcar prioridade!'), { variant:'error'});
        } else {
          enqueueSnackbar((event.target.checked ? 'Prioridade definida com sucesso!' : 'Prioridade removida com sucesso!'), { variant:'success'});
        }
      });
    }
  }; 

  const handleClickDeleteDetail  = (id_objective_detail?:string) => {
    if(id_objective_detail){
      setIdObjectiveDetail(id_objective_detail);
      setOpenDetail(true);
    }
  }; 

  const handleConfirmeDetail = () => {
    deleteObjectiveDetail();
    setOpenDetail(false);
  };

  const handleCancelDetail = () => {
    setOpenDetail(false);
    setIdObjectiveDetail(null);
  };


  const deleteObjectiveDetail = () => {
    if(idObjectiveDetail){
      ObjectiveDetailService.deleteObjectiveDetail(idObjectiveDetail).then((objectiveDetail) => {
        if (objectiveDetail instanceof Error) {
          setIdObjectiveDetail(null);
          enqueueSnackbar('Erro ao tentar remover o detalhamento desta competência!', { variant:'error'});
        } else {
            setIdObjectiveDetail(null);
            setUpdateList(!updateList); 
            enqueueSnackbar('Detalhamento de competência removido com sucesso!', { variant:'success'});
        }
      });
    }
  };

  const handleClickDelete  = (id_objective?:string) => {
    if(id_objective){
      setIdObjective(id_objective);
      setOpen(true);
    }
  }; 

  const handleConfirme = () => {
    deleteObjective();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setIdObjective(null);

  };
      
  const handleClickCancelEdit = () => {
    setObjectiveDetail(null);
 };
        
  const deleteObjective = () => {
    if(idObjective){
      setIsLoadingAll(true);
      setIsLoading(true);
      ObjectiveService.deleteObjective(idObjective).then((objective) => {
        if (objective instanceof Error) {
          setIdObjective(null);
          enqueueSnackbar('Erro ao tentar remover competência!', { variant:'error'});
        } else {
            setIdObjective(null);
            removeObjective(); 
            enqueueSnackbar('Competência removida com sucesso!', { variant:'success'});
        }
      });
    }
  };


  const handleClickEditDetail = (objectiveDetail?:IObjectiveDetail) => {
    if(objectiveDetail){
      setObjectiveDetail(objectiveDetail);
     // setOpenDialog(true);*/
    }
  };

  const updateAllObjectiveDetail = (objectiveDetail:IObjectiveDetail) => {
    //const teste = filterTaskPlan + 1 - 1;
    setUpdateList(!updateList); 
  };

  const handleClickEdit = (objective?:IObjective) => {
    if(objective){
      setObjective(objective);
     // setOpenDialog(true);*/
    }
  };

  const updateAllObjective = (objective:IObjective) => {
    //const teste = filterTaskPlan + 1 - 1;
    removeObjective(); 
  };


  return (
    <div style={{marginBottom:'20px'}}>
      <EASCard>
      {isLoadingAll && (
        <>
        <Box style={{ display: 'flex', justifyContent: 'center', marginBottom:'15px'}}>
          <Skeleton animation="wave" variant="rounded" sx={{my:'15px'}} width={'40%'} height={40} />
          <Skeleton animation="wave" variant="circular" sx={{my:'15px', mx:'8px'}}width={40} height={40} />
          <Skeleton animation="wave" variant="circular" sx={{my:'15px', mx:'8px'}} width={40} height={40} />
        </Box> 
        <Skeleton animation="wave" variant="rounded" sx={{mt:'25px'}} width={'100%'} height={90} />
        <Box style={{ display: 'flex', justifyContent: 'center', marginBottom:'15px'}}>
          <Skeleton animation="wave" variant="rounded" sx={{my:'15px'}} width={'70%'} height={45} />
          <Skeleton animation="wave" variant="rounded" sx={{my:'15px', mx:'8px'}} width={'20%'} height={45} />
          <Skeleton animation="wave" variant="circular" sx={{my:'15px', mx:'8px'}} width={40} height={45} />
        </Box> 
        </>
      )}
      {!isLoadingAll && (allObjectives && allObjectives.length ) && (allObjectives.map((objective) => (
        <div
          role="tabpanel"
          key={objective.id_objective}
          hidden={!(objective.id_objective && valueTab == parseInt(objective.id_objective))}
        >
          <Typography sx={{textAlign: 'center', marginY:'15px',  fontWeight: '700',  fontSize:  '1.5em'}}>
            {objective.text_objective}   
            {(!(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' )) && ( 
            <>             
              <IconButton aria-label="Editar Competência" onClick={() => handleClickEdit(objective)} >
                <EditIcon />
              </IconButton>
              <IconButton aria-label="Excluir Competência" onClick={() => handleClickDelete(objective.id_objective)}>
                <DeleteIcon />
              </IconButton>
            </>  
            )}
          </Typography>
          {(!(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' )) && (   
          <EASObjectivesDetailNew id_objective={objective.id_objective} addObjectiveDetail={(createdObjectiveDetail) => addObjectiveDetail(createdObjectiveDetail) } />
          )}
          </div>
      )))}
      <EASObjectivesDetailEdit finishEditObjectiveDetail={(ObjectiveDetail) => updateAllObjectiveDetail(ObjectiveDetail)} objectiveDetail={objectiveDetail} cancelEditObjectiveDetail={() => handleClickCancelEdit()} />
      <List sx={{ marginTop: '1em' }}>
      {isLoading && (
        <>
          <Skeleton variant="rounded" sx={{marginBottom:'15px'}} width={'100%'} height={60} />
          <Skeleton variant="rounded" sx={{marginBottom:'15px'}} width={'100%'} height={60} />
          <Skeleton variant="rounded" sx={{marginBottom:'15px'}} width={'100%'} height={60} />
          <Skeleton variant="rounded" sx={{marginBottom:'15px'}} width={'100%'} height={60} />
        </>
      )}
      {!isLoading && (allObjectivesDetail && allObjectivesDetail.length ? (allObjectivesDetail.map((objectiveDetail) => (
        <div key={objectiveDetail.id_objective_detail} style={{ marginTop: '0.5em', display:'block' }}>
          <Paper style={{ padding: '0.5em 0em', marginTop:'10px' }}>
            <ListItem>
              <ListItemText  
                  primary={objectiveDetail.text_objective_detail} 
                  secondary={(objectiveDetail.value_objective_detail 
                            ? ('Nota Inicial: ' + objectiveDetail.value_objective_detail) : (
                              <Chip
                                onClick={() => handleClickEditDetail(objectiveDetail)}
                                sx={{marginTop:'5px'}}
                                color="error"
                                label="Informe uma nota inicial"
                                icon={<ErrorOutlineIcon />}
                              />
                            ))} />
                                                                                                              
                <Stack direction='column' sx={{justifyContent: 'center', alignItems: 'center', width:'60px', marginLeft:'15px'}}>
                  <Typography sx={{textAlign: 'center',  fontSize: '12px'}}>
                    Prioridade    
                  </Typography>
                  {(!(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' )) ? ( 
                    (objectiveDetail.priority_objective_detail == '1' ? (
                      <Switch
                        onChange={(event) => handleClickPriority(event, objectiveDetail)}
                        defaultChecked
                        color="primary" />
                      ) : (
                      <Switch
                        onChange={(event) => handleClickPriority(event, objectiveDetail)}
                        color="primary" />
                    ))
                  ) : (
                    (objectiveDetail.priority_objective_detail == '1' ? (
                      <CheckCircleIcon sx={{mt:'0.3em'}} color="primary" />
                    ) : (
                      <RemoveCircleOutlineIcon sx={{mt:'0.3em'}} />
                    ))
                  )}
                </Stack>
                {(!(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' )) && ( 
                <>
                  <IconButton sx={{mt:'0.7em', ml:'0.1em'}} edge="end" aria-label="Editar Detalhamento de Objetivo" onClick={() => handleClickEditDetail(objectiveDetail)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton sx={{mt:'0.7em', ml:'0.2em'}} edge="end" aria-label="Excluir Detalhamento de Objetivo" onClick={() => handleClickDeleteDetail(objectiveDetail.id_objective_detail)}>
                    <DeleteIcon />
                  </IconButton>
                </>
                )}
            </ListItem>
          </Paper>
        </div>
        
      ))) :
        <Alert severity="warning">Nenhum detalhamento de competência cadastrado para exibir!</Alert>
      )}
      {!isLoading && totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
        <Box style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0' }}>
          <Pagination
            page={pagina}
            count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
            onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
            variant="outlined" 
            shape="rounded"
            color="primary"
            siblingCount={3} 
            boundaryCount={1}
          />
        </Box>
      )}
      </List>
      <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            {'Excluir Competência'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Você tem certeza que deseja excluir esta competência e todos os detalhamentos cadastrados?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{mr:2, mb:1}}>
            <Button onClick={handleCancel} variant="contained">Desistir</Button>
            <Button onClick={handleConfirme} variant="contained" autoFocus>
              Sim, Excluir
            </Button>
          </DialogActions>
        </Dialog> 
        <Dialog
          open={openDetail}
          onClose={handleCancelDetail}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            {'Excluir Detalhamento de Competência'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Você tem certeza que deseja excluir este detalhamento desta competência?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{mr:2, mb:1}}>
            <Button onClick={handleCancelDetail} variant="contained">Desistir</Button>
            <Button onClick={handleConfirmeDetail}  variant="contained"autoFocus>
              Sim, Excluir
            </Button>
          </DialogActions>
        </Dialog> 
        <EASObjectivesEdit finishEditObjective={(Objective) => updateAllObjective(Objective)} objective={objective}/> 
      </EASCard>
    </div>
  );
};