import React from 'react';
import {  List, Typography  } from '@mui/material';
import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { EASInfluencersGoupsItem } from './EASInfluencersGoupsItem';
import { Droppable } from 'react-beautiful-dnd';
import { styled } from '@mui/material/styles';

interface IEASInfluencersGoupsColumnProps {
  item: {
    id: string
    list: Array<IInfluencer>
  }
}

const ItemInfluencers = styled('div') ({
  gridRowStart: '1',
  gridColumnStart: '1',
  gridRowEnd: '4',
  gridColumnEnd: '1',
});

const ItemPromotor = styled('div') ({
  gridRowStart: '1',
  gridColumnStart: '2',
  gridRowEnd: '1',
  gridColumnEnd: '2',
});

const ItemNeutral = styled('div') ({
  gridRowStart: '2',
  gridColumnStart: '2',
  gridRowEnd: '2',
  gridColumnEnd: '2',
});

const ItemDetractor = styled('div') ({
  gridRowStart: '3',
  gridColumnStart: '2',
  gridRowEnd: '3',
  gridColumnEnd: '2',
});

const ListAll = styled(List) ({
  minHeight:'150px', 
  border: '1px solid #bdbdbd', 
  borderRadius:'8px',
});

const ListInfluencers = styled(List) ({
  minHeight:'590px', 
  backgroundColor: '#37A2DA', 
  border: '1px solid #bdbdbd', 
  borderRadius:'8px',
});

export const EASInfluencersGoupsColumn: React.FC<IEASInfluencersGoupsColumnProps> =  ({ item: { list, id } }) => {

  return (
    <Droppable droppableId={id}>
      {provided => {
        switch(id) {
          case 'Detratores':
            return <ItemDetractor>
                      <Typography>{id}</Typography>
                      <ListAll {...provided.droppableProps} ref={provided.innerRef} sx={{backgroundColor:'#c23531'}}  >
                        {list.map((influencer, index) => (
                          <EASInfluencersGoupsItem key={influencer.id_influencer} influencer={influencer} index={index} />
                        ))}
                        {provided.placeholder}
                      </ListAll> 
                    </ItemDetractor>;
          case 'Promotores':
            return <ItemPromotor>
                      <Typography>{id}</Typography>
                      <ListAll {...provided.droppableProps} ref={provided.innerRef} sx={{backgroundColor:'#4D8000'}}  >
                        {list.map((influencer, index) => (
                          <EASInfluencersGoupsItem key={influencer.id_influencer} influencer={influencer} index={index} />
                        ))}
                        {provided.placeholder}
                      </ListAll> 
                    </ItemPromotor>;
          case 'Neutros':
            return <ItemNeutral>
                      <Typography>{id}</Typography>
                      <ListAll {...provided.droppableProps} ref={provided.innerRef} sx={{backgroundColor:'#bbacac'}}  >
                        {list.map((influencer, index) => (
                          <EASInfluencersGoupsItem key={influencer.id_influencer} influencer={influencer} index={index} />
                        ))}
                        {provided.placeholder}
                      </ListAll> 
                    </ItemNeutral>;
          default:
            return <ItemInfluencers>
                      <Typography>{id}</Typography>
                      <ListInfluencers {...provided.droppableProps} ref={provided.innerRef} >
                        {list.map((influencer, index) => (
                          <EASInfluencersGoupsItem key={influencer.id_influencer} influencer={influencer} index={index} />
                        ))}
                        {provided.placeholder}
                      </ListInfluencers> 
                    </ItemInfluencers>;
        }}}
      </Droppable>
  );
};