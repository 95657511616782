
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { useSnackbar } from 'notistack';

import {Grid, Card, Paper, Container, Box, TextField, Typography, Link, LinearProgress, Alert, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material/';

import { IResultsPresentation, IPresentationFinal} from '../../shared/contexts/ResultsPresentationContext/types';

import { LayoutBaseDePagina } from '../../shared/layouts';
import { useCourseContext , useTeamContext, useAuthContext } from '../../shared/contexts';
import { ResultsPresentationService } from '../../shared/services/api/results-presentation/ResultsPresentationService';


export const Presentations = () => {

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();
  const [isLoading, setIsLoading] = useState(true);

  const [resultsPresentation, setResultsPresentation] = useState<IResultsPresentation | null>(null);
  

  const [openMindset, setOpenMindset] = useState<boolean>(false);
  const [loadingMindset, setLoadingMindset] = useState<boolean>(false);

  const [textMindset, setTextMindset] = useState<string>('');
  const [textMindsetError, setTextMindsetError] = useState<string>('');

  const [openAction, setOpenAction] = useState<boolean>(false);
  const [loadingAction, setLoadingAction] = useState<boolean>(false);

  const [textAction, setTextAction] = useState<string>('');
  const [textActionError, setTextActionError] = useState<string>('');

  const actionSchema = yup.object().shape({
    textAction: yup.string().required('Descreva suas ações')
  });

  const mindsetSchema = yup.object().shape({
    textMindset: yup.string().required('Descreva suas ações')
  });

  useEffect(() => {
    getEvaluationsFinal();
  }, []);

  async function getEvaluationsFinal() {
    setIsLoading(true);
    if(enrollment.id_inscricao){
      const resultsPresentation = await ResultsPresentationService.getAllResultsPresentation((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), team.id_equipe, team.id_mentoria_tean, 0, 1);
      if (resultsPresentation instanceof Error) {
        setIsLoading(false);
        setResultsPresentation(null);
      } else {

        const myResultsPresentation = JSON.parse(JSON.stringify(resultsPresentation.data));
        setResultsPresentation(myResultsPresentation);

        if(myResultsPresentation.presentation_final && myResultsPresentation.presentation_final.text_mindset_presentation_final)
          setTextMindset(myResultsPresentation.presentation_final.text_mindset_presentation_final);
          
        if(myResultsPresentation.presentation_final && myResultsPresentation.presentation_final.text_action_presentation_final){
          setTextAction(myResultsPresentation.presentation_final.text_action_presentation_final); 

        }
        setIsLoading(false);
      }
    }
  }

  const handleButtonCancelAction = () => {
    setOpenAction(false);
  };

  const handleButtonSaveAction = async () => {
    if(resultsPresentation){
      if (!loadingAction) {
        actionSchema
          .validate({ textAction }, { abortEarly: false })
          .then(validateValues => {
            setLoadingAction(true);
            const presentationFinal: IPresentationFinal = {
              id_presentation_final: (resultsPresentation.presentation_final && resultsPresentation.presentation_final.id_presentation_final ? resultsPresentation.presentation_final.id_presentation_final : ''),
              id_inscricao: (team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao),
              id_equipe: team.id_equipe,
              id_mentoria_tean: team.id_mentoria_tean,
              status_presentation_final: '1',
              text_action_presentation_final: textAction.toString(),
              text_mindset_presentation_final: (resultsPresentation.presentation_final && resultsPresentation.presentation_final.text_mindset_presentation_final ? resultsPresentation.presentation_final.text_mindset_presentation_final : ''),
            }; 
            if(presentationFinal.id_presentation_final != ''){
              ResultsPresentationService.updateResultsPresentation(presentationFinal).then((responseResultsPresentation) => {
                if (responseResultsPresentation instanceof Error) {
                  enqueueSnackbar('Erro ao tentar salvar informações final', { variant:'error'});
                  setLoadingAction(false);
                } else {
                 
                 // resultsPresentation.presentation_final = presentationFinal;
                  getEvaluationsFinal().then(() => {
                    setLoadingAction(false);
                    setOpenAction(false);
                  });
                  //setResultsPresentation(resultsPresentation);

                }
              });
            }
            else {          
              ResultsPresentationService.createResultsPresentation(presentationFinal).then((responseResultsPresentation) => {
                if (responseResultsPresentation instanceof Error) {
                  enqueueSnackbar('Erro ao tentar salvar suas estratégias', { variant:'error'});
                  setLoadingAction(false);
                } else {
                 // if( responseResultsPresentation )
                  //  presentationFinal.id_presentation_final = responseResultsPresentation.id;

                 // resultsPresentation.presentation_final = presentationFinal;
                //  setResultsPresentation(resultsPresentation);

                  getEvaluationsFinal().then(() => {
                    setLoadingAction(false);
                    setOpenAction(false);
                  });
                 // setLoadingAction(false);
                  //setOpenAction(false);
                }
              });
            }
            
          })
        .catch((errors: yup.ValidationError) => {
          setLoadingAction(false);
          errors.inner.forEach(error => {
            if (error.path === 'textAction')
              setTextActionError(error.message);
          });
        });
      }
    }
   };
  
  const handleButtonCancelMindset = () => {
    setOpenMindset(false);
  };

  const handleButtonSaveMindset = () => {
    if(resultsPresentation){
      if (!loadingMindset) {
        mindsetSchema
          .validate({ textMindset }, { abortEarly: false })
          .then(validateValues => {
            setLoadingMindset(true);
            const presentationFinal: IPresentationFinal = {
              id_presentation_final: (resultsPresentation.presentation_final && resultsPresentation.presentation_final.id_presentation_final ? resultsPresentation.presentation_final.id_presentation_final : ''),
              id_inscricao: (team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao),
              id_equipe: team.id_equipe,
              id_mentoria_tean: team.id_mentoria_tean,
              status_presentation_final: '1',
              text_action_presentation_final: (resultsPresentation.presentation_final && resultsPresentation.presentation_final.text_action_presentation_final ? resultsPresentation.presentation_final.text_action_presentation_final : ''),
              text_mindset_presentation_final: textMindset.toString(),
            }; 


            if(presentationFinal.id_presentation_final != ''){
              ResultsPresentationService.updateResultsPresentation(presentationFinal).then((responseResultsPresentation) => {
                if (responseResultsPresentation instanceof Error) {
                  enqueueSnackbar('Erro ao tentar salvar nota final', { variant:'error'});
                  setLoadingMindset(false);
                } else {
                 // resultsPresentation.presentation_final = presentationFinal;
                 // setResultsPresentation(resultsPresentation);
                 
 
                   getEvaluationsFinal().then(() => {
                    setLoadingMindset(false);
                    setOpenMindset(false);
                  });

                }
              });
            }
            else {          
              ResultsPresentationService.createResultsPresentation(presentationFinal).then((responseResultsPresentation) => {
                if (responseResultsPresentation instanceof Error) {
                  enqueueSnackbar('Erro ao tentar salvar suas estratégias', { variant:'error'});
                  setLoadingMindset(false);
                } else {
                  getEvaluationsFinal().then(() => {
                    setLoadingMindset(false);
                    setOpenMindset(false);
                  });
                  /*
                  if( responseResultsPresentation )
                    presentationFinal.id_presentation_final = responseResultsPresentation.id;

                  resultsPresentation.presentation_final = presentationFinal;
                  setResultsPresentation(resultsPresentation);
                  setLoadingMindset(false);
                  setOpenMindset(false);*/
                }
              });
            }
            
          })
        .catch((errors: yup.ValidationError) => {
          setLoadingMindset(false);
          errors.inner.forEach(error => {
            if (error.path === 'textMindset')
              setTextMindsetError(error.message);
          });
        });
      }
    }
  };



  return (
    <LayoutBaseDePagina
      titulo='&nbsp;Apresentações'
      iconTitle='co_present'
    >
      <Box width='100vw'  display='flex' sx={{overflow:'hidden', mt:5}}>
        <Container>
          <Box sx={{maxWidth: 900, margin: 'auto', display: 'flex', textAlign: 'center', alignItems: 'center',}} >
            <Paper sx={{p:5}}>
              <Typography variant="h6" sx={{textAlign:'left', mb:0 }} paragraph>
                Veja como está seu programa
              </Typography>
              <Typography variant="body2" sx={{textAlign:'left', mb:2}} paragraph>
                O aplicativo monta automaticamente uma apresentação final sobre seu programa de mentoria. 
              </Typography>
              <Typography variant="body2" sx={{textAlign:'left', mb:2}} paragraph>
                Para que a apresentação fique completa, você deve preencher as informações abaixo. 
              </Typography>
              <Grid container spacing={2} sx={{p:2}}>
                <Grid item xs={12} sm={12}>
                  <Button onClick={() => setOpenMindset(true)} variant="contained">
                    QUAIS FORAM OS PRINCIPAIS APRENDIZADOS?
                  </Button>
                  <Dialog 
                    open={openMindset}
                    keepMounted
                    onClose={handleButtonCancelMindset}
                    aria-describedby="alert-dialog-slide-description"
                    fullWidth
                    maxWidth="lg"
                  >
                  <DialogTitle>
                    <Typography variant="h6" sx={{ textAlign:'left', mb:0 }} paragraph>
                      Quais foram os principais aprendizados?
                    </Typography>
                    <Typography variant="body2" sx={{textAlign:'left', mb:0 }} paragraph>
                      Quais foram os aprendizados que representam uma mudança de mindset (um novo jeito de perceber as situações)?
                    </Typography>                  
                  </DialogTitle>
                  <DialogContent>
                    {loadingMindset && (
                      <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress variant='indeterminate' />
                      </Box>
                    )}
                    {!loadingMindset && (  
                    <>
                      <Box
                        component="form"
                        sx={{
                          '& .MuiTextField-root': { my: 1},
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          label="Descreva os principais aprendizados."
                          multiline
                          value={textMindset}
                          onChange={(event) => setTextMindset(event.target.value)}
                          onFocus={() => setTextMindsetError('')}
                          fullWidth
                          error={!!textMindsetError}
                          helperText={textMindsetError}
                          rows={8}
                        />
                      </Box>
                    </> 
                    )}
                  </DialogContent>
                  <DialogActions sx={{mr:2, mb:1}}>
                      <Button onClick={handleButtonCancelMindset} variant="contained">Cancelar</Button>
                      <Button onClick={handleButtonSaveMindset} variant="contained">Salvar</Button>
                    </DialogActions>
                  </Dialog>
                </Grid> 
                <Grid item xs={12} sm={12}>
                  <Button onClick={() => setOpenAction(true)} variant="contained">
                  QUAIS FORAM AS SUAS ESTRATÉGIAS?
                  </Button>
                  <Dialog 
                    open={openAction}
                    keepMounted
                    onClose={handleButtonCancelAction}
                    aria-describedby="alert-dialog-slide-description"
                    fullWidth
                    maxWidth="lg"
                  >
                  <DialogTitle>
                    <Typography variant="h6" sx={{ textAlign:'left', mb:0 }} paragraph>
                      Quais foram as suas estratégias?
                    </Typography>
                    <Typography variant="body2" sx={{textAlign:'left', mb:0 }} paragraph>
                      Quais Estratégias / ações que estão sendo executadas para a sustentabilidade do desenvolvimento após o processo de Mentoria?
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    {loadingAction && (
                      <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress variant='indeterminate' />
                      </Box>
                    )}
                    {!loadingAction && (  
                    <>
                      <Box
                        component="form"
                        sx={{
                          '& .MuiTextField-root': { my: 1},
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          label="Descreva as suas estratégias"
                          multiline
                          value={textAction}
                          onChange={(event) => setTextAction(event.target.value)}
                          onFocus={() => setTextActionError('')}
                          fullWidth
                          error={!!textActionError}
                          helperText={textActionError}
                          rows={8}
                        />
                      </Box>
                    </> 
                    )}
                  </DialogContent>
                  <DialogActions sx={{mr:2, mb:1}}>
                      <Button onClick={handleButtonCancelAction} variant="contained">Cancelar</Button>
                      <Button onClick={handleButtonSaveAction} variant="contained">Salvar</Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item xs={12} sm={12} >               
                  {resultsPresentation && resultsPresentation.total_objective_detail && resultsPresentation.total_objective_detail > 0 ? ( 
                  <Button onClick={() => navigate('/apresentacoes/apresentacao-dos-resultados')} variant="contained">
                    Abrir Apresentação dos Resultados
                  </Button>
                  ) : ( 
                    ( isLoading ? (
                      <LinearProgress variant='indeterminate' sx={{ mt:'15px'}} />
                    ) : (
                      <Alert severity="warning">Não há nenhuma Competência e/ou Detalhamento cadastrado.</Alert>
                  )))}
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Container>
      </Box>
    </LayoutBaseDePagina>
  );
};
