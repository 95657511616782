
import React, { useState, useEffect } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';

import { useTeamContext} from '../../contexts';
import { EASInfluencersGoupsColumn } from './EASInfluencersGoupsColumn';

import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

interface IColumnItem {
  id?: string;
  list?: Array<IInfluencer>;
}  

interface IColumnGroup extends IColumnItem {
  Influenciadores: IColumnItem;
  Promotores: IColumnItem;
  Neutros: IColumnItem;
  Detratores: IColumnItem;
}  

interface IEASInfluencersGoupsContainerProps {
  columns?: IColumnGroup;
  updateInfluencer:(Influencer : IInfluencer) => void;
}  

export const EASInfluencersGoupsContainer: React.FC<IEASInfluencersGoupsContainerProps> = ({ columns, updateInfluencer }) => {

  const { enqueueSnackbar } = useSnackbar();

 // const [influencer, setInfluencer] = useState<IInfluencer | null>(null);
  const [columnsContainer, setColumnsContainer] = useState<IColumnGroup>({Influenciadores: {id: 'Influenciadores',list: [],}, Promotores: {id: 'Promotores',list: [],},Neutros: {id: 'Neutros',list: [],},Detratores: {id:'Detratores',list: [],}});

  const { ...team } = useTeamContext();

  const StyledColumns = styled('div') ({
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gridTemplateRows: '3fr 3fr 3fr',    
   // gridTemplateAreas:'sidebar main main main',
    
    margin: '0 auto',
    width: '100%',
    gap: '25px'
  });

  useEffect(() => {
    async function getInfluencers() {
      if(columns)
        setColumnsContainer(columns);
    }
    getInfluencers();
  }, [team.id_equipe]);

  const uploadPosition = ( influencer:IInfluencer, position:string) => {

    if(influencer && position) {
      

      const groupId = (()=> { 
        switch(position) {
          case 'Detratores': return '3';
          case 'Neutros': return '2';
          case 'Promotores': return '1';
          default: return '0';
     }})();



      const moveInfluencer: IInfluencer = {
        id_influencer: influencer.id_influencer,
        id_inscricao: influencer.id_inscricao,
        id_equipe: influencer.id_equipe,
        id_mentoria_tean: influencer.id_mentoria_tean,
        name_influencer: influencer.name_influencer,
        order_influencer: influencer.order_influencer,
        positive_influencer: influencer.positive_influencer,
        negative_influencer: influencer.negative_influencer,
        group_influencer: groupId,
        distance_influencer: influencer.distance_influencer
      };   
      //alert(groupId);
      InfluencersService.updateInfluencers(moveInfluencer)
        .then((influencerResponse) => {
          if (influencerResponse instanceof Error) {
            enqueueSnackbar('Erro ao mover influenciador.', { variant:'error'});
          } 
          else          {
           updateInfluencer(moveInfluencer);
          }
      }).catch(() => {
        enqueueSnackbar('Erro ao mover influenciador.', { variant:'error'});
      });
    }
    else{
      enqueueSnackbar('Erro ao mover influenciador.', { variant:'error'});
    }
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;
    if (source === undefined || source === null) return null;
    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    ) return null;

    const start = (columnsContainer as any)[source.droppableId];   
    const end = (columnsContainer as any)[destination.droppableId];
  
    
      // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      );

      // Then insert the item at the right location
      newList.splice(destination.index, 0, start.list[source.index]);

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        list: newList
      };

      // Update the state
      setColumnsContainer(state => ({ ...state, [newCol.id]: newCol }));
      return null;
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const newStartList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      );

      // Create a new start column
      const newStartCol = {
        id: start.id,
        list: newStartList
      };
      // Make a new end list array
      const newEndList = end.list;

      //const moveInfluencer:IInfluencer = start.list[source.index];
      

      uploadPosition(start.list[source.index], end.id );

      // Insert the item into the end list
      newEndList.splice(destination.index, 0, start.list[source.index]);

      //alert(JSON.stringify(newEndList));
      // Create a new end column
      const newEndCol = {
        id: end.id,
        list: newEndList
      };

      // Update the state
      setColumnsContainer(state => ({
        ...state,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol
      }));

     // group_influencer

    //  alert(JSON.stringify(newEndCol));
      return null;

    }
  };

  return (
    
      <DragDropContext onDragEnd={onDragEnd}>
        <StyledColumns>
          {Object.values(columnsContainer).map(col => (
            <EASInfluencersGoupsColumn item={col} key={col.id} />
          ))}
        </StyledColumns>
      </DragDropContext>
  );
};