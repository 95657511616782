import { createContext, useCallback, useMemo, useState, useContext } from 'react';
import { ITaskPlanContextData, ITaskPlanProviderProps, ITaskPlan } from './types';

export const TaskPlanContext = createContext<ITaskPlanContextData>({} as ITaskPlanContextData);

export const TaskPlanProvider: React.FC<ITaskPlanProviderProps> = ({ children }) => {

  const [idTaskPlan, setIdTaskPlan] = useState<string | null>(null);
  const [allTaskPlans, setAllTaskPlans] = useState<Array<ITaskPlan> | null>(null);
  const [taskPlan, setTaskPlan] = useState<ITaskPlan | null>(null);

  const handleTaskPlan = useCallback(async (taskPlan: ITaskPlan) => {
    if(taskPlan.id_inscricao){
      setIdTaskPlan(taskPlan.id_inscricao);
      setTaskPlan(taskPlan);
      setAllTaskPlans(null);
    }
  }, []);

  const isLoaded = useMemo(() => ((idTaskPlan == 'error') ? false : (!(idTaskPlan) && !(allTaskPlans) ? null : true )), [idTaskPlan]);

  return (
    <TaskPlanContext.Provider value={{ isLoaded, allTaskPlans, ...taskPlan, accessTaskPlan : handleTaskPlan  }}>
      {children}
    </TaskPlanContext.Provider>
  );
};

export const useTaskPlanContext = () => useContext(TaskPlanContext);


