import { useState } from 'react';
import * as yup from 'yup';
import { Box, TextField, Fab, CircularProgress} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { green } from '@mui/material/colors';


import { useTeamContext, useCourseContext } from '../../contexts';
import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

import { useSnackbar } from 'notistack';

interface IInfluencersListAddProps {
  addInfluencer:(influencer : IInfluencer) => void;
}


export const EASInfluencersListAdd: React.FC<IInfluencersListAddProps> = ({ addInfluencer })  => {


  const { ...team } = useTeamContext();
  const { ...enrollment } = useCourseContext();

  const { enqueueSnackbar } = useSnackbar();

  const [nameInfluencer, setNameInfluencer] = useState<string>('');
  const [nameInfluencerError, setNameInfluencerError] = useState<string>('');
  
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);


  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  const InfluencerSchema = yup.object().shape({
    nameInfluencer: yup.string().required('Informe o nome do influenciador')
  });



  const handleButtonClick = () => {
    if (!loading) {
     InfluencerSchema
      .validate({ nameInfluencer }, { abortEarly: false })
      .then(validateValues => {
        setSuccess(false);
        setLoading(true);
        const influencer: IInfluencer = {
          id_influencer: '',
          id_inscricao: (team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao),
          id_equipe: team.id_equipe,
          id_mentoria_tean: team.id_mentoria_tean,
          name_influencer: validateValues.nameInfluencer,
          order_influencer: '0',
          positive_influencer:  '',
          negative_influencer: '',
          group_influencer: '',
          distance_influencer: '',

        };                 // const date = new Date(newValue);
        InfluencersService.createInfluencers(influencer)
          .then((InfluencerResponse) => {
            if (InfluencerResponse instanceof Error) {
              setLoading(false);
              setSuccess(false);
              enqueueSnackbar('Erro ao cadastrar Influenciador.', { variant:'error'});
            } else {
              setLoading(false);
              setSuccess(true);
              enqueueSnackbar('Tarefa cadastrada com sucesso!', { variant:'success'});
              addInfluencer(influencer);
              setNameInfluencer(''); 
              setNameInfluencerError('');
              setSuccess(false);
                

            }
          }).catch(() => {
            //alert(taskPlan);
            setLoading(false);
            setSuccess(false);
            enqueueSnackbar('Erro ao cadastrar Influenciador.', { variant:'error'});
          });
      })
      .catch((errors: yup.ValidationError) => {
        setLoading(false);
        errors.inner.forEach(error => {
          if (error.path === 'nameInfluencer') {
            setNameInfluencerError(error.message);
          }
        });
      });
    }
  };

  return (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <TextField
            label="Nome do Influenciador"
            variant="outlined"
            value={nameInfluencer}
            onChange={(event) => setNameInfluencer(event.target.value)}
            onFocus={() => setNameInfluencerError('')}
            fullWidth
            error={!!nameInfluencerError}
            helperText={nameInfluencerError}
          />
        
          <Box sx={{ m: 1, position: 'relative' }}>
            <Fab
              size="small"
              aria-label="save"
              color="primary"
              sx={buttonSx}
              onClick={handleButtonClick}

            >
              {success ? <CheckIcon /> : <AddIcon />}
            </Fab>
            {loading && (
              <CircularProgress
                size={48}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: -4,
                  left: -4,
                  zIndex: 1,
                }}
              />
            )}
          </Box>
        </Box>    
  );
};