
import React, { useState, useEffect } from 'react';
import {Box, Typography, Container, Alert, Grid, LinearProgress, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { useTeamContext, useCourseContext } from '../../contexts';
import { EASToolsLabelMappingItem } from './EASToolsLabelMappingItem';
import { EASToolsLabelMappingEdit } from './EASToolsLabelMappingEdit';

import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

import { useNavigate } from 'react-router-dom';
import { EASCard } from '../eas-card/EASCard';

export const EASToolsLabelMapping: React.FC = () => {

  const [influencer, setInfluencer] = useState<IInfluencer | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [messageInfluencers, setMessageInfluencers] = useState<boolean>(false);

  const [allInfluencers, setAllInfluencers] = useState<Array<IInfluencer> | null>(null);
  const [totalCount, setTotalCount] = useState(0);

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();

  const navigate = useNavigate();

  useEffect(() => {
    async function getInfluencers() {
      if(enrollment.id_inscricao){
        setIsLoading(true);
        const influencers = await InfluencersService.getAllInfluencers((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), team.id_equipe, team.id_mentoria_tean);
        if (influencers instanceof Error) {
          setAllInfluencers(null);
          setTotalCount(0);
          setIsLoading(false);
        } else {
          if(influencers.data && influencers.total){
            const myInfluencers:Array<IInfluencer> = JSON.parse(JSON.stringify(influencers.data));
            setAllInfluencers(myInfluencers);
            setIsLoading(false);
            if(!influencers.total)
              setMessageInfluencers(true);
            setTotalCount(influencers.total);

          }
          else{
            
            setMessageInfluencers(true);
            setAllInfluencers(null);
            setTotalCount(0);
            setIsLoading(false);
          }
        }
      }
    }
    getInfluencers();
  }, [team.id_equipe]);












  
  const updateInfluencer = (influencerUpdate?:IInfluencer) => {
    if (influencerUpdate && allInfluencers) {
      const newAllInfluencers = allInfluencers.map(influencer => {
        if (influencerUpdate.id_influencer !== influencer.id_influencer) {
          return influencer;
        } else {
          return influencerUpdate;
        }
      });
      setAllInfluencers(newAllInfluencers);
    }
  };


  const handleClickCancelEdit = () => {
      setInfluencer(null);
  };
   
  return (
    <Container>
      {isLoading && (
        <LinearProgress variant='indeterminate' sx={{ mt:'15px'}} />
      )}
      {!isLoading && (
      <Box sx={{pb:10}}>
        <EASCard>
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" sx={{textAlign: 'center', marginBottom:'5px'}}>
              Quais são os rótulos positivos e negativos que estes influenciadores têm a seu respeito, na sua percepção?
            </Typography>
          </Box>
          {(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' ) && (
          <Box sx={{ p: 3 }}>
            <Typography variant="h5">Este programa foi encerrado</Typography>
            {((team.type_tean === '0')  && (
              <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
            ))}
          </Box>
          )}
          <EASToolsLabelMappingEdit finishEditInfluencer={(e) => updateInfluencer(e)} cancelEditInfluencer={() => handleClickCancelEdit()} influencer={influencer} />
          <Grid container spacing={2}>
            {(allInfluencers && allInfluencers.length) ? (allInfluencers.map((influencer) => (
            <EASToolsLabelMappingItem
              key={influencer.id_influencer} 
              influencer={influencer} 
              setInfluencer={(e) => setInfluencer(e)} 
              //handleClickDelete={(e) => handleClickDelete(e)}
              updateInfluencer={(e) => updateInfluencer(e)}
            />
            ))):(
            <Alert severity="warning" >Nenhum influenciador cadastrado!</Alert>
            )}
          </Grid>
          <Box display="flex" flexDirection="column" alignItems="center" sx={{pt:'35px'}}>
            <Button variant="contained" onClick={() => navigate('/ferramentas-auxiliares')}>Concluir</Button>
          </Box>
        </EASCard>
      </Box>
      )}
      <Dialog
        maxWidth="xs"
        open={messageInfluencers}
      >
        <DialogTitle>Nenhum Influenciador Cadastrado</DialogTitle>
        <DialogContent>
          <Typography>
              Para que você possa utilizar esta ferramenta, é necessário primeiro cadastrar os influenciadores.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate('/ferramentas-auxiliares/influenciadores-de-reputacao')}>Ir para o cadastro de influenciadores</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};