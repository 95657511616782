import { Api } from '../axios-config';
import { IEvaluationPartialComment } from '../../../contexts/EvaluationPartialContext/types';

interface IEvaluationPartialCommentService {
  Teams?: string;
}

const createEvaluationPartialComment = async (evaluationPartialComment: object): Promise<IEvaluationPartialCommentService | Error> => {
  try {
    const { data } = await Api.post('/evaluationpartialcomment', { evaluationPartialComment: evaluationPartialComment } );
  
    if (data) 
      return data;
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateEvaluationPartialComment = async (evaluationPartialComment: IEvaluationPartialComment): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/evaluationpartialcomment/${evaluationPartialComment.id_evaluation_partial_comment}`, evaluationPartialComment);
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getEvaluationPartialComment = async (userEnrollment: string, client_id: string): Promise<IEvaluationPartialCommentService | Error> => {
  try {
    const { data } = await Api.post('/evaluationpartial', { userEnrollment: userEnrollment, client_id: client_id } );
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

export const EvaluationPartialCommentService = {
  getEvaluationPartialComment,
  createEvaluationPartialComment,
  updateEvaluationPartialComment,
};
