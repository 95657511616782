import React, { useState , useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import { TimelineContent, TimelineOppositeContent  } from '@mui/lab';
import TimelineDot from '@mui/lab/TimelineDot';


import {Paper, Typography, CircularProgress, Fab,  Box, Pagination, Tabs, Tab, Container, styled , Alert, List, ListItem, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemText, ListItemSecondaryAction, IconButton, LinearProgress  } from '@mui/material/';

import AddIcon from '@mui/icons-material/Add';
import { green } from '@mui/material/colors';


import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import AddTaskIcon from '@mui/icons-material/AddTask';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';



import { EASLearningNew } from '../eas-learning-new/EASLearningNew';
import { EASLearningEdit } from '../eas-learning-edit/EASLearningEdit';

import { ILearning } from '../../contexts/LearningContext/types';
import { useCourseContext , useTeamContext} from '../../contexts';

import { Environment } from '../../environment';





import { LearningService } from '../../services/api/learning/LearningService';
import { useSnackbar } from 'notistack';
import { EASCard } from '../eas-card/EASCard';

interface ILearningProps {
  children?: () => void;
}  

const IconButtonChecked = styled(IconButton)(() => ({
  color: green[500]
}));


export const EASLearning: React.FC<ILearningProps> = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();
  
  const [open, setOpen] = useState(false);
  const [newOpen, setNewOpen] = useState(false);

  

  const [updateList, setUpdateList] = useState(false);

  const [idLearning, setIdLearning] = useState<string | null>(null);
  const [allLearnings, setAllLearnings] = useState<Array<ILearning> | null>(null);
  const [learning, setLearning] = useState<ILearning | null>(null);
  const [filterLearning, setFilterLearning] = useState<number>(0);
  const [totalCount, setTotalCount] = useState(0);

  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  useEffect(() => {
    async function getLearnings() {
      
      if(enrollment.id_inscricao){
        setIsLoading(true);
        const learnings = await LearningService.getAllLearning((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), team.id_equipe, team.id_mentoria_tean, filterLearning, pagina);
        if (learnings instanceof Error) {
          setAllLearnings(null);
          setTotalCount(0);
          setIdLearning('error');
          setLearning(null);
          setIsLoading(false);
        } else {
          if(learnings.data && learnings.total){
            const myLearnings = JSON.parse(JSON.stringify(learnings.data));
            setAllLearnings(myLearnings);
            setIsLoading(false);
            if(learnings.total)
              setTotalCount(learnings.total);
          }
          else{
            setAllLearnings(null);
            setTotalCount(0);
            setIdLearning('error');
            setLearning(null);
            setIsLoading(false);
          }
        }
      }
    }
    getLearnings();
  }, [filterLearning, searchParams, updateList, team.id_equipe]);

  const handleNewLearning = () => {
    setNewOpen(true);
  };

  const handleChangeFilter = (event: React.SyntheticEvent, newValue: number) => {
    setFilterLearning(newValue);
  };



  const handleClickUnCheck  = (learning?:ILearning) => {
    if(learning && learning.id_learning){
      learning.status_learning = '0';
      LearningService.updateLearning(learning).then((idLearning) => {
        if (idLearning instanceof Error) {
          setIdLearning(null);
          setLearning(null);
          setIsLoading(false);
          enqueueSnackbar('Erro ao tentar reabrir a tarefa!', { variant:'error'});
        } else {
          
            setUpdateList(!updateList); 
            enqueueSnackbar('Tarefa reaberta com sucesso!', { variant:'success'});
            setIdLearning(null);
            setIsLoading(false);
        }
      });
    }
  }; 

  const handleClickCheck  = (learning?:ILearning) => {
    if(learning && learning.id_learning){
      learning.status_learning = '1';
      LearningService.updateLearning(learning).then((idLearning) => {
        if (idLearning instanceof Error) {
          setIdLearning(null);
          setLearning(null);
          setIsLoading(false);
          enqueueSnackbar('Erro ao tentar finalizar a tarefa!', { variant:'error'});
        } else {
          
            setUpdateList(!updateList);  
            enqueueSnackbar('Tarefa finalizada com sucesso!', { variant:'success'});
            setIdLearning(null);
            setIsLoading(false);
        }
      });
    }
  }; 

  const handleClickDelete  = (id_learning?:string) => {
    if(id_learning){
      setIdLearning(id_learning);
      setOpen(true);
    }
  }; 

  const handleConfirme = () => {
    deleteLearning();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setIdLearning(null);
  };

  const deleteLearning = () => {

    if(idLearning){
      LearningService.deleteLearning(idLearning).then((learning) => {
        if (learning instanceof Error) {
          setIdLearning(null);
          setLearning(null);
          setIsLoading(false);
          enqueueSnackbar('Erro ao tentar remover a tarefa!', { variant:'error'});
        } else {
            /*if(allLearnings){
              const newAllLearnings = allLearnings.filter((learning) => learning.id_learning !== idLearning);
              setAllLearnings(newAllLearnings);
            } */
            setUpdateList(!updateList); 
            enqueueSnackbar('Tarefa removida com sucesso!', { variant:'success'});
            setIdLearning(null);
            setIsLoading(false);
            
        }
      });
    }
  };

  const handleClickEdit = (learning?:ILearning) => {
    if(learning){
      setLearning(learning);
     // setOpenDialog(true);
    }
  };

  const updateAllLearnings = (learning:ILearning | null) => {
    //const teste = filterLearning + 1 - 1;
    setLearning(null);
    if(learning!=null)
      setUpdateList(!updateList); 
  };

  const addLearning = (learning:ILearning) => {
    //const teste = filterLearning + 1 - 1;
    setUpdateList(!updateList); 
  };

  return (
        <Box sx={{marginX:'auto'}} style={{ display: 'flex', justifyContent: 'center'}}>
        {(!(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' )) && ( 
        <>
          <EASLearningNew addLearning={(createdLearning) => addLearning(createdLearning) } closeLearning={() => setNewOpen(false)} newOpen={newOpen} />
          <EASLearningEdit finishEditLearning={(Learnings) => updateAllLearnings(Learnings)} learning={learning} />
        </>
        )}
        <Timeline sx={{ '& .MuiTimelineItem-root:before': {flex: 0,}, p:0}}>
          {(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' ) && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography variant="h5">Este programa foi encerrado</Typography>
              {((team.type_tean === '0')  && (
                <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
              ))}
            </Paper>
          )}
          {(/*team.type_tean =='0' && */ enrollment.status_mentory_inscricao !='1') && ( 
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot>
                <Fab
                  size="small"
                  aria-label="save"
                  color="primary"
                  sx={{float:'right', }}
                  onClick={handleNewLearning}
                >
                  <AddIcon />
                </Fab>
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="h6" component="span">Adicionar Novo Registro</Typography>
              <Typography>Adicione um novo registro no seu Diário de Aprendizagem</Typography>
            </TimelineContent >
          </TimelineItem>
          )}
          {isLoading && (
          <LinearProgress variant='indeterminate' sx={{ mt:'15px'}} />
          )}
          {!isLoading && (allLearnings && allLearnings.length ? (allLearnings.map((learning) => (
          <TimelineItem key={learning.id_learning} >
            <TimelineSeparator sx={{flex:0}}>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <EASCard> 
                <Box style={{ display: 'flex', }}>
                  <Typography variant="h6">                
                    {learning.date_learning ? new Date(learning.date_learning + 'T23:28:56.782Z').toLocaleDateString('pt-BR') : ''}
                  </Typography>
                  {(!(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' )) && (
                  <Box style={{position:'absolute', right:'25px'}}>
                    <IconButton sx={{mb:'0', }} size="small" aria-label="Editar Item do Diário" onClick={() => handleClickEdit(learning)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton sx={{mb:'0', }} size="small" aria-label="Excluir Item do Diário" onClick={() => handleClickDelete(learning.id_learning)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                  )}
                </Box>
                {learning.value_learning && (
                  <Typography variant="subtitle2" sx={{mb:'4px', fontSize:'1.1em'}}>Sua Nota neste dia: {`${Math.round(parseInt(learning.value_learning))}`}</Typography>
                )}
                <Typography variant="subtitle2" sx={{mb:'0', fontSize:'1.0em'}} gutterBottom component="div">O que você fez de positivo que justifica esta nota?</Typography>
                <Typography variant="body1" sx={{mb:'8px'}} gutterBottom>{learning.text_learning}</Typography>
                <Typography variant="subtitle2" sx={{mb:'0', fontSize:'1.0em'}} gutterBottom component="div">O que faltou para que sua nota fosse &rdquo;dez&rdquo;?</Typography>
                <Typography variant="body2" sx={{mb:'8px'}} gutterBottom>{learning.lack_learning ? learning.lack_learning : '-'}</Typography>
                <Typography variant="subtitle2" sx={{mb:'0', fontSize:'1.0em'}} gutterBottom component="div">Causas</Typography>
                <Typography variant="body2" sx={{mb:'8px'}} gutterBottom>{learning.cause_learning ? learning.cause_learning : '-'}</Typography>
                <Typography variant="subtitle2" sx={{mb:'0', fontSize:'1.0em'}} gutterBottom component="div">Plano de Melhoria</Typography>
                <Typography variant="body2" gutterBottom>{learning.plan_learning ? learning.plan_learning : '-'}</Typography>

              </EASCard>
            </TimelineContent>
          </TimelineItem>
          ))) :
          <Alert severity="warning">Não há registros em seu diário de aprendizagem</Alert>
          )}
        </Timeline> 
        {!isLoading && totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
        <Box style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0' }}>
          <Pagination
            page={pagina}
            count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
            onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
            variant="outlined" 
            shape="rounded"
            color="primary"
            siblingCount={3} 
            boundaryCount={1}
          />
        </Box>
        )}
        <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            {'Excluir Item do Diário de Aprendizagem'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Você tem certeza que deseja excluir este item do diário de aprendizagem?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{mr:2, mb:1}}>
            <Button onClick={handleCancel} variant="contained">Desistir</Button>
            <Button onClick={handleConfirme} variant="contained" autoFocus>
              Sim, Excluir
            </Button>
          </DialogActions>
        </Dialog>    
      </Box>
  );
};