import React, { useState } from 'react';
import { Grid, Box, Paper, Typography, TextField, Fab, CircularProgress, Theme, useMediaQuery  } from '@mui/material/';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { green } from '@mui/material/colors';

import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import { IObjective, IObjectiveDetail } from '../../contexts/ObjectiveContext/types';
import { ObjectiveDetailService } from '../../services/api/objective-detail/ObjectiveDetailService';

interface IObjectivesDetailProps {
  id_objective?: string;
  addObjectiveDetail:(ObjectiveDetail : IObjective) => void;
}  

export const EASObjectivesDetailNew: React.FC<IObjectivesDetailProps> = ({id_objective, addObjectiveDetail}) => {


  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);


  const [textObjectiveDetail, setTextObjectiveDetail] = useState<string>('');
  const [valueObjectiveDetail, setValueObjectiveDetail] = useState<string>('');
  
  const [textObjectiveDetailError, setTextObjectiveDetailError] = useState<string>('');
  const [valueObjectiveDetailError, setValueObjectiveDetailError] = useState<string>('');

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  const ObjectiveDetail = yup.object().shape({
    textObjectiveDetail: yup.string().required('Detalhe uma Excelência'),
    valueObjectiveDetail: yup.string().required('Defina sua Nota.'),
  });

  const handleButtonClick = (id_objective?:string) => {

    if (!loading) {
      if(id_objective) {
        ObjectiveDetail
        .validate({ textObjectiveDetail, valueObjectiveDetail }, { abortEarly: false })
        .then(validateValues => {
          setSuccess(false);
          setLoading(true);
        
          const objectiveDetail: IObjectiveDetail = {
            id_objective_detail:'',
            id_objective: id_objective,
            status_objective_detail: '1',
            text_objective_detail: validateValues.textObjectiveDetail,
            value_objective_detail: validateValues.valueObjectiveDetail,
            priority_objective_detail: '0',
            date_objective_detail: new Date().toLocaleDateString('pt-BR'),
          };                 // const date = new Date(newValue);
          ObjectiveDetailService.createObjectiveDetail(objectiveDetail)
            .then((objectiveDetailResponse) => {
              if (objectiveDetailResponse instanceof Error) {
                setLoading(false);
                setSuccess(false);
                enqueueSnackbar('Erro ao cadastrar detalhamento nesta competência.', { variant:'error'});
              } else {
                setLoading(false);
                setSuccess(true);
                enqueueSnackbar('Detalhamento da competência cadastrada com sucesso!', { variant:'success'});
                addObjectiveDetail(objectiveDetail);
                setTextObjectiveDetail(''); 
                setTextObjectiveDetailError('');
                setValueObjectiveDetail(''); 
                setValueObjectiveDetailError('');
                setSuccess(false);
              }
            }).catch((objectiveDetail) => {
              setLoading(false);
              setSuccess(false);
              enqueueSnackbar('Erro ao cadastrar detalhamento nesta competência.', { variant:'error'});
            });
        })
        .catch((errors: yup.ValidationError) => {
          setLoading(false);
          errors.inner.forEach(error => {
            if (error.path === 'valueObjectiveDetail') {
              setValueObjectiveDetailError(error.message);
            } 
            else{
              setTextObjectiveDetailError(error.message);
            }
          });
        });
      }
    }
  };

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', marginBottom:'15px'}}>   
      <Paper style={{ padding: '0.5em 0', width:'100%' }} elevation={0}>
        <Typography sx={{textAlign: 'center',  fontWeight: '700',  fontSize:  '1.2em'}}>
          Adicione comportamentos observáveis nessa competência
        </Typography>  
        <Typography sx={{textAlign: 'center'}}>
          Detalhe comportamentos que uma pessoa excelente nessa competência teria e faça sua autoavaliação, atribuindo uma nota ao seu desempenho nesses itens atualmente.
        </Typography>  
        <Box sx={{  justifyContent: 'center', marginY:'15px'}}>  
          <Grid sx={{width:'100%'}} container spacing={2}>
            <Grid item xs={12} md={8} lg={8} xl={9}> 
              <TextField
                label={!xsDown && !smDown && !mdDown ? ('Escreva aqui o que uma pessoa excelente nesta competência faria'):('Comportamento ')}
                multiline
                value={textObjectiveDetail}
                onChange={(event) => setTextObjectiveDetail(event.target.value)}
                onFocus={() => setTextObjectiveDetail('')}
                error={!!textObjectiveDetailError}
                helperText={textObjectiveDetailError}
                sx={{width:'100%'}}
                
              />      
            </Grid>  
            <Grid item xs={12} md={4} lg={4} xl={3}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>  
                <TextField 
                  label={!xsDown && !smDown && !mdDown ? ('Sua nota') : ('Nota')}
                  type='number' 
                  value={valueObjectiveDetail} 
                  /*
                  onChange={(event) => {
                    switch (event.target.value){
                      case > 10:
                        valueObjectiveDetailError(10);
                        break;

                    } valueObjectiveDetailError(10);
                    if (event.target.value < 0) value = 0;
          
                    valueObjectiveDetailError(value);
                  }}
      */
                  onChange={(event) => setValueObjectiveDetail(event.target.value)}  
                  error={!!valueObjectiveDetailError}
                  helperText={valueObjectiveDetailError}
                  sx={{width:'100%'}}
                  InputProps={{
                    inputProps: { 
                        max: 10, min: 0 
                    }
                }}
                />  
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Fab
                    size="small"
                    aria-label="save"
                    color="primary"
                    sx={buttonSx}
                    onClick={(e) => handleButtonClick(id_objective)}
                  >
                    {success ? <CheckIcon /> : <AddIcon />}
                  </Fab>
                  {loading && (
                    <CircularProgress
                      size={48}
                      sx={{
                        color: green[500],
                        position: 'absolute',
                        top: -4,
                        left: -4,
                        zIndex: 1,
                      }}
                    />
                  )}
                </Box> 
              </Box>   
            </Grid>
          </Grid> 
        </Box>
      </Paper>
    </Box>
  );
};