import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { Icon, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

const SBDCListItemButton = styled(ListItemButton)(({ theme }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  margin:'8px 16px',
  borderRadius:'4px',
  border: '0',
  lineHeight: 1.5,
  backgroundColor: theme.palette.background.default,
  color:theme.palette.text.primary,
  borderLeft: '8px solid #EA5B0C',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: 'rgb(235 90 13 / 26%) !important',
    /*borderLeft: '8px solid #b340e4 !important',*/
    boxShadow: 'none',
    /*color:'#FFFFFF',*/
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'rgb(235 90 13 / 56%) !important',
    /*borderLeft: '8px solid #b340e4 !important',*/
    /*color:'#FFFFFF',*/
  },
  '&:focus': {
    boxShadow: 'none',
    backgroundColor: 'rgb(235 90 13 / 26%) !important',
   /* borderLeft: '8px solid #b340e4 !important',*/
    /*color:'#FFFFFF',*/
  },
  '&:selected': {
    boxShadow: 'none',
    backgroundColor: 'rgb(235 90 13 / 26%) !important',
    
    '& .MuiListItemIcon-root': {
      color: 'white'
    }
   /* borderLeft: '8px solid #b340e4 !important',*/
   /* color:'#FFFFFF',*/
  }, 

  '&$focused': {
    boxShadow: 'none',
    backgroundColor: 'rgb(235 90 13 / 26%) !important',
  },
}));

interface IEASBtnSidebarProps {
  to: string;
  icon: string;
  label: string;
  onClick: (() => void) | undefined;
}

export const EASBtnSidebar: React.FC<IEASBtnSidebarProps> = ({ to, icon, label, onClick }) => {

  const navigate = useNavigate();

  const resolvedPath = useResolvedPath(to);
  const match = useMatch({ path: resolvedPath.pathname, end: false });

  const handleClick = () => {
    navigate(to);
    onClick?.();
  };

  return (
    <SBDCListItemButton selected={!!match} onClick={handleClick} >
      <ListItemIcon sx={{minWidth:'40px'}}>
        <Icon>{icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={label}/>
    </SBDCListItemButton>
  );
};
   

