import React, { useState , useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams, useParams} from 'react-router-dom';
import {Paper, Typography, StepButton, Avatar, ListItemAvatar, Box, Container, Alert, List, ListItem, Button,  ListItemText, LinearProgress,  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle  } from '@mui/material/';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import * as yup from 'yup';


import { useCourseContext , useTeamContext, useAuthContext} from '../../contexts';
import { ITeam } from '../../contexts/TeamContext/types';

import { IEvaluationFinal, IEvaluationFinalResponse } from '../../contexts/EvaluationFinalContext/types';

import { IObjective } from '../../contexts/ObjectiveContext/types';

import { EASEvaluationFinalItem } from './EASEvaluationFinalItem';
import { EASEvaluationFinalText } from './EASEvaluationFinalText';

import { EvaluationFinalService } from '../../services/api/evaluation-final/EvaluationFinalService';
import { useSnackbar } from 'notistack';
import { EASCard } from '../eas-card/EASCard';


interface IEvaluationFinalProps {
  children?: () => void;
}  

export const EASEvaluationFinal: React.FC<IEvaluationFinalProps> = () => {

  const { enqueueSnackbar } = useSnackbar();
 

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();
  const { ...user } = useAuthContext();

  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [totalCount, setTotalCount] = useState(0);

  const [updateFinish, setUpdateFinish] = useState<boolean>(false);

  const [activeStep, setActiveStep] = useState(0);
  
  const [allEvaluationFinalResponse, setAllEvaluationFinalResponse] = useState<Array<IEvaluationFinalResponse> | null>(null);

  useEffect(() => {
    async function getEvaluationsFinal() {
      if(enrollment.id_inscricao){
        setIsLoading(true);
        const evaluationFinalResponse = await EvaluationFinalService.getAllEvaluationFinal((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), team.id_equipe, team.id_mentoria_tean);
        if (evaluationFinalResponse instanceof Error) {
          setAllEvaluationFinalResponse(null);
          setTotalCount(0);
          setIsLoading(false);
        } else {
          if(evaluationFinalResponse.data && evaluationFinalResponse.total){
            const myEvaluationFinal = JSON.parse(JSON.stringify(evaluationFinalResponse.data));
            setAllEvaluationFinalResponse(myEvaluationFinal);
            setIsLoading(false);
            if(evaluationFinalResponse.total)
              setTotalCount(evaluationFinalResponse.total); 
          }
          else{
            setAllEvaluationFinalResponse(null);
            setTotalCount(0);
            setIsLoading(false);
          }
        }
      }
    }
    getEvaluationsFinal();
  }, [updateFinish, team.id_equipe]);


  const handleNext = () => {
    if((allEvaluationFinalResponse && (activeStep < (allEvaluationFinalResponse.length - 1))))
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if((allEvaluationFinalResponse && (activeStep >= (allEvaluationFinalResponse.length - 1)))) {
        setOpen(true);
    }
  };
 

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleConfirme = () => {
    setOpen(false);
    setIsLoading(true);
    const finishEvaluationFinal: IEvaluationFinal = {
      id_inscricao: (team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao),
      id_equipe: team.id_equipe,
      id_mentoria_tean: team.id_mentoria_tean,
      status_evaluation_final: '2',
    }; 
    EvaluationFinalService.updateEvaluationFinal(finishEvaluationFinal).then((responseEvaluationFinal) => {
      if (responseEvaluationFinal instanceof Error) {
        enqueueSnackbar('Erro ao tentar Finalizar programa', { variant:'error'});
        setIsLoading(false);
      } else {
        if(team.type_tean=='1'){
          team.status_mentory_inscricao = '1';
          team.accessTeam(team);
        }
        else{
          enrollment.status_mentory_inscricao = '1';
          enrollment.accessCourse(enrollment);
        }
          
        setUpdateFinish(!updateFinish);
        enqueueSnackbar('Parabéns, processo concluído! Seu processo foi encerrado, se precisar reabrir, solicite ao seu mentor.', { variant:'success'});
      }
    });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {

    setIsLoading(true);
    const finishEvaluationFinal: IEvaluationFinal = {
      id_inscricao: (team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao),
      id_equipe: team.id_equipe,
      id_mentoria_tean: team.id_mentoria_tean,
      status_evaluation_final: '3',
    }; 
    EvaluationFinalService.updateEvaluationFinal(finishEvaluationFinal).then((responseEvaluationFinal) => {
      if (responseEvaluationFinal instanceof Error) {
        enqueueSnackbar('Erro ao tentar reabrir programa', { variant:'error'});
        setIsLoading(false);
      } else {
        if(team.type_tean=='1'){
          team.status_mentory_inscricao = '0';
          team.accessTeam(team);
        }
        else{
          enrollment.status_mentory_inscricao = '0';
          enrollment.accessCourse(enrollment);
        }
          
        setUpdateFinish(!updateFinish);
        enqueueSnackbar('O programa foi reaberto com sucesso', { variant:'success'});
      }
    });
  };

  return (
    <Container>
      <Box style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0' }}>
        <EASCard>
          <ListItem>
            
            <ListItemAvatar sx={{marginRight:'15px'}}>
              <Avatar sx={{ border:'1px solid #EA5B0C', height: '60px', width: '60px' }} 
                        alt={(team.type_tean=='1' ? team.name : user.name)} 
                        src={(team.type_tean=='1' ? team.avatar : user.avatar) && 'https://online.sbdc.com.br/media/images/avatar/' + (team.type_tean=='1' ? team.avatar : user.avatar)} />
            </ListItemAvatar>
            <ListItemText 
              primary={<Typography variant="h6">{(team.type_tean=='1' ? team.name : user.name)}</Typography>} 
              secondary={<Typography  sx={{fontSize:'12px'}} >
                            MENTORADO
                         </Typography>}
              />
          </ListItem>          
        </EASCard>
      </Box>
      {isLoading && (
          <LinearProgress variant='indeterminate' sx={{ mt:'15px'}} />
      )}
      {!isLoading && (
      <>
      {allEvaluationFinalResponse && allEvaluationFinalResponse.length ? (
        <Box style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0' }}>
          <EASCard>
            <Stepper activeStep={activeStep} orientation="vertical" nonLinear={true} >
              {allEvaluationFinalResponse && (allEvaluationFinalResponse.map((objective, index) => (
                <Step key={objective.id_objective}  completed={true} >
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {objective.text_objective}
                  </StepButton>
                  <StepContent>
                    <List>
                    {objective.evaluation_final_items && (objective.evaluation_final_items.map((evaluationFinalItem, index) => (
                      <EASEvaluationFinalItem key={evaluationFinalItem.id_objective_detail} objectiveId={objective.id_objective} evaluationFinalItem={evaluationFinalItem} />
                    )))}
                    </List>
                    {(team.type_tean=='1' && team.status_mentory_inscricao !='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao !='1' ) && (
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === allEvaluationFinalResponse.length - 1 ? 'Finalizar Programa' : 'Próxima Competência'}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Voltar a Competência Anterior
                        </Button>
                      </div>
                    </Box>
                    )}
                  </StepContent>
                </Step>
              )))}
            </Stepper>
            {(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' ) && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography variant="h5">Este programa foi encerrado</Typography>
              {((team.type_tean === '0')  && (
                <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
              ))}
            </Paper>
            )}
          </EASCard>
        </Box> 
        ) : (
          <Alert severity="warning">Não há nenhuma Competência e/ou Detalhamento cadastrado.</Alert>
        )}
      </>
      )} 
      <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            {'Finalizar Programa'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Você tem certeza que deseja finalizar o programa?
              Uma vez finalizado, nâo será possível alterar os valores das notas cadastradas. Caso você precise reabrir basta solicitar ao seu mentor.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{mr:2, mb:1}}>
            <Button onClick={handleCancel} variant="contained">Desistir</Button>
            <Button onClick={handleConfirme} variant="contained" autoFocus>
              Sim, Finalizar
            </Button>
          </DialogActions>
        </Dialog>    
    </Container>
  );
};