import { useState, useRef, useEffect } from 'react';
import * as yup from 'yup';

import { Box, TextField, Fab, CircularProgress, Container} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { green } from '@mui/material/colors';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { EASCard } from '../eas-card/EASCard';
import { useTaskPlanContext, useTeamContext, useCourseContext } from '../../contexts';
import { ITaskPlan } from '../../contexts/TaskPlanContext/types';
import { TaskPlanService } from '../../services/api/task-plan/TaskPlanService';
import { VariantType, useSnackbar } from 'notistack';

interface ITaskPlanProps {
  addTaskPlan:(TaskPlan : ITaskPlan) => void;
}
export const EASTaskPlanNew: React.FC<ITaskPlanProps> = ({ addTaskPlan }) => {

  const { taskPlan, isLoaded} = useTaskPlanContext();
 
  const [textTaskPlan, setTextTaskPlan] = useState<string>('');
  const [dateTaskPlan, setDateTaskPlan] = useState<string>('');
  
  const [textTaskPlanError, setTextTaskPlanError] = useState<string>('');
  const [dateTaskPlanError, setDateTaskPlanError] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const { ...team } = useTeamContext();
  const { ...enrollment } = useCourseContext();
  
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  const taskPlanSchema = yup.object().shape({
    textTaskPlan: yup.string().required('Descreva a tarefa'),
    dateTaskPlan: yup.string().required('Informe uma data para a execução da tarefa')
  });


  const handleButtonClick = () => {
    if (!loading) {
    taskPlanSchema
      .validate({ textTaskPlan, dateTaskPlan }, { abortEarly: false })
      .then(validateValues => {
        setSuccess(false);
        setLoading(true);
        const taskPlan: ITaskPlan = {
          id_task_plan: '',
          id_inscricao: (team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao),
          id_equipe: team.id_equipe,
          id_mentoria_tean: team.id_mentoria_tean,
          status_task_plan: '0',
          text_task_plan: validateValues.textTaskPlan,
          date_task_plan: new Date(validateValues.dateTaskPlan).toLocaleDateString('pt-BR'),
          schedule_task_plan: '',
          unread_task_plan: (team.type_tean=='1' ? '1' : '0'),
          register_task_plan: enrollment.id_inscricao,
          presentation_task_plan: '0',
        };                 // const date = new Date(newValue);
        TaskPlanService.createTaskPlan(taskPlan)
          .then((taskPlanResponse) => {
            if (taskPlanResponse instanceof Error) {
              setLoading(false);
              setSuccess(false);
              enqueueSnackbar('Erro ao cadastrar tarefa.', { variant:'error'});
            } else {
              setLoading(false);
              setSuccess(true);
              enqueueSnackbar('Tarefa cadastrada com sucesso!', { variant:'success'});
              addTaskPlan(taskPlan);
                setTextTaskPlan(''); 
                setDateTaskPlan('');
                setDateTaskPlanError('');
                setTextTaskPlanError('');
                setSuccess(false);
                

            }
          }).catch(() => {
            //alert(taskPlan);
            setLoading(false);
            setSuccess(false);
            enqueueSnackbar('Erro ao cadastrar tarefa.', { variant:'error'});
          });
      })
      .catch((errors: yup.ValidationError) => {
        setLoading(false);
        errors.inner.forEach(error => {
          if (error.path === 'textTaskPlan') {
            setTextTaskPlanError(error.message);
          } else if (error.path === 'dateTaskPlan') {
            setDateTaskPlanError(error.message);
          }
        });
      });
    }
  };



  return (
      <EASCard>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <TextField
            label="Tarefa"
            variant="outlined"
            value={textTaskPlan}
            onChange={(event) => setTextTaskPlan(event.target.value)}
            onFocus={() => setTextTaskPlanError('')}
            fullWidth
            error={!!textTaskPlanError}
            helperText={textTaskPlanError}
          />
          <LocalizationProvider  adapterLocale={brLocale} dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Executar em"
              value={ dateTaskPlan === '' ? null : dateTaskPlan} 
              onChange={(newValue) => {
                if(newValue){
                  setDateTaskPlan(newValue);
                }
              }}
              renderInput={(params) => <TextField onFocus={() => setDateTaskPlanError('')}
                                                  value={dateTaskPlan} 
                                                  helperText={dateTaskPlanError}
                                                  {...params}
                                                  error={!!dateTaskPlanError} />}
            />

          </LocalizationProvider>          
          <Box sx={{ m: 1, position: 'relative' }}>
            <Fab
              size="small"
              aria-label="save"
              color="primary"
              sx={buttonSx}
              onClick={handleButtonClick}

            >
              {success ? <CheckIcon /> : <AddIcon />}
            </Fab>
            {loading && (
              <CircularProgress
                size={48}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: -4,
                  left: -4,
                  zIndex: 1,
                }}
              />
            )}
          </Box>
        </Box>
      </EASCard>
  );
};
