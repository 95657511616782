import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IFile, ITagsFiles } from '../../../contexts/FilesContext/types';

interface IFilesListResponse extends IFile {
  page?: number | null;
  per_page?: number | null;
  total?: number | null;
  total_pages?: number | null;
  data?: Array<IFile> | null;
  tags?: Array<ITagsFiles>
}



const getAllFiles = async (id_inscricao = '', id_evento = '', id_equipe = '', id_mentoria_tean = '', search = '', tags = '', page = 1 ): Promise<IFilesListResponse | Error> => {
  try {
   
    if(!id_inscricao || !id_equipe || !id_mentoria_tean)
      return new Error('Requisição imcompleta.');

    const urlRelativa = `/files?public_token=${Environment.PUBLIC_TOKEN}&i=${id_inscricao}&c=${id_evento}&e=${id_equipe}&t=${id_mentoria_tean}&f=${search}&g=${tags}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}`;

    const  {data} = await Api.get(urlRelativa);
  
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


export const FilesService = {
  getAllFiles,
};
