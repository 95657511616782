import { useState, useEffect } from 'react';
import { CircularProgress, Box, Grid, Typography, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button }from '@mui/material';

import { useSnackbar } from 'notistack';

import * as yup from 'yup';

import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

interface IInfluencersEvaluationsEditProps {
  influencer: IInfluencer | null;
  finishEditInfluencer:(Influencer : IInfluencer) => void;
  cancelEditInfluencer:() => void;
}

export const EASInfluencersEvaluationsEdit: React.FC<IInfluencersEvaluationsEditProps> = ({ influencer, finishEditInfluencer, cancelEditInfluencer }) => {
  
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [editInfluencer, setEditInfluencer] = useState<IInfluencer | null>(null);
  const [textPositiveEditInfluencer, setTextPositiveEditInfluencer] = useState<string>('');
  const [textPositiveEditInfluencerError, setTextPositiveEditInfluencerError] = useState<string>('');
  const [textNegativeEditInfluencer, setTextNegativeEditInfluencer] = useState<string>('');
  const [textNegativeEditInfluencerError, setTextNegativeEditInfluencerError] = useState<string>('');

  const EditInfluencerSchema = yup.object().shape({
    textPositiveEditInfluencer: yup.string().required('Descreve rótulos Positivos.'),
    textNegativeEditInfluencer: yup.string().required('Descreva Rótulos Negativos.'),
  });

  useEffect(() => {
    function loadEditInfluencer() {
      setEditInfluencer(null);
      setTextPositiveEditInfluencer('');
      setTextNegativeEditInfluencer('');
      setTextPositiveEditInfluencerError('');
      setTextNegativeEditInfluencerError('');

      if(influencer){
        setEditInfluencer(influencer);
        if(influencer.positive_influencer)
          setTextPositiveEditInfluencer(influencer.positive_influencer);
        if(influencer.negative_influencer)
          setTextNegativeEditInfluencer(influencer.negative_influencer);
        setOpenDialog(true);
      }
    }
    loadEditInfluencer();
  }, [influencer]);



  const dialogSave = () => {
    if (!loading) {
      EditInfluencerSchema
      .validate({ textPositiveEditInfluencer, textNegativeEditInfluencer }, { abortEarly: false })
      .then(validateEditValues => {
        if(editInfluencer) {
          setLoading(true);
          const influencer: IInfluencer = {
            id_influencer: editInfluencer.id_influencer,
            id_inscricao: editInfluencer.id_inscricao,
            id_equipe: editInfluencer.id_equipe,
            id_mentoria_tean: editInfluencer.id_mentoria_tean,
            name_influencer: editInfluencer.name_influencer,
            order_influencer: editInfluencer.order_influencer,
            positive_influencer: validateEditValues.textPositiveEditInfluencer,
            negative_influencer: validateEditValues.textNegativeEditInfluencer,
            group_influencer: editInfluencer.group_influencer,
            distance_influencer: editInfluencer.distance_influencer
          };   
          InfluencersService.updateInfluencers(influencer)
          .then((influencerResponse) => {
            if (influencerResponse instanceof Error) {
              setLoading(false);
              enqueueSnackbar('Erro ao adicionar rótulos.', { variant:'error'});
            } else {
              setLoading(false);
              enqueueSnackbar('Rótulos adicionados com sucesso!', { variant:'success'});
              setOpenDialog(false);
              finishEditInfluencer(influencer);
            }
          }).catch(() => {
            setLoading(false);
            enqueueSnackbar('Erro ao atualizar Influenciador.', { variant:'error'});
          });
        }
        else{
          setLoading(false);
          enqueueSnackbar('Erro ao atualizar Influenciador.', { variant:'error'});
        }
      })
      .catch((errors: yup.ValidationError) => {
        setLoading(false);
        errors.inner.forEach(error => {
          if (error.path === 'textPositiveEditInfluencer') {
            setTextPositiveEditInfluencerError(error.message);
          } 
          if (error.path === 'textNegativeEditInfluencer') {
            setTextNegativeEditInfluencerError(error.message);
          } 
        });
      });
    }
  };


  function cancelEdit()
  {
    cancelEditInfluencer();
    setOpenDialog(false);
  }

  return (
    <Dialog 
      open={openDialog}
      keepMounted
      onClose={() => cancelEdit()}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>{'Adicionar Rótulos'}</DialogTitle>
        <DialogContent>
          {loading && (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress variant='indeterminate' />
          </Box>
          )}
          {!loading && (
          <Box>
            <Box style={{ display: 'flex', justifyContent: 'center', padding:'0 0 0 8px' }}>

            <Typography component='div'>Preencha os campos abaixo, elencando os possíveis rótulos positivos e negativos que o influenciador <Box fontWeight='fontWeightMedium' display='inline'>{influencer?.name_influencer}</Box> tem a seu respeito, na sua percepção</Typography>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center', width:'100%', padding:'8px 0 0 8px' }}>
              <Grid container spacing={2} sx={{marginTop:'-10px', width:'100%'}}>
                <Grid item xs={12} sm={6}  md={6}  lg={6} xl={6} >
                  <TextField
                    label="Rótulos Positivos"
                    variant="outlined"
                    value={textPositiveEditInfluencer}
                    onChange={(event) => setTextPositiveEditInfluencer(event.target.value)}
                    onFocus={() => setTextPositiveEditInfluencerError('')}
                    multiline
                    fullWidth
                    rows={6}
                    error={!!textPositiveEditInfluencerError}
                    helperText={textPositiveEditInfluencerError}
                  />
                </Grid>
                <Grid item xs={12} sm={6}  md={6}  lg={6} xl={6} >
                  <TextField
                    label="Rótulos Negativos"
                    variant="outlined"
                    value={textNegativeEditInfluencer}
                    onChange={(event) => setTextNegativeEditInfluencer(event.target.value)}
                    onFocus={() => setTextNegativeEditInfluencerError('')}
                    multiline
                    fullWidth
                    rows={6}
                    error={!!textNegativeEditInfluencerError}
                    helperText={textNegativeEditInfluencerError}
                  />
              </Grid>
            </Grid>
          </Box>
        </Box>
        )}
      </DialogContent>
      <DialogActions sx={{mr:2, mb:1}}>
        <Button onClick={() => cancelEdit()} variant="contained">Cancelar</Button>
        <Button onClick={dialogSave} variant="contained" autoFocus >Salvar</Button>
      </DialogActions>
    </Dialog>
  );
};