import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IEvaluationPartial, IEvaluationPartialRelatory, IEvaluationPartialRelatoryObjectivesDetail } from '../../../contexts/EvaluationPartialContext/types';

interface IEvaluationPartialService {
  Teams?: string;
}

interface IEvaluationPartialListResponse {
  objectives_details: number | null;
  text_objectives_details: Array<IEvaluationPartialRelatoryObjectivesDetail> | null;
  page?: number | null;
  per_page?: number | null;
  total?: number | null;
  total_pages?: number | null;
  data?: IEvaluationPartial | IEvaluationPartialRelatory | null;
}


const createEvaluationPartial = async (evaluationPartial: object): Promise<IEvaluationPartialService | Error> => {
  try {
    const { data } = await Api.post('/evaluationpartial', { evaluationPartial: evaluationPartial } );
  
    if (data) 
      return data;
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateEvaluationPartial = async (evaluationPartial: IEvaluationPartial): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/evaluationpartial/${evaluationPartial.id_evaluation_partial}`, evaluationPartial);
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const deleteEvaluationPartial = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api.delete(`/evaluationpartial/${id}`);
    if (data) 
      return data;   
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getEvaluationPartial = async (userEnrollment: string, client_id: string): Promise<IEvaluationPartialService | Error> => {
  try {
    const { data } = await Api.post('/evaluationpartial', { userEnrollment: userEnrollment, client_id: client_id } );
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const getAllEvaluationPartial = async (id_inscricao = '', id_equipe = '', id_mentoria_tean = '', filterEvaluationPartial = 0, page = 1, filter = '' ): Promise<IEvaluationPartialListResponse | Error> => {
  try {
    if(!id_inscricao || !id_equipe || !id_mentoria_tean)
      return new Error('Requisição imcompleta.');

    
    const urlRelativa = `/evaluationpartial?public_token=${Environment.PUBLIC_TOKEN}&i=${id_inscricao}&e=${id_equipe}&t=${id_mentoria_tean}&f=${filterEvaluationPartial}&page=${page}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${filter}`;

    const  {data} = await Api.get(urlRelativa);
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {    
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

export const EvaluationPartialService = {
  getAllEvaluationPartial,
  getEvaluationPartial,
  createEvaluationPartial,
  updateEvaluationPartial,
  deleteEvaluationPartial,
};
