import React, { useState , useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Paper, Avatar, TableRow, TableBody, TableCell, Container, Typography, IconButton, TableContainer, TablePagination, Grid, LinearProgress, Box, ListItem, ListItemAvatar, ListItemText, TableHead, Chip, Rating } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { sample } from 'lodash';

import { IEvaluationPartialRelatory, IEvaluationPartialRelatoryObjectivesDetail } from '../../contexts/EvaluationPartialContext/types';
import { useCourseContext , useTeamContext, useAuthContext } from '../../contexts';
import { EvaluationPartialService } from '../../services/api/evaluation-partial/EvaluationPartialService';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { EASCard } from '../eas-card/EASCard';

interface iusertesteapagar {
  id:string;
  label:string;
  alignRight:boolean;
}

export const EASEvaluationPartialRelatory: React.FC = () => {

 const TABLE_HEAD:Array<iusertesteapagar> = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'company', label: 'Company', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

const { ...enrollment } = useCourseContext();
const { ...team } = useTeamContext();
const { ...user } = useAuthContext();
const navigate = useNavigate();

const [isLoading, setIsLoading] = useState(true);
const [allEvaluationPartial, setAllEvaluationPartial] = useState<Array<IEvaluationPartialRelatory> | null>(null);
const [allObjectivesDetail, setAllObjectivesDetail] = useState<Array<IEvaluationPartialRelatoryObjectivesDetail> | null>(null);
const [totalCount, setTotalCount] = useState(0);

useEffect(() => {
  async function getEvaluationsPartial() {
    if(enrollment.id_inscricao){
      setIsLoading(true);
      
      const evaluationPartial = await EvaluationPartialService.getAllEvaluationPartial((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), team.id_equipe, team.id_mentoria_tean, 0,0,'compare');
      if (evaluationPartial instanceof Error) {
        setAllEvaluationPartial(null);
        setIsLoading(false);
        setTotalCount(0);
      } else {
        if(evaluationPartial.data && evaluationPartial.text_objectives_details && evaluationPartial.total){
          const myEvaluationPartial = JSON.parse(JSON.stringify(evaluationPartial.data));
          setAllEvaluationPartial(myEvaluationPartial);

          
          if(evaluationPartial.text_objectives_details)
            setAllObjectivesDetail(evaluationPartial.text_objectives_details);

          if(evaluationPartial.total)
            setTotalCount(evaluationPartial.total);

          setIsLoading(false);
        }
        else{
          setAllEvaluationPartial(null);
          setIsLoading(false);
          setTotalCount(0);
        }
      }
    }
  }
  getEvaluationsPartial();
}, [team.id_equipe]);



//const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
//const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const USERLIST = [...Array(24)].map((_, index) => ({
    id: index + 1,
    avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
    name: index + 1 + ' - name',
    company: index + 1 + ' - company',
    isVerified: true,
    status: sample(['active', 'banned']),
    role: sample([
      'Leader',
      'Hr Manager',
      'UI Designer',
      'UX Designer',
      'UI/UX Designer',
      'Project Manager',
      'Backend Developer',
      'Full Stack Designer',
      'Front End Developer',
      'Full Stack Developer',
    ]),
  }));

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = USERLIST; //applySortFilter(, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  
  return (
    <Container>
      {isLoading && (
        <LinearProgress variant='indeterminate' sx={{ mt:'15px'}} />
      )}
      {!isLoading && (
      <>
        <Box style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 10px 0' }}>
          <EASCard>
            <Grid container style={{ display: 'flex', justifyContent: 'center'}} spacing={2}>
              <Grid item xs={11} >  
                <ListItem>
                  <ListItemAvatar sx={{marginRight:'5px'}}>
                  <Avatar sx={{ border:'1px solid #EA5B0C', height: '50px', width: '50px' }} 
                            alt={(team.type_tean=='1' ? team.name : user.name)} 
                            src={(team.type_tean=='1' ? team.avatar : user.avatar) && 'https://online.sbdc.com.br/media/images/avatar/' + (team.type_tean=='1' ? team.avatar : user.avatar)} />
                  </ListItemAvatar>
                  <ListItemText 
                    primary={<Typography variant="h6">{(team.type_tean=='1' ? team.name : user.name)}</Typography>} 
                    secondary={<Typography  sx={{fontSize:'12px'}}>MENTORADO</Typography>}
                    />
                </ListItem> 
              </Grid> 
              <Grid item xs={1}> 
                <Box style={{ display: 'flex', justifyContent: 'right', alignItems: 'top', marginTop:'-10px', marginRight:'-5px'}}> 
                  <IconButton aria-label="Fechar" onClick={() => navigate('/avaliacao-parcial')}>
                    <CancelIcon />
                  </IconButton>
                  </Box>  
              </Grid>
            </Grid>
          </EASCard>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0' }}>
          <EASCard>
            <TableContainer >
              <Table>
                <TableHead>
                  <TableRow >
                    <TableCell component="th" scope="row" sx={{maxWidth:'10%'}}>
                      <Typography variant="subtitle2" noWrap>
                          Competências
                      </Typography>
                    </TableCell>
                    {(allEvaluationPartial && allEvaluationPartial.length ) ? (allEvaluationPartial.map((evaluationPartial, index) => (
                    <TableCell key={index} component="th" scope="row" align="center">
                      <Typography variant="subtitle2" noWrap>
                          {evaluationPartial?.type_evaluation_partial == '0' ? 'AUT' : 'EXT'}
                      </Typography>
                    </TableCell>
                    ))):(
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle2" noWrap>
                          Nenhuma Avaliação Parcial
                      </Typography>
                    </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>

                  {(allObjectivesDetail && allObjectivesDetail.length) ? (allObjectivesDetail.map((objectivesDetail, index) => (
                    <TableRow hover key={index} tabIndex={-1} role="checkbox" >
                      <TableCell component="th" scope="row" >
                        <ListItemText 
                          primary={<Typography variant="body2" >{objectivesDetail?.text_objective_detail}</Typography>}  
                          secondary={
                          <Box>
                          <Box>
                          <Typography variant="caption">{objectivesDetail?.text_objective}</Typography>

                          </Box>
                          <Box>
                          <Typography variant="caption">{'Nota Inicial: ' +objectivesDetail?.value_objective_detail}</Typography>
                          </Box></Box>} 
    
                          />
                      </TableCell>
                      {(allEvaluationPartial && allEvaluationPartial.length ) && (allEvaluationPartial.map((evaluationPartial, index1) => {
                        let x = true;
                        return (evaluationPartial.evaluation_partial_detail && evaluationPartial.evaluation_partial_detail.length ) && (evaluationPartial.evaluation_partial_detail.map((evaluationPartialDetal, index2) => {
              
                            if( evaluationPartialDetal.id_objective_detail == objectivesDetail.id_objective_detail){
                              x = false;
                              return (<TableCell key={index} component="th" scope="row"  align="center">
                                        {evaluationPartialDetal.id_objective_detail == objectivesDetail.id_objective_detail && ( evaluationPartialDetal.value_evaluation_partial_detail ? (
                                         <Typography variant="caption" sx={{textAlign:'center'}} align="center">
                                          {evaluationPartialDetal.id_objective_detail}
                                          <Rating  precision={0.2} value={(parseInt(evaluationPartialDetal.value_evaluation_partial_detail)/5)} max={1} readOnly />
                                           <Box>{evaluationPartialDetal?.value_evaluation_partial_detail}</Box>
                                            </Typography>) : <RemoveCircleOutlineIcon fontSize="small" sx={{color:'#d1d1d1'}}/>)}
                                      </TableCell>
                                      );
                            }
                            else 
                              if(x && evaluationPartial.evaluation_partial_detail && index2 == evaluationPartial.evaluation_partial_detail.length -1)
                              return (<TableCell key={index} component="th" scope="row" padding="none">
                                        <Typography variant="subtitle2" noWrap>
                                          ?
                                        </Typography>
                                      </TableCell>);
                        }));}))}
                        
                    </TableRow>
                  ))):(              
                  <TableRow >
                    <TableCell colSpan={totalCount} >
                      Nenuma item a exibir
                    </TableCell>
                  </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </EASCard>
        </Box>
      </>
      )}
      </Container>
  );
};




