import { LayoutBaseDePagina } from '../../shared/layouts';
import { EASTools } from '../../shared/components';

export const Tools = () => {

  return (
    <LayoutBaseDePagina
      titulo='Ferramentas Auxiliares'
      iconTitle='connect_without_contact'
    >
      <EASTools />
    </LayoutBaseDePagina>
  );
};
