import {useRef, useEffect, useState} from 'react';
import ReactToPrint from 'react-to-print';
import { useNavigate } from 'react-router-dom';
import { EASPresentationFinal } from '../../shared/components';
import { Button, CircularProgress } from '@mui/material';


export const ResultsPresentationPrint = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  useEffect(() => {

    setTimeout(function() {
      const element: HTMLElement = document.getElementById('print-button') as HTMLElement;
      element.click();
    }, 2000);

  }, []);


  return (
    <>
    <ReactToPrint
      trigger={() => <Button style={{display:'none'}} id="print-button" >Generar</Button>}
      content={() => componentRef.current}
      onAfterPrint={()  => navigate('/apresentacoes')}  
      
      onBeforePrint={()  => setLoading(false)}  
    />
    <div ref={componentRef}>
      <EASPresentationFinal toPrint={true}/>
    </div>
    {loading && (
      <CircularProgress sx={{position:'absolute', left:'50%', top:'10%'}} />
    )}
  </>
  );
};
