import { Api } from '../axios-config';

interface ICourses {
  Courses?: string;
}

const getCourses = async (userID: string, client_id: string): Promise<ICourses | Error> => {
  try {
    const { data } = await Api.post('/courses', { userID: userID, client_id: client_id } );
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao localizar programa.');
  }
};

export const CourseService = {
  getCourses,
};
