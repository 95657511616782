import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IVideo, ITagsVideos } from '../../../contexts/VideosContext/types';

interface IVideosListResponse extends IVideo {
  page?: number | null;
  per_page?: number | null;
  total?: number | null;
  total_pages?: number | null;
  data?: Array<IVideo> | null;
  tags?: Array<ITagsVideos>
}

const getVideo = async (id:string): Promise<IVideo | Error> => {

    try {
      const { data } = await Api.get(`/videos/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
  
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const getAllVideos = async (id_inscricao = '', id_evento = '', id_equipe = '', id_mentoria_tean = '', video='', search = '', tags = '', page = 1): Promise<IVideosListResponse | Error> => {
  try {
   
    if(!id_inscricao || !id_equipe || !id_mentoria_tean)
      return new Error('Requisição imcompleta.');

    const urlRelativa = `/videos?public_token=${Environment.PUBLIC_TOKEN}&i=${id_inscricao}&c=${id_evento}&e=${id_equipe}&t=${id_mentoria_tean}&v=${video}&f=${search}&g=${tags}&page=${page}&limit=${(video != '' ? '3' : Environment.LIMITE_DE_LINHAS)}`;

    const  {data} = await Api.get(urlRelativa);
  
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


export const VideosService = {
  getVideo,
  getAllVideos,
};
