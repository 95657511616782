import React, { useState , useEffect, useMemo } from 'react';
import { useParams, useNavigate, useSearchParams} from 'react-router-dom';
import ReactPlayer from 'react-player';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled, Typography, Box, Stack, LinearProgress, Grid, Card, CardContent } from '@mui/material';
import { EASVideosColum } from './EASVideosColum';
import { IVideo } from '../../contexts/VideosContext/types';

import { useCourseContext , useTeamContext} from '../../contexts';
import { Environment } from '../../environment';
import { VideosService } from '../../services/api/videos/VideosService';



interface IVideoDetailProps {
  children?: () => void;
}  

export const EASVideoDetail: React.FC<IVideoDetailProps> = () => {

  //const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id = ''} = useParams<'id'>();
  const [videoDetail, setVideoDetail] = useState<IVideo | null>(null);
  const [videos, setVideos] = useState<Array<IVideo> | null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [updateList, setUpdateList] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();
  
  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  

  useEffect(() => {
    async function getVideo() {
      setIsLoading(true);
      const video = await VideosService.getVideo(id);
      if (video instanceof Error) {
        setVideos(null);
        setTotalCount(0);
        setIsLoading(false);
        setUpdateList(false);
      } else {
        setVideoDetail(video);
      }
      getVideos();
    }
    async function getVideos() {
      if(enrollment.id_inscricao){
        const videos = await VideosService.getAllVideos((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), enrollment.id_evento, team.id_equipe, team.id_mentoria_tean, id, '', '', pagina);
        if (videos instanceof Error) {
          setVideos(null);
          setTotalCount(0);
          setIsLoading(false);
          setUpdateList(false);
        } else {
          if(videos.data && videos.total){
            const myVideos = JSON.parse(JSON.stringify(videos.data));
            setVideos(myVideos);
            setIsLoading(false);
            if(videos.total)
              setTotalCount(videos.total);
            setUpdateList(false);
          }
          else{
            setVideos(null);
            setTotalCount(0);
            setIsLoading(false);
            setUpdateList(false);
          }
        }
      }
    }
    getVideo();
  }, [searchParams, updateList, team.id_equipe]);  

 
  const ReactPlayerStyled = styled(ReactPlayer)(() => ({
      width: '100% !important', 
      height: 'auto !important', 
      aspectRatio: '16/9'
  }));
    
  const executeNavigatte  = (urlNavigator:string) => {
      navigate(urlNavigator);
      setUpdateList(true);
    
  }; 


  return (
    <Box width="100%">
    {isLoading && (
      <LinearProgress variant='indeterminate' />
    )}
    {!isLoading && videoDetail && (
    <Grid container spacing={1} sx={{ mb: 3}}> 
      <Grid item md={12} lg={8} xl={9} sx={{width:'100%'}} minHeight="100vh">
        <Box py={{ md: 1, xs: 5 }} flex={1}>
          <Card sx={{ width: '100%', position: 'sticky'}}>
            <ReactPlayerStyled url={`https://www.youtube.com/watch?v=${videoDetail?.code}`} className="react-player" controls  />
            <CardContent>
              <Typography variant="h5" fontWeight="bold" p={2}>
                {videoDetail?.title}
              </Typography>
              <Stack direction="row" justifyContent="space-between" py={1} px={2}>
                <Typography variant="h6">
                    {videoDetail?.channelTitle}
                    <CheckCircleIcon sx={{ fontSize: '12px', color: 'gray', ml: '5px' }} />
                </Typography>
              </Stack>
              <Stack justifyContent="space-between" py={1} px={2}>
                <Typography variant="body1">
                  {videoDetail?.description} 
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item md={12} lg={4} xl={3} sx={{width:'100%'}}>
        <Box pl={2} py={{ md: 1, xs: 5 }} justifyContent="center" alignItems="center" >
          <EASVideosColum videos={videos} direction="column" clickToNavigate={(e) => executeNavigatte(e)}/>
        </Box>
      </Grid>
    </Grid>
    )}
    </Box>
  );
};