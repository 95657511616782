
import React, { useState, useEffect } from 'react';
import {Box, Alert, Typography, Container, Button, LinearProgress, Dialog, DialogTitle, DialogContent, DialogActions, Grid, List } from '@mui/material';

import { useTeamContext, useCourseContext } from '../../contexts';
import { EASToolsPerceptionOfInfluencersItem } from './EASToolsPerceptionOfInfluencersItem';
import { EASToolsPerceptionOfInfluencersChart } from './EASToolsPerceptionOfInfluencersChart';

import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

import { useNavigate } from 'react-router-dom';
import { EASCard } from '../eas-card/EASCard';

interface IColumnItem {
  id?: string;
  list?: Array<IInfluencer>;
}  

interface IColumnGroup extends IColumnItem {
  Influenciadores: IColumnItem;
  Promotores: IColumnItem;
  Neutros: IColumnItem;
  Detratores: IColumnItem;
}  

interface EASToolsPerceptionOfInfluencers {
  allInfluencers: Array<IInfluencer> | null;
  setAllInfluencers:(allInfluencers : Array<IInfluencer>) => void;
  updateList:() => void;
}  

export const EASToolsPerceptionOfInfluencers: React.FC = () => {
  
  const [messageInfluencers, setMessageInfluencers] = useState<boolean>(false);
  const [allInfluencers, setAllInfluencers] = useState<Array<IInfluencer> | null>(null);

  const [columns, setColumns] = useState<IColumnGroup>();
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();

  const navigate = useNavigate();

  useEffect(() => {
    async function getInfluencers() {
      if(enrollment.id_inscricao){
        setIsLoading(true);
        const influencers = await InfluencersService.getAllInfluencers((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), team.id_equipe, team.id_mentoria_tean);
        if (influencers instanceof Error) {
          setAllInfluencers(null);
          setTotalCount(0);
          setIsLoading(false);
        } else {
          if(influencers.data && influencers.total){
            const myInfluencers:Array<IInfluencer> = JSON.parse(JSON.stringify(influencers.data));
            setAllInfluencers(myInfluencers);

            const newColuns : IColumnGroup = {Influenciadores: {id: 'Influenciadores',list: [],}, Promotores: {id: 'Promotores',list: [],},Neutros: {id: 'Neutros',list: [],},Detratores: {id:'Detratores',list: [],}};
            if(myInfluencers){
              myInfluencers.map(influencer => {
                if(influencer.name_influencer)
                  switch (influencer.group_influencer) {
                    case '1':
                      newColuns.Promotores.list?.push(influencer);
                      break;
                    case '2':
                      newColuns.Neutros.list?.push(influencer);
                      break;
                    case '3':
                      newColuns.Detratores.list?.push(influencer);
                      break;
                    default:
                      newColuns.Influenciadores.list?.push(influencer);
                      break;
                  } 
              }); 
            }
            setColumns(newColuns);
            setLoading(false);
            setIsLoading(false);
            setTotalCount(influencers.total);

          }
          else{
            setMessageInfluencers(true);
            setAllInfluencers(null);
            setTotalCount(0);
            setIsLoading(false);
          }
        }
      }
    }
    getInfluencers();
  }, [team.id_equipe]);



  const updateInfluencer = (influencerUpdate?:IInfluencer) => {
    if (influencerUpdate && allInfluencers) {
      const newAllInfluencers = allInfluencers.map(influencer => {
        if (influencerUpdate.id_influencer !== influencer.id_influencer) {
          return influencer;
        } else {
          return influencerUpdate;
        }
      });
      setAllInfluencers(newAllInfluencers);
    }
  };

  return (    
  <Container>
    {isLoading && (
      <LinearProgress variant='indeterminate' sx={{ mt:'15px'}} />
    )}
    {!isLoading && (
    <Box sx={{pb:10}}>
      <EASCard>
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" sx={{textAlign: 'center', marginBottom:'5px'}}>
          Considerando o conceito de NPS, classifique de 0 a 10 a percepção que os influenciadores tem a seu respeito.
        </Typography>

        <Typography variant="body2" sx={{textAlign: 'center', marginBottom:'0', width:'100%', display:'block'}}>
          Use a escala de 0 a 10 para indicar se esse influenciador é promotor, detrator ou neutro em relação a você.
        </Typography>
        <Typography variant="caption" sx={{textAlign: 'center', marginBottom:'0', width:'100%', display:'block'}}>
        • De 9 a 10 – Promotores: pessoas altamente propensas a lhe recomendar.
        </Typography>
        <Typography variant="caption" style={{textAlign: 'center', marginBottom:'0', width:'100%', display:'block'}}>
        • De 7 a 8 – Neutros: pessoas satisfeitas, mas menos entusiasmadas e menos propensas a lhe recomendar.
        </Typography>
        <Typography variant="caption" sx={{textAlign: 'center', marginBottom:'0', width:'100%', display:'block'}}>
        • De 0 a 6 – Detratores: pessoas insatisfeitas, que podem expressar opiniões negativas e afetar sua reputação na organização.
        </Typography>




      </Box>
      {(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' ) && (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5">Este programa foi encerrado</Typography>
        {((team.type_tean === '0')  && (
          <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
        ))}
      </Box>
      )}
      <Grid container alignItems="center" spacing={2} sx={{marginTop:'-10px', width:'100%'}}>
        <Grid item xs={12} sm={12}  md={12}  lg={6} xl={6} >
          <List>
            {!isLoading ?  (
              <>
                {allInfluencers && allInfluencers.length > 0 && (allInfluencers.map((influencer, index) => (
                  <EASToolsPerceptionOfInfluencersItem 
                    key={influencer.id_influencer} 
                    influencer={influencer} 
                    index={index}
                    updateInfluencer={(e) => updateInfluencer(e)}
                  />
                )))}
              </>
            ):(
            <Alert severity="warning">Nenhum influenciador cadastrado!</Alert>
            )}
          </List>
        </Grid>
        <Grid item xs={12} sm={12}  md={12}  lg={6} xl={6} sx={{height: '100%'}}>
          <EASToolsPerceptionOfInfluencersChart allInfluencers={allInfluencers} />
        </Grid>
      </Grid>
      <Box display="flex" flexDirection="column" alignItems="center" sx={{pt:'35px'}}>
        <Button variant="contained" onClick={() => navigate('/ferramentas-auxiliares')}>Concluir</Button>
      </Box>
      </EASCard>
    </Box>
    )}
    <Dialog
      maxWidth="xs"
      open={messageInfluencers}
    >
      <DialogTitle>Nenhum Influenciador Cadastrado</DialogTitle>
      <DialogContent>
        <Typography>
            Para que você possa utilizar esta ferramenta, é necessário primeiro cadastrar os influenciadores.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => navigate('/ferramentas-auxiliares/influenciadores-de-reputacao')}>Ir para o cadastro de influenciadores</Button>
      </DialogActions>
    </Dialog>
  </Container>
  );
};