import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IDashboard } from '../../../contexts/DashboardContext/types';


interface IDashboardServiceResponse {
  data?: IDashboard | null;
}

const getDashboardService = async (id_inscricao = '', id_equipe = '', id_mentoria_tean = '', type_tean = ''): Promise<IDashboardServiceResponse | Error> => {
  try {
    if(!id_inscricao || !id_equipe || !id_mentoria_tean || !type_tean)
      return new Error('Requisição imcompleta.');

    const urlRelativa = `/dashboard?public_token=${Environment.PUBLIC_TOKEN}&i=${id_inscricao}&e=${id_equipe}&t=${id_mentoria_tean}&f=${type_tean}`;

    const  {data} = await Api.get(urlRelativa);
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro no dashboard.');
  }
};

export const DashboardService = {
  getDashboardService,
};
