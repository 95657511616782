
import { BrowserRouter } from 'react-router-dom';
//import * as microsoftTeams from "@microsoft/teams-js";
//import { io } from 'socket.io-client';
//import './socket';
import { AppThemeProvider, ConnectProvider, DrawerProvider, AuthProvider, CourseProvider, TeamProvider} from './shared/contexts';
import { EASConnect, EASLogin, EASCourse, EASTeam, EASSidebar, EASEvaluationPartialExternal } from './shared/components';
import { AppRoutes } from './routes';
import { SnackbarProvider} from 'notistack';
export const App = () => {

  //const socket = io();

  return (
    <AppThemeProvider>
      <ConnectProvider>
        <EASConnect>
          <SnackbarProvider anchorOrigin={{vertical: 'top', horizontal: 'right',}}>
            <EASEvaluationPartialExternal>
              <AuthProvider>
                <EASLogin>
                  <CourseProvider>
                    <EASCourse>
                      <TeamProvider>
                        <EASTeam>
                          <DrawerProvider>
                            <BrowserRouter>
                              <EASSidebar>
                                <AppRoutes />
                              </EASSidebar>
                            </BrowserRouter>
                          </DrawerProvider>
                        </EASTeam>
                      </TeamProvider>
                    </EASCourse>
                  </CourseProvider>
                </EASLogin>
              </AuthProvider>
            </EASEvaluationPartialExternal>
          </SnackbarProvider>
        </EASConnect>
      </ConnectProvider>
    </AppThemeProvider>
  );
};
