import { useState } from 'react';
import { styled, Box, Button, Grid, Card, Avatar, Container, LinearProgress, CardActions, CardContent, CircularProgress, TextField, Typography, Theme, useMediaQuery, useTheme, CardMedia} from '@mui/material';
import { EASCard } from '../eas-card/EASCard';

import { useAuthContext, useAppThemeContext, useCourseContext  } from '../../contexts';
import { ICourse } from '../../contexts/CourseContext/types';

const ContentStyle = styled('div')(() => ({
  maxWidth: 640,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  textAlign: 'center', 
  alignItems: 'center',
}));

interface ICourseProps {
  children: React.ReactNode;
}

export const EASCourse: React.FC<ICourseProps> = ({ children }) => {

  const theme = useTheme();
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  //const [themeName, setThemeName] = useState<'light' | 'dark' | 'contrast' >('light');
  const { themeName } = useAppThemeContext();
  const { ...user } = useAuthContext();
  
  const { isSelected, allCourses, accessCourse } = useCourseContext();

  const [isLoading, setIsLoading] = useState(false);

  const handleClickCourse = (course ?: ICourse) => {
      if(course)
        accessCourse(course);
  };
  
  
  const handleLogout = () => {
    user.logout();
  };


  if (isSelected) return (
    <>{children}</>
  );

  if (isSelected === false) 
  return (
    <Box width='100vw'  display='flex' alignItems='center' justifyContent='center' sx={{overflowX:'hidden',}}>
      <Container>
        <ContentStyle>
          <EASCard>
            <Typography variant="h4" paragraph>
              Nenhum Programa Encontrado
            </Typography>

            <Box width="100%" sx={{ mt: theme.spacing(1)}} height={theme.spacing(12)} display="flex" alignItems="center" justifyContent="center">
              <Avatar
                sx={{ border:'2.2px solid #EA5B0C', height: theme.spacing(11), width: theme.spacing(11) }}
                src={user.avatar ? 'https://online.sbdc.com.br/media/images/avatar/' + user.avatar : ''}
              />
            </Box>
            <Box width="100%" display="flex" alignItems="center" justifyContent="center">
              <Typography sx={{mb:'0px'}}>
                Olá {user.name}
              </Typography>
            </Box>

            <Typography sx={{ color: 'text.secondary' }}>
              Infelizmente não conseguimos localizar nenhum programa de mentoria ativado para este App, que esteja vinculado a esta conta Microsoft ou ao e-mail informado caso tenha realizado o login manual.
              Se o seu programa de mentoria está vinculado a outra conta Microsoft, modifique o usuário ativo de seu Microsoft Teams em Gerenciar Contas, localizado no canto superior direito ou execute logoff para tentar realizar login manual.
            </Typography>
            <Box display='flex' flexDirection='column' alignItems='center' sx={{mt:'25px'}} >
              <Button
                variant='contained'
                disabled={isLoading}
                onClick={handleLogout}
                endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
              >
                Realizar Logoff
              </Button>
            </Box>
            <Box
              component="img"
              src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
              sx={{  mx: 'auto', my: 2  }}
            />
          </EASCard>
        </ContentStyle>
      </Container>
    </Box> 
  );

  if (isSelected === null && allCourses != null && allCourses.length > 1) 
    return (
      <Box width='100vw' display='flex' alignItems='center' justifyContent='center' sx={{overflowX:'hidden',}}>
        <Container>
          <ContentStyle>
            <EASCard>
              <Typography variant="h6" paragraph sx={{mb:'0px'}}>
                Escolha o Programa
              </Typography>
              <Box width="100%" height={theme.spacing(15)} display="flex" alignItems="center" justifyContent="center">
                <Avatar
                  sx={{ border:'2.4px solid #EA5B0C', height: theme.spacing(12), width: theme.spacing(12) }}
                  src={user.avatar ? 'https://online.sbdc.com.br/media/images/avatar/' + user.avatar : ''}
                />
              </Box>
              <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                <Typography sx={{mb:'0px'}}>
                  Olá {user.name}
                </Typography>
              </Box>
              <Typography sx={{ color: 'text.secondary',mb:'10px' }}>
                Encontramos os programas abaixo. Escolha um deles para continuar
              </Typography>
              
              <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                {allCourses.map((course, key) => {
                  return (
                    <Grid item xs={6} key={key} >
                      <Button sx={{ width:'100%', height:'100%', p:0, m:0 }} onClick={() => handleClickCourse(course)} variant='contained'>
                        <Card key={key} sx={{ display:'flex', width:'100%' }} >
                          <CardMedia
                            component="img"
                            sx={{ width: 70, p: course.image_evento ? '0' : '8px', background: course.color_evento ? course.color_evento : '#585858' }}
                            image={ course.image_evento ? 'https://online.sbdc.com.br/media/images/store/' + course.image_evento : '/logo-card.png'}
                            alt={course.name_evento}
                          />
                          <Box sx={{ display: 'flex', p:0}}>
                            <CardContent>
                              <Typography variant="subtitle2" sx={{lineHeight:'100%', mb:'10px', textAlign:'left'}}>
                                {course.name_evento}
                              </Typography>                            
                              <Typography variant="body2" sx={{lineHeight:'100%', textAlign:'left', fontSize:'12px'}}>
                                {course.name_turma}
                              </Typography>
                            </CardContent>
                          </Box>
                        </Card>
                      </Button>
                    </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </EASCard>
          </ContentStyle>
        </Container>
      </Box> 
    );


  return (
    <Box width='100vw'  display='flex' alignItems='center' justifyContent='center' sx={{overflowX:'hidden',}}>
      <Container>
        <ContentStyle>
          <EASCard>
            <Typography variant="h4" paragraph>
              Pesquisando Programas!
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>
              Aguarde uns instantes, estamos buscando o(s) programa(s) em que você está cadastrado.
              Este processo näo deve demorar mais que alguns segundos.
            </Typography>

            <Box
              component="img"
              src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
              sx={{  mx: 'auto', my: { xs: 5, sm: 5 } }}
            />
            <LinearProgress color="primary" />
          </EASCard>
        </ContentStyle>
      </Container>
    </Box> 
  );
};
