import React, { useState }from 'react';
import { Typography, Card, CardContent, CardMedia, Link } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAppThemeContext } from '../../contexts';
import { IVideo } from '../../contexts/VideosContext/types';

interface IVideoCardMediaProps {
  video?: IVideo;
  clickToNavigate:(urlNavigator : string) => void;
}  


export const EASVideoCard: React.FC<IVideoCardMediaProps> = ({video, clickToNavigate}) => {
  const [elevation, setElevation] = useState(1);
  return (

    <Link onClick={() => clickToNavigate(`/video/${video?.id}`)} 
          underline="none" 
          onMouseEnter={() => setElevation(3)}
          onMouseLeave={() => setElevation(1)}
          sx={{cursor:'pointer'}}>
      <Card sx={{ width: { xs: '100%', sm: '100%', md: '100%', }}} elevation={elevation}>
          <CardMedia component="img" sx={{ width: { xs: '100%', sm: '100%'}, height: 'auto' }} image={video?.thumbnails} alt={video?.title} />
        <CardContent sx={{ height: '106px' }}>
            <Typography variant="subtitle1" fontWeight="bold" color="text.primary" >
              {video?.title.slice(0, 60)}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {video?.channelTitle}
              <CheckCircleIcon sx={{ fontSize: '12px', color: 'gray', ml: '5px' }} />
            </Typography>
        </CardContent>
      </Card>
    </Link>
  
  );
};
