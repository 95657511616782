import React from 'react';
//import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import * as microsoftTeams from '@microsoft/teams-js';
import { App } from './App';

// Initialize the Microsoft Teams SDK
microsoftTeams.initialize();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);