import { LayoutBaseDePagina } from '../../shared/layouts';
import { EASEvaluationPartialRelatory } from '../../shared/components';

export const EvaluationPartialRelatory = () => {

  return (
    <LayoutBaseDePagina
      titulo='&nbsp;Avaliação Parcial'
      iconTitle='person_search'
    >
     <EASEvaluationPartialRelatory />
    </LayoutBaseDePagina>
  );
};
