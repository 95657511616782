import React from 'react';
import { styled , Tab , Tabs, Theme, useMediaQuery } from '@mui/material/';
import { IObjective } from '../../contexts/ObjectiveContext/types';
import { EASCard } from '../eas-card/EASCard';


const TabsObj = styled(Tabs)({
  width:'100%', 
  padding: '0px',
  margin: '0px',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

const TabObj = styled(Tab)({
  textTransform: 'none',
  textDecoration: 'none',
  width:'100%',
  minWidth:'100%',
  margin: '0',
  marginTop:'10px',
  fontSize: 16,
  borderRadius:'4px',
  backgroundColor: '#434140',
  color:'#FFFFFF',
  border: '0',
  cursor:'Pointer',
  '&:hover': {
    backgroundColor: '#e05104',
  },
  '&:active': {
    backgroundColor: '#EA5B0C',
  },
  '&:focus': {
    backgroundColor: '#434140',
  },
  '&.Mui-selected': {
    backgroundColor: '#EA5B0C',
    color:'#FFFFFF',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
});

interface IObjectivesSkillsProps {
  children?: () => void;
  valueTab: number;
  allObjectives?: Array<IObjective> | null;
  handleChangeTable: (newValue: number) => void;
}  

export const EASObjectivesSkills: React.FC<IObjectivesSkillsProps> = ({valueTab, allObjectives, handleChangeTable}) => {
 
  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const handleChange = (event: React.SyntheticEvent, valueTab: number) => {
    handleChangeTable(valueTab);
  };

  return (
    (!lgDown ? (
    <TabsObj
      orientation="vertical"
      variant="scrollable"
      value={valueTab}
      onChange={handleChange}
    >
      { (allObjectives && allObjectives.length ) && (allObjectives.map((objective) => (
      <TabObj 
        key={objective.id_objective} 
        label={objective.text_objective}
        value={objective.id_objective} 
      />
      )))}
    </TabsObj>
    ) : (
    <EASCard >
      <Tabs 
      sx={{mx:-2}}
        value={valueTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        { (allObjectives && allObjectives.length ) && (allObjectives.map((objective) => (
        <Tab 
          key={objective.id_objective} 
          label={objective.text_objective}
          value={objective.id_objective} 
        />
        )))}
      </Tabs>
    </EASCard>
    ))
  );
};