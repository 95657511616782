
import React, { useState , useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { Grid } from '@mui/material';

interface IColorDataChart {
  color?: string;
}
interface IDataChart extends IColorDataChart {
  name?: string;
  value?: Array<number | null>;
  symbolSize?: number;
  itemStyle?: IColorDataChart;
  label?: {show?: boolean; position?: string; color?: string; formatter?: string}
}
interface IDataChartPie extends IColorDataChart {
  name?: string;
  value?: number;
  //symbolSize?: number;
  itemStyle?: IColorDataChart;
 // label?: {show?: boolean; position?: string; color?: string; formatter?: string}
}
interface IIndicator {
  max: number;
}
interface IIndicatorRadar {
  name: string;
}

interface IEASInfluencersResultsChartsProps {
  allInfluencers: Array<IInfluencer> | null;
}  

export const EASInfluencersResultsCharts: React.FC<IEASInfluencersResultsChartsProps> = ({ allInfluencers }) => {

  const [dataChart, setDataChart] = useState<Array<IDataChart>>([]);
  const [dataChartPie, setDataChartPie] = useState<Array<IDataChartPie>>([]);
  const [dataChartRadar, setDataChartRadar] = useState<Array<IDataChart>>([]);
 
  const [indicator, setIndicator] = useState<Array<IIndicator>>([]);
  const [indicatorRadar, setIndicatorRadar] = useState<Array<IIndicatorRadar>>([]);

  const colorOrderInfluencer = ['', 
        '#37A2DA',  '#32C5E9',  '#67E0E3',  '#9FE6B8',  '#FFDB5C',  '#ff9f7f',
        '#fb7293',  '#E062AE',  '#E690D1',  '#e7bcf3',  '#9d96f5',  '#8378EA',
        '#96BFFF',  '#3366E6',  '#991AFF',  '#B34D4D',  '#4D8000',  '#c23531',
        '#2f4554',  '#61a0a8',  '#d48265',  '#91c7ae',  '#749f83',  '#ca8622',
        '#bda29a',  '#6e7074',  '#546570',  '#c4ccd3',  '#759aa0',  '#e69d87',
        '#8dc1a9',  '#ea7e53',  '#eedd78',  '#73a373',  '#73b9bc',  '#7289ab',
        '#91ca8c',  '#f49f42'];

  useEffect(() => {
    async function getInfluencers() {


      const tempIndicator = Array<IIndicator>(0);
      for (let i = 0; i < 24; i++) 
        tempIndicator.push({max: 10});


      setIndicator(tempIndicator);
      if(allInfluencers){
        
        const chart = Array(0);
        const chartRadar = Array(0);
        const nums = [0,12,6,18,22,2,14,10,4,16,8, 20,1,3,5,7,9,11,13,15,17,19];


        const valuesInfluencers:Array<number> = [];
        const valuesPromotor:Array<number> = [];
        const valuesNeutral:Array<number> = [];
        const valuesDetractor:Array<number> = [];

        allInfluencers.map((influencer) => {
          const values = [];
          const value = influencer.distance_influencer && parseFloat(influencer.distance_influencer) >1 ? parseFloat(influencer.distance_influencer) : 1.8;


          switch (influencer.group_influencer) {
            case '1': valuesPromotor.push(10-value); break;
            case '2': valuesNeutral.push(10-value); break;
            case '3': valuesDetractor.push(10-value); break;
            default: valuesInfluencers.push(10-value); break;
          }


          for (let i = 0; i < 24; i++) {
            if(nums[chart.length] == i)
                values.push(value);
            else
                values.push(0);
          }

          const orderInfluencer = influencer.order_influencer ? influencer.order_influencer : '0';

          const colorGroup = (()=> { 
            switch(influencer.group_influencer) {
              case '3': return '#c23531';
              case '2': return '#bbacac';
              case '1': return '#4D8000';
              default: return '#37A2DA';
         }})();


          const colorChart: IColorDataChart = {
            color : colorGroup,
          };

          const newDataChart: IDataChart = {
            name : influencer.name_influencer,
            value: values,
            itemStyle:colorChart,
            label:{show:true, position: 'inside', color:'#fff', formatter: orderInfluencer}
          };
          chart.push(newDataChart);
        });

        const newDataChart: IDataChart = {
          name :'Você',
          value: [0],
          symbolSize:30,
          label:{show:true, position: 'inside', color:'black', formatter: '███'},
          itemStyle: {
            color:'black',
          },
        };
      chart.push(newDataChart);
      setDataChart(chart);


      setDataChartPie([{  name: 'Promotores',
                          value: valuesPromotor.length,
                          itemStyle: {
                            color:'#4D8000',
                          },
                        },{
                          name: 'Neutro',
                          value: valuesNeutral.length,
                          itemStyle: {
                            color:'#bbacac',
                          },
                        },{
                          name: 'Detratores',
                          value: valuesDetractor.length,
                          itemStyle: {
                            color:'#c23531',
                          },
                        }]);
/*
      switch (influencer.group_influencer) {
        case '1': valuesPromotor.push(10-value); break;
        case '2': valuesNeutral.push(10-value); break;
        case '3': valuesDetractor.push(10-value); break;
        default: valuesInfluencers.push(10-value); break;
*/
      const orderInfluencer = 'asda';

      chartRadar.push({
        name : 'Promotores',
        value: valuesPromotor,
        itemStyle:{
          color : '#4D8000',
        },
        label:{show:false, position: 'inside', color:'#fff', formatter: orderInfluencer}
      });
      
      chartRadar.push({
        name : 'Neutros',
        value: valuesNeutral,
        itemStyle:{
          color : '#bbacac',
        },
        label:{show:false, position: 'inside', color:'#fff', formatter: orderInfluencer}
      });

      chartRadar.push({
        name : 'Detratores',
        value: valuesDetractor,
        itemStyle:{
          color : '#c23531',
        },
        label:{show:false, position: 'inside', color:'#fff', formatter: orderInfluencer}
      });


      //alert(valuesDetractor);

      let t = 0;
      if(valuesInfluencers.length > valuesPromotor.length)
        if(valuesInfluencers.length > valuesNeutral.length)
          if(valuesInfluencers.length > valuesDetractor.length)
            t = valuesInfluencers.length;
          else
            t = valuesDetractor.length;
        else
          if(valuesNeutral.length > valuesDetractor.length)
            t = valuesNeutral.length;
          else
            t = valuesDetractor.length;
      else
      if(valuesPromotor.length > valuesNeutral.length)
        if(valuesPromotor.length > valuesDetractor.length)
          t = valuesPromotor.length;
        else
          t = valuesDetractor.length;
      else
        if(valuesNeutral.length > valuesDetractor.length)
          t = valuesNeutral.length;
        else
          t = valuesDetractor.length;


      const tempIndicator = Array<IIndicatorRadar>(0);
      for (let i = 0; i < t; i++) 
        tempIndicator.push({name:i.toString()});
      
      setIndicatorRadar(tempIndicator);


      setDataChartRadar(chartRadar);

      }
    }
    getInfluencers();
  }, [allInfluencers]);


  const target = {

    title: {
      text: 'Proximidade x Percepção',
      top: 0,
      left:'center'
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a}<br/><strong>{b}</strong>'
    },
    radar: {
      indicator: indicator,
      radius: '90%',
      center: ['50%', '55%'],
      startAngle: 90,
      splitNumber: 5,
      shape: 'circle',
      axisLine: [],
      axisLabel: [],
    },
    series: [
      {
        name: 'Influenciadores e você',
        type: 'radar',
        symbolSize:25,
        data: dataChart,
        lineStyle:
          {
          width: 0,
          }
      }
    ]
  };

  const pie = {

    title: {
      text: 'Total por Percepção',
      top: 0,
      left:'center'
    },
    tooltip: {
      trigger: 'item'
    },
    series: [
      {      
        name: 'Grupo',
        type: 'pie',
        radius: '70%',
        data: dataChartPie,
      }
    ]
  };

  const radar = {

    title: {
      text: 'Volume por Proximidade',
      top: 0,
      left:'center'
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a}<br/><strong>{b}</strong>'
    },
    radar: {
      indicator: indicatorRadar,
      radius: '90%',
      center: ['50%', '55%'],
      startAngle: 90,
      //splitNumber: 5,
      //shape: 'circle',
      axisLine: [],
      axisLabel: [],
    },
    series: [
      {
        name: 'Influenciadores e você',
        type: 'radar',
        symbolSize:0,
        areaStyle: {},
        data: dataChartRadar,
       /* lineStyle:
          {
          width: 0,
          }*/
      }
    ]
  };

  return (
    <Grid container spacing={3}>
      <Grid item sm={12} md={6} sx={{marginTop:'40px'}}>
        <ReactEcharts style={{width:'100%', height:'100%', minHeight:'350px'}}
            option={{
                ...target,
            }}
        />
      </Grid>
      <Grid item sm={12} md={6} sx={{marginTop:'40px'}}>
        <ReactEcharts style={{width:'100%', height:'100%', minHeight:'350px'}}
            option={{
                ...pie,
            }}
        />
      </Grid>
      {/*
      <Grid item sm={12} md={6} sx={{marginTop:'40px'}}>
        <ReactEcharts style={{width:'100%', height:'100%', minHeight:'350px'}}
            option={{
                ...radar,
            }}
        />
      </Grid>

      */}

    </Grid>
  );
};