import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { AuthService } from '../../services/api/auth/AuthService';
import { useAppThemeContext } from '../';

import { setUserLocalStorange} from './util';
import { IAuthContextData, IAuthProviderProps, IUser} from './types';

import { useSnackbar } from 'notistack';

export const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  
  const [accessAuth, setAccessAuth] = useState<boolean | null>(null);
  const [user, setUser] = useState<IUser | null>(null);
  const { emailName } = useAppThemeContext();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function AuthTeamAPI() {
        const mailMicrosoft = emailName + '';
        const userTeam = await AuthService.authTeams(mailMicrosoft);

        if (userTeam instanceof Error) {
          setUser(null);
          setUserLocalStorange(null);
          setAccessAuth(false);
        } else {
          setUser(userTeam);
          setUserLocalStorange(userTeam);
          setAccessAuth(true);
        }
      }

      if(emailName)
        AuthTeamAPI();
      else
        setAccessAuth(false);
    
  }, []);

  const handleLogin = useCallback(async (email?: string, password?: string) => {
 
    if(email && password) {
      const userLoginManual = await AuthService.auth(email, password);
      if (userLoginManual instanceof Error) {
        setUser(null);
        setUserLocalStorange(null);
        setAccessAuth(false);
        enqueueSnackbar('O e-mail ou senha informados nâo estão corretos.', { variant:'error'});
      } else {  
        setUser(userLoginManual);
        setUserLocalStorange(userLoginManual);
        setAccessAuth(true);
      }
    }
    else {
      setUser(null);
      setUserLocalStorange(null);
      setAccessAuth(false);
    }
  }, []);

  const handleLogout = useCallback(() => {
    setUser(null);
    setUserLocalStorange(null);
    setAccessAuth(false);
  }, []);


  const isAuthenticated = useMemo(() => accessAuth, [accessAuth]);


  return (
    <AuthContext.Provider value={{ isAuthenticated, ...user, login:(email,login) => handleLogin(email,login), logout: () => handleLogout() }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
