import React, { useState , useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams} from 'react-router-dom';
import { Container, Alert, Box, Pagination, LinearProgress, Grid, TextField, Fab, Chip, Autocomplete } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { IVideo, ITagsVideos } from '../../contexts/VideosContext/types';
import { EASVideoCard } from './EASVideoCard';

import { useCourseContext , useTeamContext} from '../../contexts';
import { Environment } from '../../environment';
import { VideosService } from '../../services/api/videos/VideosService';
import { EASCard } from '../eas-card/EASCard';


interface IVideosProps {
  children?: () => void;
}  

export const EASVideos: React.FC<IVideosProps> = () => {

  //const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [videos, setVideos] = useState<Array<IVideo> | null>(null);
  const [tagsVideo, setTagsVideo] = useState<Array<ITagsVideos> | null>(null);
  const [tagsIDVideo, setTagsIDVideo] = useState<Array<string>>([]);
  
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [updateList, setUpdateList] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState<string>('');

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();
  
  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const tags = useMemo(() => {
    return searchParams.get('tags') || '';
  }, [searchParams]);
  
  const executeNavigatte  = (urlNavigator:string) => {
    navigate(urlNavigator);
    setUpdateList(true);
  }; 

  useEffect(() => {
    async function getVideos() {
      if(enrollment.id_inscricao){
        setIsLoading(true);
        const responseVideos = await VideosService.getAllVideos((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), enrollment.id_evento, team.id_equipe, team.id_mentoria_tean,'', search, tagsIDVideo.toString(), pagina);
        if (responseVideos instanceof Error) {
          setVideos(null);
          setTotalCount(0);
          setIsLoading(false);
          setUpdateList(false);
        } else {
          if(responseVideos.tags){
            const myTagsVideo = JSON.parse(JSON.stringify(responseVideos.tags));
            setTagsVideo(myTagsVideo);
          }
            
          if(responseVideos.data && responseVideos.total){
            const myVideos = JSON.parse(JSON.stringify(responseVideos.data));
            setVideos(myVideos);
            setIsLoading(false);
            setTotalCount(responseVideos.total);
            setUpdateList(false);
          }
          else{
            setVideos(null);
            setTotalCount(0);
            setIsLoading(false);
            setUpdateList(false);
          }
        }
      }
    }
    getVideos();
  }, [searchParams, updateList, team.id_equipe]);  

  return (
    <Container>
      <Box width="100%">
        <Box sx={{ display: 'flex', justifyContent: 'center', height: 'auto', marginBottom: '15px' }}>
            <EASCard>
              <Box style={{ display: 'flex', justifyContent: 'center', height: 'auto' }}>
                <TextField
                  variant="outlined"
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                  fullWidth
                  label={Environment.INPUT_DE_BUSCA}
                  type={Environment.INPUT_DE_BUSCA}
                />

                
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Fab
                    size="small"
                    aria-label="save"
                    color="primary"
                    onClick={() => setSearchParams({ busca:search, pagina:'1' }, { replace: true })}
                  >
                    {<SearchIcon />}
                  </Fab>
                </Box>
              </Box>
            </EASCard>

          </Box>
          {(tagsVideo && tagsVideo.length > 1) && (
          <Box sx={{ display: 'flex', justifyContent: 'center', height: 'auto', marginBottom: '15px' }}>
            <EASCard>
              <Box style={{ display: 'flex', justifyContent: 'center', height: 'auto' }}>
                <Autocomplete
                  multiple
                  options={tagsVideo}
                  getOptionLabel={(option) => option.name_material_tag}
                  onChange={(event: any, newValue: Array<ITagsVideos> | null) => {

                    setTagsIDVideo([]);
                    if(newValue){
                      newValue.map((value) => ( setTagsIDVideo((prevTagsIDVideo) => [ ...prevTagsIDVideo, value.id_material_tag, ])));
                      setSearchParams({tags: tagsIDVideo, pagina:'1' }, { replace: true });
                    }   
                    else{
                      setSearchParams({tags: '', pagina:'1' }, { replace: true });
                    }
                  }}

                  filterSelectedOptions
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tags"
                    />
                  )}
                />
              </Box>

              
            </EASCard>
          </Box>
          )}

        {isLoading && (
        <LinearProgress variant='indeterminate' />
        )}
        {!isLoading && (videos && videos.length>0 ? (
        <Grid container spacing={1} sx={{ mb: 3}}> 
            {videos.map((video, index) => (
            <Grid item key={index} md={12} lg={4} xl={3} sx={{width:'100%'}}>
              <Box >
                {video.id && <EASVideoCard video={video} clickToNavigate={(e) => executeNavigatte(e)} /> }
              </Box>
            </Grid>
            ))}        
        </Grid>
        ) : (
        <Alert severity="warning">Nenhum vídeo encontrado!</Alert>
        ))}
      </Box>
      {!isLoading && totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
      <Box style={{ display: 'flex', justifyContent: 'center', padding:'20px 0 20px 0' }}>
        <Pagination
          page={pagina}
          count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
          onChange={(_, newPage) => setSearchParams({ busca, tags, pagina: newPage.toString() }, { replace: true })}
          variant="outlined" 
          shape="rounded"
          color="primary"
          siblingCount={3} 
          boundaryCount={1}
        />
      </Box>
      )}
    </Container> 
  );
};