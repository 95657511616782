import React, { useState , useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams, useParams} from 'react-router-dom';
import {
  Theme, 
  useMediaQuery, 
  Paper, 
  Chip, 
  Grid, 
  Typography, 
  StepButton, 
  ListItemAvatar, 
  Avatar, 
  Box,
  Container, 
  List, 
  ListItem, 
  Button, 
  ListItemText, 
  IconButton, 
  LinearProgress  } from '@mui/material/';

import ContactMailIcon from '@mui/icons-material/ContactMail';
import CancelIcon from '@mui/icons-material/Cancel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import { IObjective } from '../../contexts/ObjectiveContext/types';

import * as yup from 'yup';

import { IEvaluationPartial,  IEvaluationPartialDetailResponse } from '../../contexts/EvaluationPartialContext/types';
import { useCourseContext , useTeamContext, useAuthContext} from '../../contexts';

import { EASEvaluationPartialEditItem } from './EASEvaluationPartialEditItem';
import { EASEvaluationPartialEditText } from './EASEvaluationPartialEditText';

import { EvaluationPartialService } from '../../services/api/evaluation-partial/EvaluationPartialService';
import { EvaluationPartialDetailService } from '../../services/api/evaluation-partial-detail/EvaluationPartialDetailService';
import { useSnackbar } from 'notistack';
import { EASCard } from '../eas-card/EASCard';




interface IEvaluationPartialEditProps {
  children?: () => void;
}  

export const EASEvaluationPartialEdit: React.FC<IEvaluationPartialEditProps> = () => {

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id = ''} = useParams<'id'>();

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();
  const { ...user } = useAuthContext();
 
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);

  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [updateList, setUpdateList] = useState(false);

  const [allEvaluationPartialDetailResponse, setAllEvaluationPartialDetailResponse] = useState<Array<IEvaluationPartialDetailResponse> | null>(null);

  const [totalCount, setTotalCount] = useState(0);


  const [idEvaluationPartial, setIdEvaluationPartial] = useState<string>('');
  //const [dateEvaluationPartial, setDateEvaluationPartial] = useState<string>('');
 // const [textEvaluationPartial, setTextEvaluationPartial] = useState<string>('');
  const [typeEvaluationPartial, setTypeEvaluationPartial] = useState<string>('');
  const [mailEvaluationPartial, setMailEvaluationPartial] = useState<string>('');
  
  const [statusEvaluationPartial, setStatusEvaluationPartial] = useState<string>('');

  const [activeStep, setActiveStep] = useState(0);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);


  const externalEvaluationPartialSchema = yup.object().shape({
    emailEvaluationPartial: yup.string().email('Informe um e-mail válido').required('Informe uma Nota')
  });

  useEffect(() => {
    async function getEvaluationsPartial() {
      if(enrollment.id_inscricao){
        setIsLoading(true);
        const evaluationPartialResponse = await EvaluationPartialDetailService.getAllEvaluationPartialDetail((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), team.id_equipe, team.id_mentoria_tean, id, 0, pagina);
        if (evaluationPartialResponse instanceof Error) {
          setAllEvaluationPartialDetailResponse(null);
          setTotalCount(0);
          setIsLoading(false);
          enqueueSnackbar('Não foi possivel carregar a Avaliação parcial', { variant:'error'});
          navigate('/avaliacao-parcial');
        } else {
          if(evaluationPartialResponse.data && evaluationPartialResponse.total){
            const myEvaluationPartial = JSON.parse(JSON.stringify(evaluationPartialResponse.data));
            setAllEvaluationPartialDetailResponse(myEvaluationPartial);
            setIsLoading(false);
            if(evaluationPartialResponse.total)
              setTotalCount(evaluationPartialResponse.total);

            if(evaluationPartialResponse.id_evaluation_partial)
              setIdEvaluationPartial(evaluationPartialResponse.id_evaluation_partial);
            
           // if(evaluationPartialResponse.date_evaluation_partial)
           //   setDateEvaluationPartial(evaluationPartialResponse.date_evaluation_partial);
            
           // if(evaluationPartialResponse.text_evaluation_partial)
          //   setTextEvaluationPartial(evaluationPartialResponse.text_evaluation_partial);

            if(evaluationPartialResponse.type_evaluation_partial)
              setTypeEvaluationPartial(evaluationPartialResponse.type_evaluation_partial);
            
            if(evaluationPartialResponse.status_evaluation_partial)
              setStatusEvaluationPartial(evaluationPartialResponse.status_evaluation_partial);  
            
            if(evaluationPartialResponse.mail_evaluation_partial)
              setMailEvaluationPartial(evaluationPartialResponse.mail_evaluation_partial); 
              
              
          }
          else{
            setAllEvaluationPartialDetailResponse(null);
            setTotalCount(0);
            setIsLoading(false);
          }
        }
      }
    }
    getEvaluationsPartial();
  }, [searchParams, updateList, team.id_equipe]);

/*
  const handleConfirmeExternalEvaluationPartial = () => {
      externalEvaluationPartialSchema
      .validate({ emailEvaluationPartial}, { abortEarly: false })
      .then(validateValues => {    
        setOpenExternalEvaluationPartial(false);
      })
      .catch((errors: yup.ValidationError) => {
        //setLoading(false);
        errors.inner.forEach(error => {
          setEmailEvaluationPartialError(error.message);
        });
      });    
  };
*/

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if((allEvaluationPartialDetailResponse && (activeStep >= (allEvaluationPartialDetailResponse.length - 1)))) {
      setIsLoading(true);
      if(idEvaluationPartial) {
        const evaluationPartial: IEvaluationPartial = {
          id_evaluation_partial: idEvaluationPartial,
          status_evaluation_partial:'2'
        };
        EvaluationPartialService.updateEvaluationPartial(evaluationPartial).then((responseEvaluationPartialDetail) => {
          if (responseEvaluationPartialDetail instanceof Error) {
            enqueueSnackbar('Erro ao tentar finalizar avaliação parcial!', { variant:'error'});
          } else {
            enqueueSnackbar('Avaliação parcial finalizada com sucesso', { variant:'success'});
            setStatusEvaluationPartial('2');
          }
          setIsLoading(false);
        });
      }
    }
  };
 

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {

    if(idEvaluationPartial) {
      setIsLoading(true);
      const evaluationPartial: IEvaluationPartial = {
        id_evaluation_partial: idEvaluationPartial,
        status_evaluation_partial:'0'
      };
      EvaluationPartialService.updateEvaluationPartial(evaluationPartial).then((responseEvaluationPartialDetail) => {
        if (responseEvaluationPartialDetail instanceof Error) {
          enqueueSnackbar('Erro ao tentar reabrir avaliação parcial!', { variant:'error'});
        } else {
          setStatusEvaluationPartial('0');
        }
        setIsLoading(false);
      });
    }
    setActiveStep(0);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleSend = (step: number) => () => {
    setActiveStep(step);
  };
  

  const Ratinglabels: { [index: string]: string } = {
    1: 'Não Percebido',
    2: 'Pouco Percebido',
    3: 'Percebido',
    4: 'Muito Percebido',
    5: 'Excedeu as Expectativas',
  };

 // const getLabelText = (value: number) => {
  //  return `${value} Star${value !== 1 ? 's' : ''}, ${Ratinglabels[value]}`;
  //};

  return (
    <Container>
      {isLoading && (
        <LinearProgress variant='indeterminate' sx={{ mt:'15px'}} />
      )}
      {!isLoading && (
      <>
        <Box style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 10px 0' }}>
          <EASCard>
            <Grid container style={{ display: 'flex', justifyContent: 'center'}} spacing={2}>
              <Grid item xs={11} >  
                <Grid container style={{ display: 'flex', justifyContent: 'center'}} spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>  
                    <ListItem>
                      <ListItemAvatar sx={{marginRight:'5px'}}>
                      <Avatar sx={{ border:'1px solid #EA5B0C', height: '50px', width: '50px' }} 
                                alt={(team.type_tean=='1' ? team.name : user.name)} 
                                src={(team.type_tean=='1' ? team.avatar : user.avatar) && 'https://online.sbdc.com.br/media/images/avatar/' + (team.type_tean=='1' ? team.avatar : user.avatar)} />
                      </ListItemAvatar>
                      <ListItemText 
                        primary={<Typography variant="h6">{(team.type_tean=='1' ? team.name : user.name)}</Typography>} 
                        secondary={<Typography  sx={{fontSize:'12px'}}>MENTORADO</Typography>}
                        />
                    </ListItem> 
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: lgDown ? 'center':'top', marginTop:lgDown ? '5px' : '0px'}} spacing={2}>
                      {typeEvaluationPartial === '1' && (  
                      <Grid item xs={12} >
                        <Box style={{ display: 'flex'}}>   
                          <Chip label={mailEvaluationPartial} variant="outlined" onDelete={handleSend} deleteIcon={<ContactMailIcon style={{fontSize:'16px', marginRight:'10px'}} />} />
                        </Box>
                      </Grid>
                      )}
                      <Grid item xs={12} style={{ display: 'flex', verticalAlign: mdDown ? 'center':'top', justifyContent: 'center', alignItems: mdDown ? 'center':'top', marginTop: mdDown ? '-25px' : '10px'}} > 
                        {statusEvaluationPartial === '2' ? (
                        <Chip label="Avaliação Parcial Finalizada" color="primary" />
                        ) : (
                        <Chip label="Avaliação Parcial Não Finalizada" color="primary" variant="outlined" />
                        )}  
                      </Grid>
                    </Grid>
                  </Grid> 
                </Grid> 
              </Grid> 
              <Grid item xs={1}> 
                <Box style={{ display: 'flex', justifyContent: 'right', alignItems: 'top', marginTop:'-10px', marginRight:'-5px'}}> 
                  <IconButton aria-label="Fechar" onClick={() => navigate('/avaliacao-parcial')}>
                    <CancelIcon />
                  </IconButton>
                  </Box>  
              </Grid>
            </Grid>
          </EASCard>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0' }}>
          <EASCard>
            <Stepper activeStep={activeStep} orientation="vertical" nonLinear={(typeEvaluationPartial === '0' && statusEvaluationPartial !== '2' && team.type_tean === '0' && enrollment.status_mentory_inscricao !='1')  ? false : true} >
              {allEvaluationPartialDetailResponse && (allEvaluationPartialDetailResponse.map((objective, index) => (
                <Step key={objective.id_objective}  completed={statusEvaluationPartial === '2' ||  enrollment.status_mentory_inscricao =='1' || activeStep > index ? true : false} >
                  {(team.type_tean === '1' || typeEvaluationPartial === '1' || statusEvaluationPartial === '2' || enrollment.status_mentory_inscricao =='1' ) ? (
                    <StepButton color="inherit" onClick={handleStep(index)}>
                    {objective.text_objective}
                    </StepButton>
                  ) : (
                    <StepLabel>
                    {objective.text_objective}
                    </StepLabel>
                  )}
                  <StepContent>
                    <List>
                    {objective.evaluation_partial_detail && (objective.evaluation_partial_detail.map((evaluationPartialDetail, index) => (
                      <EASEvaluationPartialEditItem key={evaluationPartialDetail.id_objective_detail} evaluationPartialDetail={evaluationPartialDetail} typeEvaluationPartial={typeEvaluationPartial} statusEvaluationPartial={statusEvaluationPartial} teamEvaluationPartial={team.type_tean} statusEvaluationFinal={team.type_tean=='1' ? team.status_mentory_inscricao : enrollment.status_mentory_inscricao} />
                    )))}
                    </List>
                    {((typeEvaluationPartial === '1' && team.type_tean === '1') || typeEvaluationPartial === '0') && (
                    <EASEvaluationPartialEditText 
                      disabled={(typeEvaluationPartial === '1' || statusEvaluationPartial === '2' || enrollment.status_mentory_inscricao =='1')} 
                      evaluationPartialComment={objective.evaluation_partial_comment} 
                    />
                    )}
                    {((typeEvaluationPartial === '0' && statusEvaluationPartial !== '2' && enrollment.status_mentory_inscricao !='1')) && (
                    <Box sx={{ mb: 2 }}>
                      <div>
                        {(index !== allEvaluationPartialDetailResponse.length - 1 || typeEvaluationPartial === '0') && (
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === allEvaluationPartialDetailResponse.length - 1 ? 'Finalizar Avaliação' : 'Próxima Competência'}
                        </Button>
                        )}
                        {index !== 0 && allEvaluationPartialDetailResponse.length > 1 && (
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Voltar a Competência Anterior
                        </Button>
                          )}
                      </div>
                    </Box>
                    )}
                  </StepContent>
                </Step>
              )))}
            </Stepper>
            {(team.type_tean=='1' && team.status_mentory_inscricao !='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao !='1') ?
            ((statusEvaluationPartial === '2')  && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography variant="h5">Esta avaliação parcial foi marcada como concluída</Typography>
              {((team.type_tean === '0' && typeEvaluationPartial === '0' && statusEvaluationPartial === '2')  && (
                <Typography variant="subtitle1">Caso deseje reabrir esta Avaliação Parcial, solicite a seu mentor</Typography>
              ))}
              {((team.type_tean === '1' && typeEvaluationPartial === '0' && statusEvaluationPartial === '2')  && (
              <Button variant="contained" onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                Reabrir Avaliação Parcial
              </Button>
              ))}
            </Paper>
            )):(
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography variant="h5">Este programa foi encerrado</Typography>
              {((team.type_tean === '0') && (
                <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
              ))}
            </Paper>
            )}
          </EASCard>
        </Box> 
      </>
      )} 
    </Container>
  );
};