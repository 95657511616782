import { Container, Grid, } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
//import { EASFileCard } from './EASFileCard';
import { IFile, ITagsFiles } from '../../contexts/FilesContext/types';

import { useCourseContext , useTeamContext} from '../../contexts';
import { Environment } from '../../environment';
import { FilesService } from '../../services/api/files/FilesService';
import { EASToolCard } from './EASToolCard';

interface IEASToolCard {
  image: string;
  title: string;
  description: string;
  link: string;
}  

export const EASTools = () => {

  const tools : IEASToolCard[] = 
  [
    {
      image: '/listInfluencer.jpg',
      title: 'Influenciadores de Reputação',
      description: 'Crie sua lista de influenciadores de reputação. Defina em ordem crescente, quem são as pessoas que mais podem influenciar sua reputação, conforme sua percepção.',
      link: '/ferramentas-auxiliares/influenciadores-de-reputacao',
    },
    {
      image: '/proximityMap.jpg',
      title: 'Mapa de Proximidade',
      description: 'Em uma escala de 1 a 10, defina a distância que cada influenciador de reputação está de você e perceba o quão próximo estão aqueles que podem influenciar em sua reputação.',
      link: '/ferramentas-auxiliares/mapa-de-proximidade',
    },
    {
      image: '/labelMapping.jpg',
      title: 'Mapeamento dos Rótulos',
      description: 'Descreva, baseado em sua percepção, quais seriam os rótulos positivos e negativos, que cada influenciador de reputação poderia ter a seu respeito.',
      link: '/ferramentas-auxiliares/mapeamento-dos-rotulos',
    },
    {
      image: '/perceptionsOfInfluencers.jpg',
      title: 'Score dos Influenciadores',
      description: 'Considerando o conceito de NPS, classifique a percepção dos influenciadores em relação a você.',
      link: '/ferramentas-auxiliares/score-dos-influneciadores',
    },
  ];


    return(
      <Container>
        <Grid container spacing={1}>
          {(tools && tools.length) && tools.map((Tool, index) => (
          <Grid key={index} item sm={12} md={6} lg={3}>
            <EASToolCard image={Tool.image} title={Tool.title} description={Tool.description} link={Tool.link} />
          </Grid>
          ))}
        </Grid>
      </Container>
    );
};