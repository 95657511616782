import { createTheme } from '@mui/material';
import { orange, grey } from '@mui/material/colors';

export const LightTheme = createTheme({
  palette: {
    primary: {
      main: orange[900],
      dark: orange[900],
      light: orange[800],
      contrastText: '#ffffff',
    },
    secondary: {
      main: grey[200],
      dark: grey[300],
      light: grey[100],
      contrastText: '#ffffff',
    },
    background: {
      paper: '#FFFFFF',
      default: '#fbfbfb',
    },
    text:{
      primary: '#333333',
      secondary: '#8e8b8b',
      disabled: '#8e8b8b',
    },
  },
});
