import React, { useState, useEffect } from 'react';
import {ListItemAvatar, Box, Hidden, ListItem, ListItemText, Rating, Divider, Typography, TextField } from '@mui/material/';
import { useSnackbar } from 'notistack';

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import StraightIcon from '@mui/icons-material/Straight';


import { IEvaluationFinalItem, IEvaluationFinal } from '../../contexts/EvaluationFinalContext/types';
import { useCourseContext, useTeamContext } from '../../contexts';


import { EvaluationFinalService } from '../../services/api/evaluation-final/EvaluationFinalService';

interface IEvaluationFinalItemProps {
  evaluationFinalItem: IEvaluationFinalItem | null;
  objectiveId?: string; 
  children?: () => void;
}  

export const EASEvaluationFinalItem: React.FC<IEvaluationFinalItemProps> = ( { evaluationFinalItem, objectiveId }) => {
 
  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();

  const [valueEvaluationFinal, setValueEvaluationFinal ] = useState<number>(0);
  const [evaluationFinalItemList, setEvaluationFinalItemList ] = useState<IEvaluationFinalItem | null>(null);
  const [evaluationFinal, setEvaluationFinal ] = useState<IEvaluationFinal | null>(null);
  
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    function loadEditObjectiveDetail() {
      if(evaluationFinalItem){
        setEvaluationFinalItemList(evaluationFinalItem);
        if(evaluationFinalItem.value_evaluation_final)
          setValueEvaluationFinal(parseFloat(evaluationFinalItem.value_evaluation_final));
      }  
    }
    loadEditObjectiveDetail();
  }, [evaluationFinalItem]);



  const storeValueEvaluationFinal  = async () => {


    if(evaluationFinalItemList && evaluationFinalItemList.id_evaluation_final){
      evaluationFinalItemList.value_evaluation_final = valueEvaluationFinal.toString();
      evaluationFinalItemList.date_evaluation_final = new Date().toLocaleDateString('pt-BR');
      EvaluationFinalService.updateEvaluationFinal(evaluationFinalItemList).then((responseEvaluationFinal) => {
        if (responseEvaluationFinal instanceof Error) {
          enqueueSnackbar('Erro ao tentar salvar nota final', { variant:'error'});
        } else {
          if( evaluationFinalItem ) {
            evaluationFinalItem.value_evaluation_final = evaluationFinalItemList.value_evaluation_final;
            evaluationFinalItem.date_evaluation_final = evaluationFinalItemList.date_evaluation_final;
            setEvaluationFinalItemList(evaluationFinalItem);
            setEvaluationFinal(evaluationFinal);
          }
        }
      });
    }
    else {
      if(evaluationFinalItemList && objectiveId) {
        const newEvaluationFinal: IEvaluationFinal = {
          id_evaluation_final:(evaluationFinalItemList && evaluationFinalItemList.id_evaluation_final ? evaluationFinalItemList.id_evaluation_final : ''),
          id_inscricao: (team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao),
          id_equipe: team.id_equipe,
          id_mentoria_tean: team.id_mentoria_tean,
          id_objective: objectiveId,
          id_objective_detail: evaluationFinalItemList.id_objective_detail,
          status_evaluation_final: (evaluationFinalItemList.status_evaluation_final ? evaluationFinalItemList.status_evaluation_final : '1'),
          value_evaluation_final: valueEvaluationFinal.toString(),
          text_evaluation_final: valueEvaluationFinal.toString(),
          date_evaluation_final: new Date().toLocaleDateString('pt-BR'),
        };  
        
        const idEvaluationPartialDetail = await  EvaluationFinalService.createEvaluationFinal(newEvaluationFinal);

        if (idEvaluationPartialDetail instanceof Error) {
            enqueueSnackbar('Erro ao tentar salvar nota final', { variant:'error'});
        } else {
            if( evaluationFinalItem ) {
              evaluationFinalItem.id_evaluation_final = idEvaluationPartialDetail;
              evaluationFinalItem.value_evaluation_final = valueEvaluationFinal.toString();

            setEvaluationFinalItemList(evaluationFinalItem);
            setEvaluationFinal(evaluationFinal);
          }
        }
      }
    }
  }; 
/*
  const renderSwitch = (media_evaluation_final?:string) => {

    switch(Math.round(Number(media_evaluation_final))) {
      
      case 1:
        return <TrendingDownIcon sx={{ fontSize:'2em', fontWeight:'bold' }} color="error" />;
      case 2:
        return <TrendingDownIcon sx={{ fontSize:'2em', fontWeight:'bold' }} color="warning" />;
      case 3:
        return <TrendingFlatIcon sx={{ fontSize:'2em', fontWeight:'bold' }} color="warning" />;
      case 4:
        return <TrendingUpIcon sx={{ fontSize:'2em', fontWeight:'bold' }} color="success" />;
      case 5:
        return <StraightIcon sx={{ fontSize:'2em', fontWeight:'bold' }} color="success" />;
      default:
        return <TrendingFlatIcon sx={{ fontSize:'2em', fontWeight:'bold' }} color="warning" />;
    }
  };
*/
  return (
    <>
      {evaluationFinalItem && (
      <>
       <ListItem>
          <ListItemText primary={evaluationFinalItem.text_objective_detail} secondary={'Nota Inicial: ' + evaluationFinalItem.value_objective_detail }/>
          
          <Hidden smDown>
            <ListItemAvatar sx={{minWidth:'160px'}}>

              <Box style={{ display: 'flex', justifyContent:'center', width:'160px'}}>
                {/*
                <Box style={{ display: 'flex', justifyContent:'center', width:'50px'}}>
                  <Box sx={{ mr:5, justifyContent:'center'}}>
                    <Typography variant="subtitle1" sx={{fontSize:'8px', whiteSpace:'nowrap'}}>
                    Avaliacões Parciais
                    </Typography>
                    <Box style={{display: 'flex', justifyContent:'center', width:'100%'}}>
                      {renderSwitch(evaluationFinalItem.media_evaluation_final)}
                    </Box>
                  </Box>
                </Box>
                */}
                <TextField
                  value={+valueEvaluationFinal}
                  onChange={(event) => setValueEvaluationFinal(Number(event.target.value))}
                  onBlur={storeValueEvaluationFinal}
                  label="Nota Final"
                  type="number"
                  disabled={!(team.type_tean=='1' && team.status_mentory_inscricao !='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao !='1' )}
                />
              </Box>
            </ListItemAvatar>
          </Hidden>
        </ListItem>
        <Hidden smUp>

          <Box style={{marginBottom:'10px', marginLeft:'10px'}}>
            <Box style={{ display: 'flex', justifyContent:'center', width:'160px'}}>
              {/*
              <Box style={{ display: 'flex', justifyContent:'center', width:'50px'}}>
                <Box sx={{ mr:5, justifyContent:'center'}}>
                  <Typography variant="subtitle1" sx={{fontSize:'8px', whiteSpace:'nowrap'}}>
                  Avaliacões Parciais
                  </Typography>
                  <Box style={{display: 'flex', justifyContent:'center', width:'100%'}}>
                    {renderSwitch(evaluationFinalItem.media_evaluation_final)}
                  </Box>
                </Box>
              </Box>
              */}
              <TextField
                value={+valueEvaluationFinal}
                onChange={(event) => setValueEvaluationFinal(Number(event.target.value))}
                onBlur={storeValueEvaluationFinal}
                label="Nota Final"
                type="number"
                disabled={!(team.type_tean=='1' && team.status_mentory_inscricao !='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao !='1' )}
              />
            </Box>
          </Box>
        </Hidden>
        <Divider variant="inset" component="hr" style={{marginLeft:'-10px'}} />
      </>
      )}
      </>
  );
};