import React, { useState, useEffect } from 'react';
import {Theme, ListItemAvatar, Box, useMediaQuery, ListItem, ListItemText, Rating, Divider, Typography, Hidden } from '@mui/material/';

import { IEvaluationPartialDetail } from '../../contexts/EvaluationPartialContext/types';

import { useSnackbar } from 'notistack';

import { EvaluationPartialDetailService } from '../../services/api/evaluation-partial-detail/EvaluationPartialDetailService';

interface IEvaluationPartialEditItemProps {
  evaluationPartialDetail: IEvaluationPartialDetail | null;
  typeEvaluationPartial?: string;
  statusEvaluationPartial?: string;
  statusEvaluationFinal?: string;
  teamEvaluationPartial?: string;
  children?: () => void;
}  

export const EASEvaluationPartialEditItem: React.FC<IEvaluationPartialEditItemProps> = ( {evaluationPartialDetail, typeEvaluationPartial, statusEvaluationPartial, teamEvaluationPartial, statusEvaluationFinal}) => {

  const [valueRating, setValueRating] = useState<number | null>(0);
  const [hoverRating, setHoverRating] = useState(-1);
  const [evaluationPartialDetailList,  setEvaluationPartialDetailList ] = useState<IEvaluationPartialDetail | null>(null);
  
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    function loadEditObjectiveDetail() {
      if(evaluationPartialDetail){
        setEvaluationPartialDetailList(evaluationPartialDetail);
        if(evaluationPartialDetail.value_evaluation_partial_detail)
          setValueRating(parseInt(evaluationPartialDetail.value_evaluation_partial_detail));
      }  
    }
    loadEditObjectiveDetail();
  }, [evaluationPartialDetail]);


  const handleClickRating  = async (newValueRating : number) => {
    const tempValue = valueRating;
    setValueRating(newValueRating);

    if(evaluationPartialDetailList && evaluationPartialDetailList.id_evaluation_partial_detail){
      evaluationPartialDetailList.value_evaluation_partial_detail = newValueRating.toString();
      evaluationPartialDetailList.date_evaluation_partial_detail = new Date().toLocaleDateString('pt-BR');
      EvaluationPartialDetailService.updateEvaluationPartialDetail(evaluationPartialDetailList).then((responseEvaluationPartialDetail) => {
        if (responseEvaluationPartialDetail instanceof Error) {
          enqueueSnackbar('Erro ao tentar marcar avaliação parcial!', { variant:'error'});
          setValueRating(tempValue);
        } else {
          setEvaluationPartialDetailList(evaluationPartialDetailList);
        }
      });
    }
    else {
      if(evaluationPartialDetailList && evaluationPartialDetailList.objective_detail?.id_objective_detail) {
        const newEvaluationPartialDetail: IEvaluationPartialDetail = {
          id_evaluation_partial_detail: '',
          id_evaluation_partial: evaluationPartialDetailList.id_evaluation_partial,
          id_objective: evaluationPartialDetailList.id_objective,
          id_objective_detail: evaluationPartialDetailList.objective_detail?.id_objective_detail,
          status_evaluation_partial_detail: '1',
          value_evaluation_partial_detail: newValueRating.toString(),
          fragility_evaluation_partial_detail: '0',
          text_evaluation_partial_detail: '',
          date_evaluation_partial_detail: new Date().toLocaleDateString('pt-BR')
        };  
        
        const idEvaluationPartialDetail = await EvaluationPartialDetailService.createEvaluationPartialDetail(newEvaluationPartialDetail);
        if (idEvaluationPartialDetail instanceof Error) {
          enqueueSnackbar('Erro ao tentar marcar avaliação parcial!', { variant:'error'});
          setValueRating(tempValue);
        } else {
          newEvaluationPartialDetail.id_evaluation_partial_detail = idEvaluationPartialDetail.toString(),
          setEvaluationPartialDetailList(newEvaluationPartialDetail);
          if(evaluationPartialDetail){
              evaluationPartialDetail.id_evaluation_partial_detail = idEvaluationPartialDetail.toString(),
              evaluationPartialDetail.value_evaluation_partial_detail = newEvaluationPartialDetail.value_evaluation_partial_detail;
              evaluationPartialDetail.status_evaluation_partial_detail = newEvaluationPartialDetail.status_evaluation_partial_detail;
              evaluationPartialDetail.fragility_evaluation_partial_detail = newEvaluationPartialDetail.fragility_evaluation_partial_detail;
              evaluationPartialDetail.text_evaluation_partial_detail = newEvaluationPartialDetail.text_evaluation_partial_detail;
              evaluationPartialDetail.date_evaluation_partial_detail = newEvaluationPartialDetail.date_evaluation_partial_detail;
          }
        }
      }
    }
  }; 

  const Ratinglabels: { [index: string]: string } = {
    1: 'Não Percebido',
    2: 'Pouco Percebido',
    3: 'Percebido',
    4: 'Muito Percebido',
    5: 'Excedeu as Expectativas',
  };

  const getLabelText = (value: number) => {
    return `${value} Star${value !== 1 ? 's' : ''}, ${Ratinglabels[value]}`;
  };

  return (
    <>
      {evaluationPartialDetail && (
      <>
       <ListItem>
          <ListItemText primary={evaluationPartialDetail.objective_detail?.text_objective_detail} secondary={'Nota Inicial: ' + evaluationPartialDetail.objective_detail?.value_objective_detail }/>
          <Hidden smDown>
            <ListItemAvatar>
              <Box style={{ display: 'flex', justifyContent:'center', width:'158px'}}>
                <Rating 
                  name={'value_' + evaluationPartialDetail.objective_detail?.id_objective_detail}
                  defaultValue={0} 
                  value={valueRating}
                  getLabelText={getLabelText}
                  onChange={(event, newValue) => {
                    if(newValue)
                    handleClickRating(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHoverRating(newHover);
                  }}
                  readOnly={((typeEvaluationPartial === '1' || statusEvaluationPartial === '2' || statusEvaluationFinal == '1')? true : false)}
                />
              </Box>
              <Box style={{ display: 'flex', justifyContent:'center', width:'158px'}}>
                {valueRating !== null && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {(
                      (Ratinglabels[hoverRating !== -1 ? hoverRating : valueRating]) ?
                      (Ratinglabels[hoverRating !== -1 ? hoverRating : valueRating]) :
                      (<>&nbsp;</>)
                    )}
                  </Typography>
                )}
              </Box>
            </ListItemAvatar>
          </Hidden>
        </ListItem>
        <Hidden smUp>
          <Box style={{marginBottom:'10px', marginLeft:'10px'}}>
            <Box style={{ display: 'flex', justifyContent:'left', width:'158px'}}>
                <Rating 
                  name={'value_' + evaluationPartialDetail.objective_detail?.id_objective_detail}
                  defaultValue={0} 
                  value={valueRating}
                  getLabelText={getLabelText}
                  onChange={(event, newValue) => {
                    if(newValue)
                    handleClickRating(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHoverRating(newHover);
                  }}
                  readOnly={((typeEvaluationPartial === '1' || statusEvaluationPartial === '2' || statusEvaluationFinal == '1')? true : false)}
                />
              </Box>
              <Box style={{ display: 'flex', justifyContent:'left', width:'158px', marginLeft:'5px'}}>
                {valueRating !== null && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {(
                      (Ratinglabels[hoverRating !== -1 ? hoverRating : valueRating]) ?
                      (Ratinglabels[hoverRating !== -1 ? hoverRating : valueRating]) :
                      (<>&nbsp;</>)
                    )}
                  </Typography>
                )}
              </Box>
            </Box>
          </Hidden>
        <Divider variant="inset" component="li" style={{marginLeft:'-10px'}} />
      </>
      )}
      </>
  );
};