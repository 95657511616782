

import React, { useState } from 'react';
import { Paper, Typography, Avatar, Link, Box } from '@mui/material';
import { IFile }from '../../contexts/FilesContext/types';

interface IfileCardMediaProps {
    file: IFile;
}  
 
export const EASFileCard: React.FC<IfileCardMediaProps> = ({file}) => {

    const [elevation, setElevation] = useState(1);
        return (
        <Link
            href={'http://online.sbdc.com.br/media/files/' + file.path_material} 
            target="_blank"
            underline="none"
            onMouseEnter={() => setElevation(3)}
            onMouseLeave={() => setElevation(1)}
        >
            <Paper
                sx={{
                    height: 220,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    overflow:'hidden', 
                    textOverflow:'ellipsis',
                }}
                elevation={elevation}
            >
                <Box sx={{padding:'1em'}}>
                    <Box display="flex" flexDirection="column" alignItems="center" sx={{padding:'.3em'}}>
                        <Avatar
                            alt={file.name_material}
                            variant="rounded"
                            sx={{
                                bgcolor : ( file?.ext_material == 'PDF' 
                                            ? '#B50706' 
                                            : ( file?.ext_material == 'DOC' || file?.ext_material == 'DOCX' 
                                                ? '#2E5A97' 
                                                : ( file?.ext_material == 'XLS' || file?.ext_material == 'XLSX' 
                                                    ? '#008000' 
                                                    : ( file?.ext_material == 'PPT' || file?.ext_material == 'PPTX' 
                                                        ? '#D2491B' 
                                                        : '#847979'
                                                        )
                                                    )
                                                )
                                            ),
                                '& img': { objectFit: 'contain' },
                                width: 65, 
                                height: 55
                            }}
                        >{file.ext_material}</Avatar>
                        <Box textAlign="center" marginTop={1}>
                            <Typography variant="subtitle2" sx={{maxHeight: '84px', wordBreak:'break-word', overflow:'hidden', textOverflow:'ellipsis',}}>
                                {file.name_material}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-around" width="100%">
                        <Box display="flex" alignItems="center" textAlign="center">
                            <div>
                                <Typography variant="caption" color="textSecondary" sx={{fontSize:'10px'}}>
                                {file.name_material_tag}
                                </Typography>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Link>
    );
};