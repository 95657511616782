
import React, { useState , useEffect } from 'react';
import { Divider, Slider, Avatar, ListItemAvatar,  Box, ListItem, ListItemText } from '@mui/material/';

import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

import { useSnackbar } from 'notistack';

interface IEASToolsProximityMapItemProps {
  influencer: IInfluencer;
  index: number;
  updateInfluencer:(Influencer : IInfluencer) => void;
}  

export const EASToolsProximityMapItem: React.FC<IEASToolsProximityMapItemProps> = ({ influencer, index, updateInfluencer }) => {

  const { enqueueSnackbar } = useSnackbar();

  const [itemInfluencer, setItemInfluencer] = useState<IInfluencer>();
  const [valueSider, setValueSider] = useState<number>(1);
  const [loading, setLoading] = useState(true);

  const colorOrderInfluencer = ['', 
        '#37A2DA',  '#32C5E9',  '#67E0E3',  '#9FE6B8',  '#FFDB5C',  '#ff9f7f',
        '#fb7293',  '#E062AE',  '#E690D1',  '#e7bcf3',  '#9d96f5',  '#8378EA',
        '#96BFFF',  '#3366E6',  '#991AFF',  '#B34D4D',  '#4D8000',  '#c23531',
        '#2f4554',  '#61a0a8',  '#d48265',  '#91c7ae',  '#749f83',  '#ca8622',
        '#bda29a',  '#6e7074',  '#546570',  '#c4ccd3',  '#759aa0',  '#e69d87',
        '#8dc1a9',  '#ea7e53',  '#eedd78',  '#73a373',  '#73b9bc',  '#7289ab',
        '#91ca8c',  '#f49f42'];

  useEffect(() => {
    async function loadItemInfluencer() {
      if(influencer){
        setItemInfluencer(influencer);
        if(influencer.distance_influencer){
          setValueSider(parseFloat(influencer.distance_influencer));
        }
        setLoading(false);
      }
    }
    loadItemInfluencer();
  }, []);

  const handleClickEditDistanceChart = (newValue?:number | number[]) => {
    if (typeof newValue === 'number' && itemInfluencer) {
      setItemInfluencer({distance_influencer: newValue.toString(),});   
    }
  };

  const handleClickEditDistanceStore = (newValue?:number | number[]) => {
    if (typeof newValue === 'number' && influencer) {
      if(newValue.toString() != influencer.distance_influencer) {
        const Newinfluencer: IInfluencer = {
          id_influencer: influencer.id_influencer,
          id_inscricao: influencer.id_inscricao,
          id_equipe: influencer.id_equipe,
          id_mentoria_tean: influencer.id_mentoria_tean,
          name_influencer: influencer.name_influencer,
          order_influencer: influencer.order_influencer,
          positive_influencer: influencer.positive_influencer,
          negative_influencer: influencer.negative_influencer,
          group_influencer: influencer.group_influencer,
          distance_influencer: newValue.toString()
        };   
        InfluencersService.updateInfluencers(Newinfluencer)
        .then((influencerResponse) => {
          if (influencerResponse instanceof Error) {
            enqueueSnackbar('Erro ao salvar distanciamento do Influenciador.', { variant:'error'});
          } 
          else{
           updateInfluencer(Newinfluencer);
          }
        }).catch(() => {
          enqueueSnackbar('Erro ao atualizar Influenciador.', { variant:'error'});
        });
      }
    } 
  };

  return (
    <>
      {!loading && (
        <>            
          <ListItem
            sx={{padding: '10px 5px'}}
            key={influencer.id_influencer}
            secondaryAction={
              <Box style={{ display: 'flex', justifyContent:'center', width:'160px'}}>                 
                <Slider
                    aria-label="Distância"
                    defaultValue={valueSider}
                    valueLabelDisplay="auto"
                    step={0.5}
                    min={0}
                    max={10}
                    sx={{mt:1, mr:2}}
                    onChange={(event, value) => handleClickEditDistanceChart(value)}
                    onChangeCommitted={(event, value) => handleClickEditDistanceStore(value)}
                />
              </Box>
            }
          >
              <ListItemAvatar>
                <Avatar sx={{backgroundColor: colorOrderInfluencer[index+ 1]}}>{ (index+10).toString(36).toUpperCase()}</Avatar>
              </ListItemAvatar>
              <ListItemText  primary={influencer.name_influencer} />
          </ListItem>
          <Divider  component="li" />
        </>
      )}
    </>
  );
};