import React from 'react';
import {Grid, Typography, Box } from '@mui/material/';


interface IPanelWelcomeProps {
  children?: () => void;
}  


export const EASPanelWelcome: React.FC<IPanelWelcomeProps> = () => {

  //const { themeName } = useAppThemeContext();
  //const navigate = useNavigate();


  return (  <Grid container >
              {/* 
              <Grid item xs={12} sm={2} sx={{ textAlign:{ xs: 'center', sm: 'left'} }} >
                <Box component="img" 
                    src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
                    sx={{ mx: 'auto', my: { xs: 'auto', sm: 5 }, width:'100%', maxWidth:'250px' }}
                  />
              </Grid>
              */}
              <Grid item xs={12} sm={10}>
                <Box sx={{ textAlign:{ xs: 'center', sm: 'left'},  mx: { xs: 2, sm: 4 }, my: { xs: 2, sm: 4 }}}>
                  <Typography sx={{ fontWeight: 800,}} variant="h4">
                    Bem-Vindo!
                  </Typography> 
                </Box>
              </Grid>
            </Grid>
          );
};