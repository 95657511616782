import { useState, useEffect } from 'react';
import { Grid, CircularProgress, Box, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Theme, useMediaQuery }from '@mui/material';

import { useSnackbar } from 'notistack';

import * as yup from 'yup';

import { IObjectiveDetail } from '../../contexts/ObjectiveContext/types';
import { ObjectiveDetailService } from '../../services/api/objective-detail/ObjectiveDetailService';

interface IObjectiveDetailEditEditProps {
  objectiveDetail: IObjectiveDetail | null;
  finishEditObjectiveDetail:(ObjectiveDetail : IObjectiveDetail) => void;
  cancelEditObjectiveDetail:() => void;
}

export const EASObjectivesDetailEdit: React.FC<IObjectiveDetailEditEditProps> = ({ objectiveDetail, finishEditObjectiveDetail, cancelEditObjectiveDetail }) => {
  

  
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [editObjectiveDetail, setEditObjectiveDetail] = useState<IObjectiveDetail>();

  const [textEditObjectiveDetail, setTextEditObjectiveDetail] = useState<string>('');
  const [valueEditObjectiveDetail, setValueEditObjectiveDetail] = useState<string>('');
  
  const [textEditObjectiveDetailError, setTextEditObjectiveDetailError] = useState<string>('');
  const [valueEditObjectiveDetailError, setValueEditObjectiveDetailError] = useState<string>('');

  const objectiveDetailEditSchema = yup.object().shape({
    textEditObjectiveDetail: yup.string().required('Detalhe uma Excelência'),
    valueEditObjectiveDetail: yup.string().required('Defina sua Nota.'),
  });

  useEffect(() => {
    function loadEditObjectiveDetail() {
      if(objectiveDetail){
        setEditObjectiveDetail(objectiveDetail);
        if(objectiveDetail.text_objective_detail)
          setTextEditObjectiveDetail(objectiveDetail.text_objective_detail);
        if(objectiveDetail.value_objective_detail)
          setValueEditObjectiveDetail(objectiveDetail.value_objective_detail);
        setOpenDialog(true);
      }
      
    }
    loadEditObjectiveDetail();
  }, [objectiveDetail]);

  const dialogSave = () => {
    if (!loading) {
    objectiveDetailEditSchema
      .validate({ textEditObjectiveDetail, valueEditObjectiveDetail }, { abortEarly: false })
      .then(validateEditValues => {
        if(editObjectiveDetail) {
          setSuccess(false);
          setLoading(true);
          const objectiveDetail: IObjectiveDetail = {
            id_objective_detail: editObjectiveDetail.id_objective_detail,
            id_objective: editObjectiveDetail.id_objective,
            status_objective_detail: editObjectiveDetail.status_objective_detail,
            text_objective_detail: validateEditValues.textEditObjectiveDetail,
            value_objective_detail: validateEditValues.valueEditObjectiveDetail,
          };   
          ObjectiveDetailService.updateObjectiveDetail(objectiveDetail)
          .then((objectiveDetailResponse) => {
            if (objectiveDetailResponse instanceof Error) {
              setLoading(false);
              setSuccess(false);
              enqueueSnackbar('Erro ao atualizar tarefa.', { variant:'error'});
            } else {
              setLoading(false);
              setSuccess(true);
              enqueueSnackbar('Tarefa atualizada com sucesso!', { variant:'success'});
              setOpenDialog(false);
              finishEditObjectiveDetail(objectiveDetail);
              //if(allObjectiveDetails){
              //  const newAllObjectiveDetails = allObjectiveDetails.filter((objectiveDetail) => objectiveDetail.id_task_plan !== editObjectiveDetail.id_task_plan);
               // setAllObjectiveDetails(newAllObjectiveDetails);
              //} 
            }
          }).catch((error) => {
            //alert(objectiveDetail);
            setLoading(false);
            setSuccess(false);
            enqueueSnackbar('Erro ao atualizar tarefa.', { variant:'error'});
          });
        }
        else{
          setLoading(false);
          setSuccess(false);
          enqueueSnackbar('Erro ao atualizar tarefa.', { variant:'error'});
        }
      })
      .catch((errors: yup.ValidationError) => {
        setLoading(false);
        //enqueueSnackbar('Erro ao cadastrar tarefa.', { variant:'error'});
        errors.inner.forEach(error => {
          if (error.path === 'textObjectiveDetail') {
            setTextEditObjectiveDetailError(error.message);
          } else if (error.path === 'dateObjectiveDetail') {
            setValueEditObjectiveDetailError(error.message);
          }
        });
      });
    }
  };

  function cancelEdit()
  {
    cancelEditObjectiveDetail();
    setOpenDialog(false);
  }


  return (
    <Dialog 
      open={openDialog}
      keepMounted
      onClose={() => cancelEdit()}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>{'Editar Detalhamento de Competência'}</DialogTitle>
      <DialogContent>
        {loading && (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress variant='indeterminate' />
        </Box>
        )}
        {!loading && (
        <Box style={{ justifyContent: 'center', padding:'8px 0 0 8px' }}>
          <Grid sx={{width:'100%'}} container spacing={2}>
            <Grid item xs={12} md={10} lg={10}>
              <TextField
                label={!xsDown && !smDown && !mdDown ? ('Escreva aqui o que uma pessoa excelente nesta competência faria'):('Comportamento ')}
                multiline
                value={textEditObjectiveDetail}
                onChange={(event) => setTextEditObjectiveDetail(event.target.value)}
                onFocus={() => setTextEditObjectiveDetailError('')}
                fullWidth
                error={!!textEditObjectiveDetailError}
                helperText={textEditObjectiveDetailError}
              />    
            </Grid>  
            <Grid item xs={12} md={2} lg={2}>
              <TextField 
                label={!xsDown && !smDown && !mdDown ? ('Sua nota') : ('Nota')}
                type='number' 
                value={valueEditObjectiveDetail} 
                onChange={(event) => setValueEditObjectiveDetail(event.target.value)}  
                error={!!valueEditObjectiveDetailError}
                helperText={valueEditObjectiveDetailError}
                sx={{  mx: 'auto', width:'100%' }}

              />  
            </Grid>
          </Grid>
        </Box> 
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} variant="contained">Cancelar</Button>
        <Button onClick={dialogSave} variant="contained" autoFocus>Salvar</Button>
      </DialogActions>
    </Dialog>
  );
};
