import React, { useState, useEffect } from 'react';
import {Box, Autocomplete, TextField, Fab, CircularProgress } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { green } from '@mui/material/colors';

import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import { EASCard } from '../eas-card/EASCard';
import { IObjective, IObjectiveSample } from '../../contexts/ObjectiveContext/types';
import { ObjectiveService } from '../../services/api/objective/ObjectiveService';
import { useCourseContext , useTeamContext} from '../../contexts';
import { EASObjectivesNewSample } from './EASObjectivesNewSample';

import { Environment } from '../../environment';


  


interface IObjectivesNewProps {
  addObjective:(Objective : IObjective) => void;
}
export const EASObjectivesNew: React.FC<IObjectivesNewProps> = ({ addObjective }) => {


  const { enqueueSnackbar } = useSnackbar();

  const authorization =  localStorage.getItem(Environment.PUBLIC_TOKEN);

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [textObjective, setTextObjective] = useState<string>('');
  
  const [textObjectiveError, setTextObjectiveError] = useState<string>('');

  const [objectiveSampleList, setObjectiveSampleList] = useState<Array<IObjectiveSample>>([]);
  const [objectiveSample, setObjectiveSample] = useState<IObjectiveSample>();

  const [objectiveList, setObjectiveList] = useState<Array<string>>([]);
  
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };
  
  const ObjectiveSchema = yup.object().shape({
    textObjective: yup.string().required('Defina um Objetivo'),
  });

  useEffect(() => {
    async function getObjectivesSample() {
      const responseObjectiveSampleList = await ObjectiveService.getObjectivesSample(enrollment.id_evento);
      
      if (responseObjectiveSampleList instanceof Error) {
        setObjectiveSampleList([]);
        setObjectiveList([]);
      } else {
        if(responseObjectiveSampleList){
          const arrayObjectiveSamplesList = JSON.parse(JSON.stringify(responseObjectiveSampleList));
          setObjectiveSampleList(arrayObjectiveSamplesList.data);
          setObjectiveList(arrayObjectiveSamplesList.list);       
        }
        else{
          setObjectiveSampleList([]);
          setObjectiveList([]);
        }
      }
    }
    getObjectivesSample();
  }, []);

  const handleButtonClick = () => {
    if (!loading) {
      ObjectiveSchema
      .validate({ textObjective }, { abortEarly: false })
      .then(validateValues => {
        setSuccess(false);
        setLoading(true);
        if(objectiveSampleList.findIndex(i => i.name_objective_sample === validateValues.textObjective) >= 0) {
          setObjectiveSample(objectiveSampleList[objectiveSampleList.findIndex(i => i.name_objective_sample === validateValues.textObjective)]);
        }
        else {
          const objective: IObjective = {
            id_objective: '',
            id_inscricao: (team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao),
            id_equipe: team.id_equipe,
            id_mentoria_tean: team.id_mentoria_tean,
            status_objective: '0',
            text_objective: validateValues.textObjective,
            date_objective: new Date().toLocaleDateString('pt-BR'),
            sample_objective: '',
          }; 
          saveObjective(objective);
        }
      })
      .catch((errors: yup.ValidationError) => {
        setLoading(false);
        errors.inner.forEach(error => {
          if (error.path === 'textObjective') {
            setTextObjectiveError(error.message);
          } 
        });
      });
    }
  };

  const saveObjective = ( objective: IObjective | boolean) => {
    if(objective === false ){
      //setSuccess(false);
      setLoading(false);
    }
    else if(objective !== true) {
      ObjectiveService.createObjective(objective)
      .then((objectiveResponse) => {
        if (objectiveResponse instanceof Error) {
          setLoading(false);
          setSuccess(false);
          enqueueSnackbar('Erro ao cadastrar competência. E1 - ' + objectiveResponse, { variant:'error'});
        } else {
          setLoading(false);
          setSuccess(true);
          enqueueSnackbar('Competência cadastrada com sucesso!', { variant:'success'});
          addObjective(objective);
          setTextObjective(''); 
          setTextObjectiveError('');
          setSuccess(false);
        }
      }).catch((objective) => {
        setLoading(false);
        setSuccess(false);
        enqueueSnackbar('Erro ao cadastrar competência. E2 - ' + objective, { variant:'error'});
      });

    }
  };



  return (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <EASObjectivesNewSample objectiveSample={objectiveSample} saveObjectiveSample={(e) => saveObjective(e)} />
      <EASCard>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Autocomplete 
            sx={{width: '100%'}}
            options={objectiveList}
            onChange={(event, value) => {
                if (typeof value === 'string') {
                  setTextObjective(value);
                }
              }
            }
            value={textObjective}
            renderInput = {(params) => <TextField {...params} 
                                                    label='Objetivo/Competência'
                                                    value = {textObjective}
                                                    onChange={(event) => setTextObjective(event.target.value)}
                                                    helperText={textObjectiveError}
                                                    error={!!textObjectiveError}
                                                    />}
            
            
            onFocus={() => setTextObjectiveError('')}
            freeSolo
          />         
          <Box sx={{ m: 1, position: 'relative' }}>
            <Fab
              size="small"
              aria-label="save"
              color="primary"
              sx={buttonSx}
              onClick={handleButtonClick}
            >
              {success ? <CheckIcon /> : <AddIcon />}
            </Fab>
            {loading && (
              <CircularProgress
                size={48}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: -4,
                  left: -4,
                  zIndex: 1,
                }}
              />
            )}
          </Box>
        </Box>
      </EASCard>
    </Box>
  );
};