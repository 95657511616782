import { LayoutBaseDePagina } from '../../shared/layouts';
import { EASInfluencers } from '../../shared/components';


export const Influencers = () => {

  return (
    <LayoutBaseDePagina
      titulo='Influenciadores e Rótulos'
      iconTitle='hail'
    >
      <EASInfluencers />
    </LayoutBaseDePagina>
  );
};
