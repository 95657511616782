import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Button, Divider, List, ListItem, ListItemText, ListItemIcon, Theme, useMediaQuery} from '@mui/material/';

import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

import { useAppThemeContext } from '../../contexts';
import { ITip } from '../../contexts/TipsContext/types';

interface IPanelTipsProps {
  Tips?: Array<ITip>;
  children?: () => void;
}  


export const EASPanelTips: React.FC<IPanelTipsProps> = ({Tips}) => {

  const { themeName } = useAppThemeContext();

  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  
  const navigate = useNavigate();


  return (  <Box sx={{ display:'flex', backgroundColor: (themeName === 'light'   ? '#fae5da' : '#000000'), borderRadius:'10px 10px 10px 10px', p:'15px',mb:2 }}>

              <Box sx={{ textAlign:{ xs: 'center', sm: 'left'},  mx: { xs: 2, sm: 4 }, my: { xs: 2, sm: 4 }}}>
                <Typography sx={{ fontWeight: 500,}} variant="h5">
                  Algumas Dicas!
                </Typography> 
                <List sx={{ width: '100%'}}>
                  { Tips && ( Tips.map((Tip, index) => (
                  <Box key={index}>
                    <ListItem alignItems="flex-start">
                      {!xsDown && !smDown && !mdDown && (
                      <ListItemIcon>
                        <TipsAndUpdatesIcon />
                      </ListItemIcon>
                      )}
                      <ListItemText
                        primary={Tip.text_tips}                            
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </Box>
                  )))}
                </List>

{/*
                      <Grid key={index} item xs={12} sm={12} md={4} lg={3} sx={{width:'100%', display:{ sm: (index>5 ? 'none' : 'block'), md:(index>5 ? 'none' : 'block'), lg:'block'}}}>
                        <Typography variant="subtitle1" sx={{mt:'15px'}}>
                          {Tip.text_tips}
                        </Typography>
                      <Divider variant="inset" component="li" />
                      </Grid>*/}
                      <Button sx={{mt:'30px', verticalAlign:'bottom'}} variant="contained" color="primary" onClick={() => navigate('/dicas')}>Conheça mais dicas</Button>
                    </Box>

            </Box>
          );
};