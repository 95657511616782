
import React, { useState , useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import {Box, LinearProgress, Typography, Container, Alert, List, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle  } from '@mui/material/';

import { EASToolsInfluencersListAdd } from './EASToolsInfluencersListAdd';
import { EASToolsInfluencersListEdit } from './EASToolsInfluencersListEdit';
import { EASToolsInfluencersListItem } from './EASToolsInfluencersListItem';

import { useTeamContext, useCourseContext } from '../../contexts';

import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

import { useSnackbar } from 'notistack';
import { EASCard } from '../eas-card/EASCard';
import { array } from 'yargs';

interface IColumnItem {
  id?: string;
  list?: Array<IInfluencer>;
}  

interface IColumnGroup extends IColumnItem {
  Influenciadores: IColumnItem;
}  

export const EASToolsInfluencersList: React.FC = () => {

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [update, setUpdate] = useState<boolean>(true);
  
  const [allInfluencers, setAllInfluencers] = useState<Array<IInfluencer> | null>(null);
  const [totalCount, setTotalCount] = useState(0);


  const [open, setOpen] = useState(false);
  const [idInfluencer, setIdInfluencer] = useState<string | null>(null);
  const [influencer, setInfluencer] = useState<IInfluencer | null>(null);
  const [column, setColumn] = useState<IColumnItem>({id:'Influenciadores',list: [],});

  useEffect(() => {
    async function getInfluencers() {
      if(enrollment.id_inscricao){
        setIsLoading(true);
        const influencers = await InfluencersService.getAllInfluencers((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), team.id_equipe, team.id_mentoria_tean);
        if (influencers instanceof Error) {
          setAllInfluencers(null);
          setTotalCount(0);
          setIsLoading(false);
        } else {
          if(influencers.data && influencers.total){
            const myInfluencers:Array<IInfluencer> = JSON.parse(JSON.stringify(influencers.data));
            setAllInfluencers(myInfluencers);
            setIsLoading(false);
            //if(influencers.total)
              setTotalCount(influencers.total);
             /*let a = 3;
              myInfluencers.map(influencerMap => {
                if (influencerMap.group_influencer == '0'|| influencerMap.group_influencer == undefined || influencerMap.group_influencer == null ) {
                  a = 0;
                } 
              });   */

              const newColunm : IColumnItem ={id:'Influenciadores',list: [],};
              if(myInfluencers){
                myInfluencers.map(influencer => {
                    newColunm.list?.push(influencer);
                }); 
              }
              setColumn(newColunm);
              setLoading(false);
          }
          else{
            setAllInfluencers(null);
            setTotalCount(0);
            setIsLoading(false);
          }
        }
      }
    }
    getInfluencers();
  }, [team.id_equipe, update]);

  const handleClickCancelEdit = () => {
      setInfluencer(null);
  };

  const handleClickDelete  = (id_influencer?:string) => {
    if(id_influencer){
      setIdInfluencer(id_influencer);
      setOpen(true);
    }
  }; 

  const handleConfirme = () => {
    deleteInfluencer();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setIdInfluencer(null);
  };
  
  const deleteInfluencer = () => {

    if(idInfluencer){
      InfluencersService.deleteInfluencers(idInfluencer).then((influencer) => {
        if (influencer instanceof Error) {
          setIdInfluencer(null);
          setInfluencer(null);
          enqueueSnackbar('Erro ao tentar remover o Influenciador!', { variant:'error'});
        } else {
          if(idInfluencer && column && column.list){
            const newList = column.list.filter(
              (_: any, idx: number) => _.id_influencer !== idInfluencer
            );
            //newList.splice((influencerUpdate.order_influencer ? parseInt(influencerUpdate.order_influencer)-1 : 0), 0, influencerUpdate);
      
            const newCol = {
              id: column.id,
              list: newList
            };
            setColumn(newCol);
            if(newList.length < 1){
              setUpdate(!update);
            }
            enqueueSnackbar('Influenciador removido com sucesso!', { variant:'success'});
            setIdInfluencer(null);  
          }

 
        }
      });
    }
  };


  const uploadOrder = ( influencer:IInfluencer, newOrder:number) => {


    //alert(newOrder +1 );
    //alert(influencer.order_influencer);
    if(influencer && Number.isInteger(newOrder)) {

      const moveInfluencer: IInfluencer = {
        id_influencer: influencer.id_influencer,
        id_inscricao: influencer.id_inscricao,
        id_equipe: influencer.id_equipe,
        id_mentoria_tean: influencer.id_mentoria_tean,
        order_influencer: (newOrder+1).toString(),
        distance_influencer: influencer.order_influencer,
      };    
      InfluencersService.moveInfluencer(moveInfluencer)
        .then((influencerResponse) => {
          if (influencerResponse instanceof Error) {
            enqueueSnackbar('Erro ao mover influenciador.', { variant:'error'});
          } 
          else {
            /*
            if(column.list && column.list?.length>0){
              const newAllInfluencers = column.list.map((influencer, index) => {
                influencer.order_influencer = (index +1).toString();
                return influencer;
              });
              setAllInfluencers(newAllInfluencers);
            }*/
          }
        }).catch(() => {
          enqueueSnackbar('Erro ao mover influenciador.', { variant:'error'});
      });
    }
    else{
      enqueueSnackbar('Erro ao mover influenciador.', { variant:'error'});
    }
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;
    if (source === undefined || source === null) return null;
    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    ) return null;

    const start = (column as any);   
    const end = (column as any);
  
    uploadOrder(start.list[source.index], destination.index );

    const newList = start.list.filter(
      (_: IInfluencer, idx: number) => idx !== source.index
    );

    newList.splice(destination.index, 0, start.list[source.index]);

    if(newList){
      const finalNewList = newList.map((influencer:IInfluencer, index:number) => {
        influencer.order_influencer = (index +1).toString();
        return influencer;
      });

      const newCol = {
        id: start.id,
        list: finalNewList
      };
      setAllInfluencers(finalNewList);
      setColumn(newCol);


    }

    return null;

  };

  const addInfluencer = (newInfluencer:IInfluencer) => {
    if(newInfluencer){
      
      const newAllInfluencers = allInfluencers;
      newInfluencer.order_influencer = column.list?.length ? (column.list?.length + 1 ).toString() : '1';
      newAllInfluencers?.push(newInfluencer);
      setAllInfluencers(newAllInfluencers);

      if(column.list) {

        const newList = column.list;
        newList.push(newInfluencer);
        //newList.splice((influencerUpdate.order_influencer ? parseInt(influencerUpdate.order_influencer)-1 : 0), 0, influencerUpdate);
  
        const newCol = {
          id: column.id,
          list: newList
        };
        setColumn(newCol);
        if(newList.length == 1){
          setUpdate(!update);
        }
         
      }
    }
  };

  const updateAllInfluencers = (influencerUpdate:IInfluencer) => {
    if(allInfluencers){

      const newAllInfluencers = allInfluencers.map(influencer => {
        if (influencerUpdate.id_influencer !== influencer.id_influencer) {
          return influencer;
        } else {
          return influencerUpdate;
        }
      });
      setAllInfluencers(newAllInfluencers);
    }

    if(influencerUpdate.order_influencer && column && column.list){
      const newList = column.list.filter(
        (_: any, idx: number) => _.id_influencer !== influencerUpdate.id_influencer
      );
      newList.splice((influencerUpdate.order_influencer ? parseInt(influencerUpdate.order_influencer)-1 : column.list.length), 0, influencerUpdate);

      const newCol = {
        id: column.id,
        list: newList
      };
      setColumn(newCol);
    }

  };


  const updateInfluencer = (influencerUpdate?:IInfluencer) => {
    if (influencerUpdate && allInfluencers) {
      const newAllInfluencers = allInfluencers.map(influencer => {
        if (influencerUpdate.id_influencer !== influencer.id_influencer) {
          return influencer;
        } else {
          return influencerUpdate;
        }
      });
      setAllInfluencers(newAllInfluencers);
    }
  };



  return (
    <Container>
      {isLoading && (
        <LinearProgress variant='indeterminate' sx={{ mt:'15px'}} />
      )}
      <Box sx={{display:(isLoading ? 'none': 'block')}}>
        <EASCard>
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" sx={{textAlign: 'center', marginBottom:'5px'}}>
              Quais são as pessoas que exercem maior influência sobre a sua reputação na organização? Classifique em ordem de importância.
            </Typography>
          </Box>
          {(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' ) ? (
          <Box sx={{ p: 3 }}>
            <Typography variant="h5">Este programa foi encerrado</Typography>
            {((team.type_tean === '0')  && (
              <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
            ))}
          </Box>
          ):(
          <Box sx={{ p: 3 }}>
            <EASToolsInfluencersListAdd addInfluencer={(e) => addInfluencer(e)} />
          </Box>
          )}
          <EASToolsInfluencersListEdit finishEditInfluencer={(e) => updateAllInfluencers(e)} cancelEditInfluencer={() => handleClickCancelEdit()} influencer={influencer}  />
          <List>
            <DragDropContext onDragEnd={onDragEnd}>
              {!loading && column.list && column.list.length > 0 && column.id ?  (
              <Droppable droppableId={column.id}>
                {provided => (
                  <div  {...provided.droppableProps} ref={provided.innerRef}>
                  {column.list && column.list.length > 0 && (column.list.map((influencer, index) => (
                    <EASToolsInfluencersListItem 
                      index={index}
                      key={influencer.id_influencer} 
                      influencer={influencer} 
                      setInfluencer={(e) => setInfluencer(e)} 
                      handleClickDelete={(e) => handleClickDelete(e)}
                      updateInfluencer={(e) => updateInfluencer(e)}
                    />
                  )))}

                  {provided.placeholder}
                  </div>
                )}
              </Droppable>
              ): (
              <Alert severity="warning">Nenhum influenciador cadastrado!</Alert>
              )}
            </DragDropContext>
          </List>
          <Box display="flex" flexDirection="column" alignItems="center" sx={{pt:'35px'}}>
            <Button variant="contained" onClick={() => navigate('/ferramentas-auxiliares')}>Concluir</Button>
          </Box>
          <Dialog
              open={open}
              onClose={handleCancel}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle>
              {'Excluir Influenciador'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Você tem certeza que deseja excluir este Influenciador?
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{mr:2, mb:1}}>
              <Button onClick={handleCancel} variant="contained">Desistir</Button>
              <Button onClick={handleConfirme}  variant="contained" autoFocus>
                Sim, Excluir
              </Button>
            </DialogActions>
          </Dialog>
        </EASCard>
      </Box>
    </Container>
  );
};