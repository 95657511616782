import React, { useState, useEffect } from 'react';
import {TextField, Theme, useMediaQuery} from '@mui/material/';
import { useSnackbar } from 'notistack';

import { IEvaluationPartialComment } from '../../contexts/EvaluationPartialContext/types';
import { EvaluationPartialCommentService } from '../../services/api/evaluation-partial-comment/EvaluationPartialCommentService';

interface IEvaluationPartialEditTextProps {
  disabled: boolean;
  evaluationPartialComment?: IEvaluationPartialComment;
  children?: () => void;
}  

export const EASEvaluationPartialEditText: React.FC<IEvaluationPartialEditTextProps> = ( {disabled, evaluationPartialComment}) => {

  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [evaluationPartialCommentEdit,  setEvaluationPartialCommentEdit] = useState<IEvaluationPartialComment | null>(null);
  const [textEvaluationPartialCommentEdit, setTextEvaluationPartialCommentEdit] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();

  
  useEffect(() => {
    function loadTextEvaluationPartialComment() {
      if(evaluationPartialComment){
        setEvaluationPartialCommentEdit(evaluationPartialComment);
        if(evaluationPartialComment.text_evaluation_partial_comment)
          setTextEvaluationPartialCommentEdit(evaluationPartialComment.text_evaluation_partial_comment);
      }
    }
    loadTextEvaluationPartialComment();
  }, [evaluationPartialComment]);

  const storeTextEvaluationPartialComment = async () => {
    if(evaluationPartialCommentEdit){
      if(evaluationPartialCommentEdit.id_evaluation_partial_comment){
        evaluationPartialCommentEdit.text_evaluation_partial_comment = textEvaluationPartialCommentEdit;
        EvaluationPartialCommentService.updateEvaluationPartialComment(evaluationPartialCommentEdit).then((responseEvaluationPartialComment) => {
          if (responseEvaluationPartialComment instanceof Error) {
            enqueueSnackbar('Erro ao salvar comentário', { variant:'error'});
          } else {
            setEvaluationPartialCommentEdit(evaluationPartialCommentEdit);
          }
        });
      }
      else {
        if(evaluationPartialCommentEdit.id_evaluation_partial && evaluationPartialCommentEdit.id_objective) {
          const newEvaluationPartialComment: IEvaluationPartialComment = {
            id_evaluation_partial_comment: '',
            id_evaluation_partial: evaluationPartialCommentEdit.id_evaluation_partial,
            id_objective: evaluationPartialCommentEdit.id_objective,
            status_evaluation_partial_comment: '1',
            text_evaluation_partial_comment: textEvaluationPartialCommentEdit,
          };  
          
          const idEvaluationPartialComment = await EvaluationPartialCommentService.createEvaluationPartialComment(newEvaluationPartialComment);
          if (idEvaluationPartialComment instanceof Error) {
            enqueueSnackbar('Erro ao tentar marcar avaliação parcial!', { variant:'error'});
          } else {
            newEvaluationPartialComment.id_evaluation_partial_comment = idEvaluationPartialComment.toString(),
            setEvaluationPartialCommentEdit(newEvaluationPartialComment);
            if(evaluationPartialComment){
                evaluationPartialComment.id_evaluation_partial_comment = idEvaluationPartialComment.toString(),
                evaluationPartialComment.id_evaluation_partial = newEvaluationPartialComment.id_evaluation_partial;
                evaluationPartialComment.id_objective = newEvaluationPartialComment.id_objective;
                evaluationPartialComment.status_evaluation_partial_comment = newEvaluationPartialComment.status_evaluation_partial_comment;
                evaluationPartialComment.text_evaluation_partial_comment = newEvaluationPartialComment.text_evaluation_partial_comment;
            }
          }
        }
      }
    }
  };

  return (
    <TextField
      id={'text_comment_' + ((evaluationPartialComment && evaluationPartialComment.id_objective) ? evaluationPartialComment.id_objective  : '' )}
      label={!xsDown && !smDown && !mdDown ? ('Adicione um comentário sobre está competência.'):('Comentário sobre está competência.')}
      multiline
      fullWidth
      disabled={disabled}
      rows={4}
      sx={{my:2}}
      value={(disabled && !textEvaluationPartialCommentEdit)? 'Nenhum comentário adicionado' : textEvaluationPartialCommentEdit}
      onChange={(event) => setTextEvaluationPartialCommentEdit(event.target.value)}
      onBlur={storeTextEvaluationPartialComment}
    />
  );
};