import { useState, useEffect } from 'react';
import { CircularProgress, Box, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button }from '@mui/material';

import { useSnackbar } from 'notistack';

import * as yup from 'yup';

import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

interface IToolsInfluencersListEditProps {
  influencer: IInfluencer | null;
  finishEditInfluencer:(Influencer : IInfluencer) => void;
  cancelEditInfluencer:() => void;
}

export const EASToolsInfluencersListEdit: React.FC<IToolsInfluencersListEditProps> = ({ influencer, finishEditInfluencer, cancelEditInfluencer }) => {
  
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [editInfluencer, setEditInfluencer] = useState<IInfluencer>();
  const [textEditInfluencer, setTextEditInfluencer] = useState<string>('');
  const [textInfluencerError, setTextInfluencerError] = useState<string>('');

  const EditInfluencerSchema = yup.object().shape({
    textEditInfluencer: yup.string().required('Informe o nome do Influenciador.'),
  });

  useEffect(() => {
    function loadEditInfluencer() {
      if(influencer){
        setEditInfluencer(influencer);
        if(influencer.name_influencer)
          setTextEditInfluencer(influencer.name_influencer);
        setOpenDialog(true);
      }
    }
    loadEditInfluencer();
  }, [influencer]);


  const dialogSave = () => {
    if (!loading) {
      EditInfluencerSchema
      .validate({ textEditInfluencer }, { abortEarly: false })
      .then(validateEditValues => {
        if(editInfluencer) {
          setLoading(true);
          const influencer: IInfluencer = {
            id_influencer: editInfluencer.id_influencer,
            id_inscricao: editInfluencer.id_inscricao,
            id_equipe: editInfluencer.id_equipe,
            id_mentoria_tean: editInfluencer.id_mentoria_tean,
            name_influencer: validateEditValues.textEditInfluencer,
            order_influencer: editInfluencer.order_influencer,
            positive_influencer: editInfluencer.positive_influencer,
            negative_influencer: editInfluencer.negative_influencer,
            group_influencer: editInfluencer.group_influencer,
            distance_influencer: editInfluencer.distance_influencer
          };   
          InfluencersService.updateInfluencers(influencer)
          .then((influencerResponse) => {
            if (influencerResponse instanceof Error) {
              setLoading(false);
              enqueueSnackbar('Erro ao atualizar Influenciador.', { variant:'error'});
            } else {
              setLoading(false);
              enqueueSnackbar('Nome do Influenciador atualizado com sucesso!', { variant:'success'});
              setOpenDialog(false);
              finishEditInfluencer(influencer);
            }
          }).catch(() => {
            setLoading(false);
            enqueueSnackbar('Erro ao atualizar Influenciador.', { variant:'error'});
          });
        }
        else{
          setLoading(false);
          enqueueSnackbar('Erro ao atualizar Influenciador.', { variant:'error'});
        }
      })
      .catch((errors: yup.ValidationError) => {
        setLoading(false);
        //enqueueSnackbar('Erro ao cadastrar tarefa.', { variant:'error'});
        errors.inner.forEach(error => {
          if (error.path === 'textInfluencer') {
            setTextInfluencerError(error.message);
          } 
        });
      });
    }
  };

  function cancelEdit()
  {
    cancelEditInfluencer();
    setOpenDialog(false);
  }

  return (
    <Dialog 
      open={openDialog}
      keepMounted
      onClose={() => cancelEdit()}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>{'Editar Influenciador'}</DialogTitle>
      <DialogContent>
        {loading && (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress variant='indeterminate' />
        </Box>
        )}
        {!loading && (
        <Box style={{ display: 'flex', justifyContent: 'center', padding:'8px 0 0 8px' }}>
          <TextField
            label="Influenciador"
            variant="outlined"
            value={textEditInfluencer}
            onChange={(event) => setTextEditInfluencer(event.target.value)}
            onFocus={() => setTextInfluencerError('')}
            fullWidth
            error={!!textInfluencerError}
            helperText={textInfluencerError}
          />
        </Box> 
        )}
      </DialogContent>
      <DialogActions sx={{mr:2, mb:1}}>
        <Button onClick={() => cancelEdit()} variant="contained">Cancelar</Button>
        <Button onClick={dialogSave} variant="contained" autoFocus >Salvar</Button>
      </DialogActions>
    </Dialog>
  );
};