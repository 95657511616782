
import React, { useState , useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { IInfluencer } from '../../contexts/InfluencersContext/types';

interface IColorDataChart {
  color?: string;
}
interface IDataChart extends IColorDataChart {
  name?: string;
  value?: Array<number | null>;
  symbolSize?: number;
  itemStyle?: IColorDataChart;
  label?: {show?: boolean; position?: string; color?: string; formatter?: string}
}

interface IIndicator {
  max: number;
}

interface IEASInfluencersListChartProps {
  allInfluencers: Array<IInfluencer> | null;
}  

export const EASInfluencersListChart: React.FC<IEASInfluencersListChartProps> = ({ allInfluencers }) => {

  const [dataChart, setDataChart] = useState<Array<IDataChart>>([]);
  
  const [indicator, setIndicator] = useState<Array<IIndicator>>([]);

  const colorOrderInfluencer = ['', 
        '#37A2DA',  '#32C5E9',  '#67E0E3',  '#9FE6B8',  '#FFDB5C',  '#ff9f7f',
        '#fb7293',  '#E062AE',  '#E690D1',  '#e7bcf3',  '#9d96f5',  '#8378EA',
        '#96BFFF',  '#3366E6',  '#991AFF',  '#B34D4D',  '#4D8000',  '#c23531',
        '#2f4554',  '#61a0a8',  '#d48265',  '#91c7ae',  '#749f83',  '#ca8622',
        '#bda29a',  '#6e7074',  '#546570',  '#c4ccd3',  '#759aa0',  '#e69d87',
        '#8dc1a9',  '#ea7e53',  '#eedd78',  '#73a373',  '#73b9bc',  '#7289ab',
        '#91ca8c',  '#f49f42'];

  useEffect(() => {
    async function getInfluencers() {

      const tempIndicator = Array<IIndicator>(0);
      for (let i = 0; i < 24; i++) 
        tempIndicator.push({max: 10});


      setIndicator(tempIndicator);
      if(allInfluencers){
        
        const chart = Array(0);
        const nums = [0,12,6,18,22,2,14,10,4,16,8, 20,1,3,5,7,9,11,13,15,17,19];

        allInfluencers.map((influencer) => {
          const values = [];
          const value = influencer.distance_influencer && parseFloat(influencer.distance_influencer) >1 ? parseFloat(influencer.distance_influencer) : 1.8;

          for (let i = 0; i < 24; i++) {
            
            if(nums[chart.length] == i)
                values.push(value);
            else
              values.push(0);
          }

          const orderInfluencer = influencer.order_influencer ? influencer.order_influencer : '0';

          const colorChart: IColorDataChart = {
            color : colorOrderInfluencer[parseInt(orderInfluencer)],
          };

          const newDataChart: IDataChart = {
            name : influencer.name_influencer,
            value: values,
            itemStyle:colorChart,
            label:{show:true, position: 'inside', color:'#fff', formatter: orderInfluencer}
          };
          chart.push(newDataChart);
        });

        const newDataChart: IDataChart = {
          name :'Você',
          value: [0],
          symbolSize:30,
          label:{show:true, position: 'inside', color:'red', formatter: '███'},
          itemStyle: {
            color:'red',
          },
        };
      chart.push(newDataChart);
      setDataChart(chart);
      }
    }
    getInfluencers();
  }, [allInfluencers]);

  const option = {

    title: {
      text: 'Mapa de Proximidade',
      top: 0,
      left:'center'
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a}<br/><strong>{b}</strong>'
    },
    radar: {
      indicator: indicator,
      radius: '90%',
      center: ['50%', '55%'],
      startAngle: 90,
      splitNumber: 5,
      shape: 'circle',
      axisLine: [],
      axisLabel: [],
    },
    series: [
      {
        name: 'Influenciadores e você',
        type: 'radar',
        symbolSize:25,
        data: dataChart,
        lineStyle:
          {
          width: 0,
          }
      }
    ]
  };

  return (
    <ReactEcharts style={{width:'100%', height:'100%', minHeight:'350px'}}
        option={{
            ...option,
        }}
    />
  );
};