
import React, { useState , useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import {Box, LinearProgress, Grid, Typography, Container, Alert, List, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle  } from '@mui/material/';


import { EASToolsProximityMapItem } from './EASToolsProximityMapItem';
import { EASToolsProximityMapChart } from './EASToolsProximityMapChart';
import { useTeamContext, useCourseContext } from '../../contexts';

import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

import { useSnackbar } from 'notistack';
import { EASCard } from '../eas-card/EASCard';
import { array } from 'yargs';

interface IColumnItem {
  id?: string;
  list?: Array<IInfluencer>;
}  

interface IColumnGroup extends IColumnItem {
  Influenciadores: IColumnItem;
}  

export const EASToolsProximityMap: React.FC = () => {

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [messageInfluencers, setMessageInfluencers] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const [allInfluencers, setAllInfluencers] = useState<Array<IInfluencer> | null>(null);
  const [totalCount, setTotalCount] = useState(0);


  const [open, setOpen] = useState(false);
  const [idInfluencer, setIdInfluencer] = useState<string | null>(null);
  const [influencer, setInfluencer] = useState<IInfluencer | null>(null);
  const [collumn, setColumn] = useState<IColumnItem>({id:'Influenciadores',list: [],});

  useEffect(() => {
    async function getInfluencers() {
      if(enrollment.id_inscricao){
        setIsLoading(true);
        const influencers = await InfluencersService.getAllInfluencers((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), team.id_equipe, team.id_mentoria_tean);
        if (influencers instanceof Error) {
          setAllInfluencers(null);
          setTotalCount(0);
          setIsLoading(false);
        } else {
          if(influencers.data && influencers.total){
            const myInfluencers:Array<IInfluencer> = JSON.parse(JSON.stringify(influencers.data));
            setAllInfluencers(myInfluencers);
            setIsLoading(false);
            if(influencers.total)
              setTotalCount(influencers.total);
              /*
              let a = 3;
              myInfluencers.map(influencerMap => {
                if (influencerMap.group_influencer == '0'|| influencerMap.group_influencer == undefined || influencerMap.group_influencer == null ) {
                  a = 0;
                } 
              });   
*/
              const newColunm : IColumnItem ={id:'Influenciadores',list: [],};
              if(myInfluencers){
                myInfluencers.map(influencer => {
                    newColunm.list?.push(influencer);
                }); 
              }
              setColumn(newColunm);
          }
          else{
            setMessageInfluencers(true);
            setAllInfluencers(null);
            setTotalCount(0);
            setIsLoading(false);
          }
        }
      }
    }
    getInfluencers();
  }, [team.id_equipe]);

  const handleClickCancelEdit = () => {
      setInfluencer(null);
  };

  const handleClickDelete  = (id_influencer?:string) => {
    if(id_influencer){
      setIdInfluencer(id_influencer);
      setOpen(true);
    }
  }; 

  const handleConfirme = () => {
    deleteInfluencer();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setIdInfluencer(null);
  };
  
  const deleteInfluencer = () => {

    if(idInfluencer){
      InfluencersService.deleteInfluencers(idInfluencer).then((influencer) => {
        if (influencer instanceof Error) {
          setIdInfluencer(null);
          setInfluencer(null);
          enqueueSnackbar('Erro ao tentar remover o Influenciador!', { variant:'error'});
        } else {

          if(idInfluencer && collumn && collumn.list){
            const newList = collumn.list.filter(
              (_: any, idx: number) => _.id_influencer !== idInfluencer
            );
            //newList.splice((influencerUpdate.order_influencer ? parseInt(influencerUpdate.order_influencer)-1 : 0), 0, influencerUpdate);
      
            const newCol = {
              id: collumn.id,
              list: newList
            };
            setColumn(newCol);
          }

          enqueueSnackbar('Influenciador removido com sucesso!', { variant:'success'});
          setIdInfluencer(null);   
        }
      });
    }
  };


  const uploadOrder = ( influencer:IInfluencer, newOrder:number) => {

    if(influencer && newOrder) {
    
      const moveInfluencer: IInfluencer = {
        id_influencer: influencer.id_influencer,
        id_inscricao: influencer.id_inscricao,
        id_equipe: influencer.id_equipe,
        id_mentoria_tean: influencer.id_mentoria_tean,
        order_influencer: newOrder.toString(),
        distance_influencer: influencer.order_influencer,
      };    
      InfluencersService.moveInfluencer(moveInfluencer)
        .then((influencerResponse) => {
          if (influencerResponse instanceof Error) {
            enqueueSnackbar('Erro ao mover influenciador.', { variant:'error'});
          } 
          else {
            influencer.order_influencer = newOrder.toString();
            updateInfluencer(influencer);
          }
        }).catch(() => {
          enqueueSnackbar('Erro ao mover influenciador.', { variant:'error'});
      });
    }
    else{
      enqueueSnackbar('Erro ao mover influenciador.', { variant:'error'});
    }
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;
    if (source === undefined || source === null) return null;
    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    ) return null;

    const start = (collumn as any);   
    const end = (collumn as any);
  
    uploadOrder(start.list[source.index], destination.index );

    const newList = start.list.filter(
      (_: any, idx: number) => idx !== source.index
    );

    newList.splice(destination.index, 0, start.list[source.index]);

    const newCol = {
      id: start.id,
      list: newList
    };

    setColumn(newCol);
    //setColumn(state => ({ ...state, [newCol.id]: newCol }));
    return null;

  };

  const addInfluencer = (newInfluencer:IInfluencer) => {
    if(newInfluencer){
      
      const newAllInfluencers = allInfluencers;
      newInfluencer.order_influencer = collumn.list?.length ? (collumn.list?.length + 1 ).toString() : '1';
      newAllInfluencers?.push(newInfluencer);
      setAllInfluencers(newAllInfluencers);

      if(collumn.list) {

        const newList = collumn.list;
        newList.push(newInfluencer);
        //newList.splice((influencerUpdate.order_influencer ? parseInt(influencerUpdate.order_influencer)-1 : 0), 0, influencerUpdate);
  
        const newCol = {
          id: collumn.id,
          list: newList
        };
        setColumn(newCol);
      }
    }
  };

  const updateAllInfluencers = (influencerUpdate:IInfluencer) => {
    if(allInfluencers){

      const newAllInfluencers = allInfluencers.map(influencer => {
        if (influencerUpdate.id_influencer !== influencer.id_influencer) {
          return influencer;
        } else {
          return influencerUpdate;
        }
      });
      setAllInfluencers(newAllInfluencers);
    }

    if(influencerUpdate.order_influencer && collumn && collumn.list){
      const newList = collumn.list.filter(
        (_: any, idx: number) => _.id_influencer !== influencerUpdate.id_influencer
      );
      newList.splice((influencerUpdate.order_influencer ? parseInt(influencerUpdate.order_influencer)-1 : collumn.list.length), 0, influencerUpdate);

      const newCol = {
        id: collumn.id,
        list: newList
      };
      setColumn(newCol);
    }

  };


  const updateInfluencer = (influencerUpdate?:IInfluencer) => {
    if (influencerUpdate && allInfluencers) {
      const newAllInfluencers = allInfluencers.map(influencer => {
        if (influencerUpdate.id_influencer !== influencer.id_influencer) {
          return influencer;
        } else {
          return influencerUpdate;
        }
      });
      setAllInfluencers(newAllInfluencers);
    }
  };


  return (
    <Container>
      {isLoading && (
        <LinearProgress variant='indeterminate' sx={{ mt:'15px'}} />
      )}
      {!isLoading && (
      <Box>
        <EASCard>
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" sx={{textAlign: 'center', marginBottom:'5px'}}>
              Defina a proximidade que você tem de cada influenciador, sendo 10 para muito próximo e 0 para muito distante.
            </Typography>
          </Box>
          {(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' ) && (
          <Box sx={{ p: 3 }}>
            <Typography variant="h5">Este programa foi encerrado</Typography>
            {((team.type_tean === '0')  && (
              <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
            ))}
          </Box>
          )}
          <Grid container alignItems="center" spacing={2} sx={{marginTop:'-10px', width:'100%'}}>
            <Grid item xs={12} sm={12}  md={12}  lg={6} xl={6} >
              <List>
                {!isLoading ?  (
                  <>
                    {allInfluencers && allInfluencers.length > 0 && (allInfluencers.map((influencer, index) => (
                      <EASToolsProximityMapItem 
                        key={influencer.id_influencer} 
                        influencer={influencer} 
                        index={index}
                        updateInfluencer={(e) => updateInfluencer(e)}
                      />
                    )))}
                  </>
                ):(
                <Alert severity="warning">Nenhum influenciador cadastrado!</Alert>
                )}
              </List>
            </Grid>
            <Grid item xs={12} sm={12}  md={12}  lg={6} xl={6} sx={{height: '100%'}}>
              <EASToolsProximityMapChart allInfluencers={allInfluencers} />
            </Grid>
          </Grid>
          <Box display="flex" flexDirection="column" alignItems="center" sx={{pt:'35px'}}>
            <Button variant="contained" onClick={() => navigate('/ferramentas-auxiliares')}>Concluir</Button>
          </Box>
          <Dialog
              open={open}
              onClose={handleCancel}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle>
              {'Excluir Influenciador'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Você tem certeza que deseja excluir este Influenciador?
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{mr:2, mb:1}}>
              <Button onClick={handleCancel} variant="contained">Desistir</Button>
              <Button onClick={handleConfirme}  variant="contained" autoFocus>
                Sim, Excluir
              </Button>
            </DialogActions>
          </Dialog>
        </EASCard>
      </Box>
      )}
      <Dialog
        maxWidth="xs"
        open={messageInfluencers}
      >
      <DialogTitle>Nenhum Influenciador Cadastrado</DialogTitle>
      <DialogContent>
        <Typography>
            Para que você possa utilizar esta ferramenta, é necessário primeiro cadastrar os influenciadores.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => navigate('/ferramentas-auxiliares/influenciadores-de-reputacao')}>Ir para o cadastro de influenciadores</Button>
      </DialogActions>
    </Dialog>
    </Container>
  );
};