import React from 'react';
import { Stack, Box } from '@mui/material';
import { IVideo} from '../../contexts/VideosContext/types';
import { EASVideoCard } from './EASVideoCard';

interface IVideosColumProps {
  direction?: 'column' | 'row';
  videos?: Array<IVideo> | null;
  clickToNavigate:(urlNavigator : string) => void;
}  

export const EASVideosColum: React.FC<IVideosColumProps> = ({ videos, direction, clickToNavigate}) => {

  return (
    <Stack direction={direction || 'row'} flexWrap="wrap" justifyContent="start" alignItems="start" gap={2}>
      {(videos && videos.length>0 && (videos.map((video, index) => (
        <Box key={index}>
          {video.id && <EASVideoCard video={video} clickToNavigate={(e) => clickToNavigate(e)} /> }
        </Box>
      ))))}
    </Stack>
  );
};