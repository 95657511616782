import { LayoutBaseDePagina } from '../../shared/layouts';
import { EASTips } from '../../shared/components';

export const Tips = () => {
    return (
        <LayoutBaseDePagina
        titulo='&nbsp;Dicas'
        iconTitle='tips_and_updates'
        >
            <EASTips />
        </LayoutBaseDePagina>
        );
};