import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IEvaluationPartialDetail, IEvaluationPartialDetailResponse, IEvaluationPartial, IUserEvaluationPartialExternal,  } from '../../../contexts/EvaluationPartialContext/types';

interface IEvaluationPartialDetailListResponse extends IEvaluationPartialDetail {
  id_evaluation_partial?: string;
  type_evaluation_partial?: string;
  date_evaluation_partial?: string;
  text_evaluation_partial?: string;
  status_evaluation_partial?: string;
  user?: IUserEvaluationPartialExternal;
  total?: number | null;
  data?: IEvaluationPartialDetailResponse | null;
}


const updateEvaluationPartial = async (evaluationPartial: IEvaluationPartial): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/evaluationpartialexternal/${evaluationPartial.id_evaluation_partial}`, evaluationPartial);
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const getAllEvaluationPartialExternal = async (token = '' ): Promise<IEvaluationPartialDetailListResponse | Error> => {
  try {
    if(!token)
      return new Error('Requisição imcompleta.');

    
    const urlRelativa = `/evaluationpartialexternal?public_token=${Environment.PUBLIC_TOKEN}&t=${token}`;

    const  {data} = await Api.get(urlRelativa);
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

export const EvaluationPartialExternalService = {
  getAllEvaluationPartialExternal,
  updateEvaluationPartial,
};
