import { useState, useRef, useEffect } from 'react';
import * as yup from 'yup';

import { Box, TextField, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Button, Theme, useMediaQuery } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { green } from '@mui/material/colors';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { EASCard } from '../eas-card/EASCard';
import { useLearningContext, useTeamContext, useCourseContext } from '../../contexts';
import { ILearning } from '../../contexts/LearningContext/types';
import { LearningService } from '../../services/api/learning/LearningService';
import { VariantType, useSnackbar } from 'notistack';

interface ILearningNewProps {
  learning: ILearning | null;
  finishEditLearning:(Learning : ILearning | null) => void;
}
export const EASLearningEdit: React.FC<ILearningNewProps> = ({ learning,  finishEditLearning }) => {


  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [editLearning, setEditLearning] = useState<ILearning | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [textLearning, setTextLearning] = useState<string>('');
  const [dateLearning, setDateLearning] = useState<string>('');
  const [valueLearning, setValueLearning] = useState<string>('');

  const [lackLearning, setLackLearning] = useState<string>('');
  const [causeLearning, setCauseLearning] = useState<string>('');
  const [planLearning, setPlanLearning] = useState<string>('');
  
  const [textLearningError, setTextLearningError] = useState<string>('');
  const [dateLearningError, setDateLearningError] = useState<string>('');
  const [valueLearningError, setValueLearningError] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const { ...team } = useTeamContext();
  const { ...enrollment } = useCourseContext();
  
  
  useEffect(() => {
    function loadEditLearning() {
      if(learning){
        setEditLearning(learning);
        if(learning.date_learning)
          setDateLearning(new Date(learning.date_learning+'T23:28:56.782Z').toString());
        if(learning.value_learning)
          setValueLearning(learning.value_learning);
        if(learning.text_learning)
          setTextLearning(learning.text_learning);
        if(learning.lack_learning)
          setLackLearning(learning.lack_learning);
        if(learning.cause_learning)
          setCauseLearning(learning.cause_learning);
        if(learning.plan_learning)
          setPlanLearning(learning.plan_learning);
        setOpenDialog(true);
      }
      
    }
    loadEditLearning();
  }, [learning]);

  const learningSchema = yup.object().shape({
    valueLearning: yup.string().required('Informe uma Nota'),
    dateLearning: yup.string().required('Informe uma Data'),
    textLearning: yup.string().required('Descreva suas ações')
  });

  const handleButtonCancel = () => {
    finishEditLearning(null);
    setOpenDialog(false);
   // closeLearning();
  };

  const handleButtonSave = () => {
    if (!loading) {
    learningSchema
      .validate({ valueLearning, textLearning, dateLearning }, { abortEarly: false })
      .then(validateValues => {
        if(editLearning) {
          setLoading(true);
          const learning: ILearning = {
            id_learning: editLearning.id_learning,
            id_inscricao: editLearning.id_inscricao,
            id_equipe: editLearning.id_equipe,
            id_mentoria_tean: editLearning.id_mentoria_tean,
            unread_learning: editLearning.unread_learning,
            status_learning: '1',
            date_learning: new Date(validateValues.dateLearning).toLocaleDateString('pt-BR'),
            value_learning: validateValues.valueLearning,
            text_learning: validateValues.textLearning,
            lack_learning: lackLearning ? lackLearning : '',
            cause_learning: causeLearning ? causeLearning : '',
            plan_learning: planLearning ? planLearning : '', 
          };   
          LearningService.updateLearning(learning)
            .then((learningResponse) => {
              if (learningResponse instanceof Error) {
                setLoading(false);
                enqueueSnackbar('Erro ao atualizar item no diário de aprendizagem.', { variant:'error'});
              } else {
                setLoading(false);
                enqueueSnackbar('Item do diário de aprendizagem atualizar com sucesso!', { variant:'success'});
                setDateLearning('');
                setValueLearning(''); 
                setTextLearning(''); 
                setDateLearningError('');
                setValueLearningError('');
                setTextLearningError('');
                setLackLearning(''); 
                setCauseLearning(''); 
                setPlanLearning(''); 
                finishEditLearning(learning);
                setOpenDialog(false);
              }
            }).catch((learningResponse) => {
            setLoading(false);
            enqueueSnackbar('Erro ao atualizae item no diário de aprendizagem.', { variant:'error'});
          });
        }
      })
      .catch((errors: yup.ValidationError) => {
        setLoading(false);
        errors.inner.forEach(error => {
          if (error.path === 'textLearning') {
            setTextLearningError(error.message);
          } else if (error.path === 'dateLearning') {
            setDateLearningError(error.message);
          }
          else if (error.path === 'valueLearning') {
            setValueLearningError(error.message);
          }
        });
      });
    }
  };

  return (

    <Dialog 
      open={openDialog}
      keepMounted
      onClose={handleButtonCancel}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="lg"
    >
    <DialogTitle>{'Novo Item do Diário de Aprendizagem'}</DialogTitle>
    <DialogContent>
      {loading && (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress variant='indeterminate' />
        </Box>
      )}
      {!loading && (  
      <>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { my: 1},
          }}
          noValidate
          autoComplete="off"
        >
          <LocalizationProvider  adapterLocale={brLocale} dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Data"
              value={ dateLearning === '' ? null : dateLearning} 
              onChange={(newValue) => {
                if(newValue){
                  setDateLearning(newValue);
                }
              }}
              renderInput={(params) => <TextField onFocus={() => setDateLearningError('')}
                                                  value={dateLearning} 
                                                  helperText={dateLearningError}
                                                  {...params}
                                                  error={!!dateLearningError} />}
            />

          </LocalizationProvider>  
          
          <TextField 
            label="Nota"
            type='number' 
            value={valueLearning} 
            onChange={(event) => setValueLearning(event.target.value)}  
            onFocus={() => setValueLearningError('')}
            error={!!valueLearningError}
            helperText={valueLearningError}
            sx={{width:'20%', minWidth:'100px'}}
          />  
        </Box>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { my: 1},
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label={!xsDown && !smDown && !mdDown ? ('O que você fez de positivo que justifica esta nota?'):('O que você fez para esta nota?')}
            multiline
            value={textLearning}
            onChange={(event) => setTextLearning(event.target.value)}
            onFocus={() => setTextLearningError('')}
            fullWidth
            error={!!textLearningError}
            helperText={textLearningError}
            rows={3}
          />
        </Box>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { my: 1},
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label={!xsDown && !smDown && !mdDown ? ('O que faltou para que sua nota fosse dez?'):('O que faltou para a nota dez?')}
            multiline
            value={lackLearning}
            onChange={(event) => setLackLearning(event.target.value)}
            fullWidth
            rows={2}
          />
        </Box>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { my: 1},
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="Causas"
            multiline
            value={causeLearning}
            onChange={(event) => setCauseLearning(event.target.value)}
            fullWidth
            rows={2}
          />
        </Box>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { my: 1},
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="Plano de Melhoria"
            multiline
            value={planLearning}
            onChange={(event) => setPlanLearning(event.target.value)}
            fullWidth
            rows={2}
          />
        </Box> 
      </> 
      )}
    </DialogContent>
    <DialogActions sx={{mr:2, mb:1}}>
        <Button onClick={handleButtonCancel} variant="contained">Cancelar</Button>
        <Button onClick={handleButtonSave} variant="contained" autoFocus>Salvar</Button>
      </DialogActions>
    </Dialog> 
  );
};
