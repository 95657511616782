
import React, { useState, useEffect } from 'react';
import {Box, Typography, Container } from '@mui/material';

import { useTeamContext, useCourseContext } from '../../contexts';
import { EASInfluencersGoupsContainer } from './EASInfluencersGoupsContainer';

import { IInfluencer } from '../../contexts/InfluencersContext/types';

interface IColumnItem {
  id?: string;
  list?: Array<IInfluencer>;
}  

interface IColumnGroup extends IColumnItem {
  Influenciadores: IColumnItem;
  Promotores: IColumnItem;
  Neutros: IColumnItem;
  Detratores: IColumnItem;
}  

interface IEASInfluencersGoupsProps {
  allInfluencers: Array<IInfluencer> | null;
  setAllInfluencers:(allInfluencers : Array<IInfluencer>) => void;
  updateList:() => void;
}  

export const EASInfluencersGoups: React.FC<IEASInfluencersGoupsProps> = ({ allInfluencers, setAllInfluencers, updateList }) => {

  const [influencer, setInfluencer] = useState<IInfluencer | null>(null);
  //const [teste, setTeste] = useState<Array<string>>([]);
  const [columns, setColumns] = useState<IColumnGroup>();
  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();

  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    async function getGroupInfluencers() {
      //setColumns();
      const newColuns : IColumnGroup = {Influenciadores: {id: 'Influenciadores',list: [],}, Promotores: {id: 'Promotores',list: [],},Neutros: {id: 'Neutros',list: [],},Detratores: {id:'Detratores',list: [],}};
      if(allInfluencers){
        allInfluencers.map(influencer => {
          if(influencer.name_influencer)
            switch (influencer.group_influencer) {
              case '1':
                newColuns.Promotores.list?.push(influencer);
                break;
              case '2':
                newColuns.Neutros.list?.push(influencer);
                break;
              case '3':
                newColuns.Detratores.list?.push(influencer);
                break;
              default:
                newColuns.Influenciadores.list?.push(influencer);
                break;
            } 
        }); 
      }
      setColumns(newColuns);
      setLoading(false);
    }
    getGroupInfluencers();
  }, [team.id_equipe]);

  const updateInfluencer = (influencerUpdate?:IInfluencer) => {
    if (influencerUpdate && allInfluencers) {
      const newAllInfluencers = allInfluencers.map(influencer => {
        if (influencerUpdate.id_influencer !== influencer.id_influencer) {
          return influencer;
        } else {
          return influencerUpdate;
        }
      });
      setAllInfluencers(newAllInfluencers);
    }
  };

  return (
    <Container>
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" sx={{textAlign: 'center', marginBottom:'5px'}}>
          Considerando o conceito de NPS, como você classifica a percepção dos influenciadores a seu respeito?
        </Typography>
      </Box>
      {(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' ) ? (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5">Este programa foi encerrado</Typography>
        {((team.type_tean === '0')  && (
          <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
        ))}
      </Box>
      ):(!loading && (
      <Box sx={{ p: 3 }}>
        <EASInfluencersGoupsContainer 
          columns={columns} 
          updateInfluencer={(e) => updateInfluencer(e)} />
      </Box>
      ))}

    </Container>
  );
};