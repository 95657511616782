import { LayoutBaseDePagina } from '../../shared/layouts';
import { EASVideos } from '../../shared/components';

export const Videos = () => {

  return (
    <LayoutBaseDePagina
      titulo='&nbsp;Video'
      iconTitle='ondemand_video'
    >
      <EASVideos />
    </LayoutBaseDePagina>
  );
};
