import React, { useState } from 'react';
import {Paper, Tabs, Tab, Container, List} from '@mui/material/';

import FilePresentIcon from '@mui/icons-material/FilePresent';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import { EASVideos, EASFiles, EASWeblinks } from '../';

interface ILibraryProps {
  children?: () => void;
}  

export const EASLibrary: React.FC<ILibraryProps> = () => {

  const [filterLibrary, setFilterLibrary] = useState<number>(0);

  const handleChangeFilter = (event: React.SyntheticEvent, newValue: number) => {
    setFilterLibrary(newValue);
  };


  return (
      <Container>
        <Container>
          <Paper sx={{ marginTop: '1em', display: 'flex', justifyContent: 'center' }}>
            <Tabs value={filterLibrary} onChange={handleChangeFilter} aria-label="Selecione os items da biblioteca">
              <Tab icon={<OndemandVideoIcon />} label="Videos" />
              <Tab icon={<FilePresentIcon />} label="ARQUIVOS" />
            </Tabs>
          </Paper>
        </Container>
        <List sx={{ marginTop: '1em' }}>
          {filterLibrary == 0 ? (<EASVideos />) : (filterLibrary == 1 ? (<EASFiles />) : (<EASWeblinks />))}
        </List>
      </Container>
  );
};