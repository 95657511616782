import React from 'react';

import { LayoutBaseDePagina } from '../../shared/layouts';
import { EASObjectives } from '../../shared/components';

export const Objectives = () => {

  return (
    <LayoutBaseDePagina 
      titulo='&nbsp;Detalhamento de Objetivos' 
      iconTitle='adjust' 
    >
      <EASObjectives />
    </LayoutBaseDePagina>
  );
};

