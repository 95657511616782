import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { Chip, Popover, Typography, Avatar, Grid, Box, } from '@mui/material/';

import InfoIcon from '@mui/icons-material/Info';

interface IEASInfluencersGoupsItemProps {
  influencer: IInfluencer
  index: number
}

export const EASInfluencersGoupsItem: React.FC<IEASInfluencersGoupsItemProps> = ({ influencer, index })=> {

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const colorOrderInfluencer = ['', 
  '#37A2DA',  '#32C5E9',  '#67E0E3',  '#9FE6B8',  '#FFDB5C',  '#ff9f7f',
  '#fb7293',  '#E062AE',  '#E690D1',  '#e7bcf3',  '#9d96f5',  '#8378EA',
  '#96BFFF',  '#3366E6',  '#991AFF',  '#B34D4D',  '#4D8000',  '#c23531',
  '#2f4554',  '#61a0a8',  '#d48265',  '#91c7ae',  '#749f83',  '#ca8622',
  '#bda29a',  '#6e7074',  '#546570',  '#c4ccd3',  '#759aa0',  '#e69d87',
  '#8dc1a9',  '#ea7e53',  '#eedd78',  '#73a373',  '#73b9bc',  '#7289ab',
  '#91ca8c',  '#f49f42'];


    return (
      <Draggable draggableId={(influencer.id_influencer ? influencer.id_influencer : 'id2')} index={index}>
        {provided => (
          <>
            <Chip
              key={influencer.id_influencer}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={{backgroundColor:'#ffffff', m:1, borderRadius:2, py:2, px:1, cursor:'rand'}}
              avatar={<Avatar sx={{color:'#ffffff !important', bgcolor: colorOrderInfluencer[(influencer.order_influencer ? parseInt(influencer.order_influencer) : 0)]}}>{influencer.order_influencer}</Avatar>}
              label={influencer.name_influencer}
              variant="outlined"    
              onDelete={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              deleteIcon={<InfoIcon />}    
            />
            <Popover
            id={'popover'+influencer.id_influencer}
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
            
          >
            <Box style={{ display: 'flex', justifyContent: 'center', width:'100%', padding:'8px 0 0 8px' }}>
              <Grid container spacing={2} sx={{marginTop:'-10px', width:'400px'}}>
                <Grid item xs={12} sm={6}  md={6}  lg={6} xl={6} sx={{ p: 1 }} >
                  <Typography sx={{ pb:1,fontWeight:'600', textAlign:'center'}}>Rótulos Positivos</Typography>
                  <Typography sx={{pb:1,textAlign:'center'}} >{influencer.positive_influencer}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}  md={6}  lg={6} xl={6} sx={{ p: 1 }} >
                  <Typography sx={{pb:1,fontWeight:'600',textAlign:'center'}}>Rótulos Negativos</Typography>
                  <Typography sx={{pb:1,textAlign:'center'}} >{influencer.negative_influencer}</Typography>
                </Grid>
              </Grid>
            </Box>            
          </Popover>
          </>
        )}
      </Draggable>
    );
  };
