
export const Environment = {
  VERSION: '1.3.29',
  /**
   * Define a quantidade de linhas a ser carregada por padrão nas listagens
   */
  LIMITE_DE_LINHAS: 12,
  /**
   * Placeholder exibido nas inputs
   */
  INPUT_DE_BUSCA: 'Pesquisar...',
  /**
   * Texto exibido quando nenhum registro é encontrado em uma listagem
   */
  LISTAGEM_VAZIA: 'Nenhum registro encontrado.',

  API_URL: 'https://api.sbdc.com.br/',
  API_FOLDER: 'v1',

  PUBLIC_TOKEN:'dfcb2be4543cfbe361d0a86e67e215c0003365ae',
  CLIENT_ID:'87bb3bc7fa2ea6e830f6adc32534bab71a50a31d',
  URL_APP:'https://app-mentoring.auraexo.com/',

};
