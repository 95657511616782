import { LayoutBaseDePagina } from '../../shared/layouts';
import { EASEvaluationFinal } from '../../shared/components';

export const EvaluationFinal = () => {

  return (
    <LayoutBaseDePagina
      titulo='&nbsp;Avaliação Final'
      iconTitle='how_to_reg'
    >
      <EASEvaluationFinal />
    </LayoutBaseDePagina>
  );
};