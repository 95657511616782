
import { IUser } from './types';

export function setUserLocalStorange(user: IUser | null) {
    localStorage.setItem('lumt', JSON.stringify(user));
}

export function getUserLocalStorange() {
    const json = localStorage.getItem('lumt');
    if(!json){
        return null;
    }
    const user = JSON.parse(json);
    return user ?? null;
}




