import React from 'react';

import { IObjectives} from '../../contexts/ResultsPresentationContext/types';
import { Heading, Slide, Text, Box } from 'spectacle';

interface IPresentationFinalComparationProps {
  Key:number;
  indexKey:number;
  Objectives?:Array<IObjectives>;
  primary:string;
  background:string;
}

export const EASPresentationFinalComparation:React.FC<IPresentationFinalComparationProps> = ({ indexKey, Objectives, primary, background}) => {
  
  return (
      <Slide key={indexKey} backgroundColor={background}>
        <Heading fontWeight="400" fontSize="36px" margin="0px"  color="#000000">EVOLUÇÃO POR COMPETÊNCIA</Heading>
        {(Objectives && 
          Objectives.length) && 
            (Objectives.map((Objective, index) => (
        <div key={Objective.id_objective} > 
          <Box backgroundColor="trasnparent" 
               style={{
                  position:'absolute',
                  borderRadius:'50%',
                  top:(195 + (index*200)) + 'px',
                  left:'250px', 
                  width:'350px',
                  height:'140px',
               }}>
            <Box backgroundColor="trasnparent" 
                 style={{
                    verticalAlign:'center',
                    display:'flex',
                    alignItems: 'center',
                    justifyContent:'center', 
                    height:'100%',
                 }}>
              <Text fontWeight="700"
                    fontSize="28px"
                    textAlign="center"
                    margin="auto"
                    color={'#000000'}>
                  {Objective.text_objective && Objective.text_objective.toUpperCase()}
              </Text>
            </Box>
          </Box>

          <Box backgroundColor={primary} 
               style={{
                  position:'absolute', 
                  borderRadius:'0 25px 25px 0',
                  top:(210 + (index*200)) + 'px',
                  left: '620px',
                  width:(10 + Objective.media_value_objective*30) + 'px', 
                  height:'50px',
               }}>
          </Box>

          <Box backgroundColor="trasnparent"
               style={{
                  position:'absolute', 
                  top:(192 + (index*200)) + 'px',
                  left: '620px',
                  width:(Objective.media_value_objective*30) + 'px', 
               }}> 
            <Text fontWeight="200" 
                  fontSize="22px"
                  margin="15px 0px"
                  textAlign="center"
                  minWidth="50px"
                  color="#000000">
                {Objective.media_value_objective}
            </Text>
          </Box>
          <Box backgroundColor="trasnparent" 
               style={{
                  position:'absolute', 
                  top:(192 + (index*200)) + 'px',
                  width: '200px',
                  minWidth:'40px',
                  left:((Objective.media_value_objective*30 < 20 ? 620 : 585) + Objective.media_value_objective*30) + 'px', 
               }}> 
            <Text fontWeight="100" 
                  fontSize="18px"
                  textAlign="center"
                  color="#000000">
                Nota Inicial
            </Text>
          </Box>
          <Box backgroundColor={primary} 
               style={{
                  position:'absolute', 
                  borderRadius:'0 25px 25px 0',
                  top:(270 + (index*200)) + 'px',
                  left:'620px',
                  minWidth:'50px',
                  width:(10 + Objective.media_value_evaluation_final*30) + 'px',
                  height:'50px',
               }}>
          </Box>
          <Box backgroundColor="trasnparent" 
               style={{
                  position:'absolute', 
                  top:(252 + (index*200)) + 'px',
                  left:'620px',
                  minWidth:'40px',
                  width:(Objective.media_value_evaluation_final*30) + 'px',
               }}>
            <Text fontWeight="200" 
                  fontSize="22px"
                  margin="15px 0px"
                  textAlign="center"
                  color="#000000">
                {Objective.media_value_evaluation_final}
            </Text>
          </Box>
          <Box backgroundColor="trasnparent" 
               style={{
                  position:'absolute', 
                  top:(252 + (index*200)) + 'px',
                  left:((Objective.media_value_evaluation_final*30 < 20 ? 620 : 585) + Objective.media_value_evaluation_final*30) + 'px',
                  width:'200px',
               }}>
            <Text fontWeight="100" 
                  fontSize="18px"
                  textAlign="center"
                  color="#000000">
                Nota Final
            </Text>
          </Box>
        </div>
        )))}
      </Slide> 
  );
  
};
