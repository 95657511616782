import { ReactNode } from 'react';
import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { EASNavbar } from '../components';

interface ILayoutBaseDePaginaProps {
  titulo: string;
  iconTitle?: string;
  barraDeFerramentas?: ReactNode;
  children: React.ReactNode;
}
export const LayoutBaseDePagina: React.FC<ILayoutBaseDePaginaProps> = ({ children, iconTitle, titulo }) => {

  const theme = useTheme();

  return (
    <Box height="100%" display="flex" flexDirection="column" gap={1}>
      <Box py={1} display="flex" alignItems="center" gap={1} height={theme.spacing(6)}>
        <EASNavbar 
          titulo={titulo}
          iconTitle={iconTitle}
        />
      </Box>
      <Box flex={1} px={2} pb={2} display="flex" overflow="auto">
        {children}
      </Box>
    </Box>
  );
};
