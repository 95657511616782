import { LayoutBaseDePagina } from '../../shared/layouts';
import { EASVideoDetail } from '../../shared/components';

export const VideosDetail = () => {

  return (
    <LayoutBaseDePagina
      titulo='&nbsp;Video'
      iconTitle='ondemand_video'
    >
      <EASVideoDetail />
    </LayoutBaseDePagina>
  );
};
