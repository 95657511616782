
import React, { useState , useEffect } from 'react';
import { Divider, ListItemButton, ListItemIcon, Avatar, Box, ListItem,  ListItemText } from '@mui/material/';

import { green } from '@mui/material/colors';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

import { IInfluencer } from '../../contexts/InfluencersContext/types';

interface EASInfluencersEvaluationsItemProps {
  influencer: IInfluencer;
  updateListItem:() => void;
  setInfluencer:(Influencer : IInfluencer) => void;
  updateInfluencer:(Influencer : IInfluencer) => void;
}  

export const EASInfluencersEvaluationsItem: React.FC<EASInfluencersEvaluationsItemProps> = ({ influencer, setInfluencer, updateListItem, updateInfluencer }) => {

  const [itemInfluencer, setItemInfluencer] = useState<IInfluencer | null>(null);
  const [loading, setLoading] = useState(false);
  
    const colorOrderInfluencer = ['', 
        '#37A2DA',  '#32C5E9',  '#67E0E3',  '#9FE6B8',  '#FFDB5C',  '#ff9f7f',
        '#fb7293',  '#E062AE',  '#E690D1',  '#e7bcf3',  '#9d96f5',  '#8378EA',
        '#96BFFF',  '#3366E6',  '#991AFF',  '#B34D4D',  '#4D8000',  '#c23531',
        '#2f4554',  '#61a0a8',  '#d48265',  '#91c7ae',  '#749f83',  '#ca8622',
        '#bda29a',  '#6e7074',  '#546570',  '#c4ccd3',  '#759aa0',  '#e69d87',
        '#8dc1a9',  '#ea7e53',  '#eedd78',  '#73a373',  '#73b9bc',  '#7289ab',
        '#91ca8c',  '#f49f42'];
        
  useEffect(() => {
    async function loadItemInfluencer() {
      if(influencer){
        setItemInfluencer(influencer);
        setLoading(false);
      }
    }
    loadItemInfluencer();
  }, []);

  const handleClickEdit = (influencer?:IInfluencer) => {
    if(influencer){
      setInfluencer(influencer);
    }
  };

  return ( 
    <>
    {!loading && (
      <>
      <ListItem
        key={influencer.id_influencer}
        secondaryAction={
          <Box display="flex">
            <Avatar sx={{mr:1, bgcolor: (influencer.positive_influencer ? green[500] : '')}} ><ThumbUpIcon /></Avatar>
            <Avatar sx={{mr:1, bgcolor: (influencer.positive_influencer ? green[500] : '')}}><ThumbDownIcon /></Avatar>
          </Box>
        }
        disablePadding
      >
      <ListItemButton role={undefined} onClick={() => handleClickEdit(influencer)}  dense>
        <ListItemIcon>
        <Avatar sx={{backgroundColor: colorOrderInfluencer[(influencer.order_influencer ? parseInt(influencer.order_influencer) : 1)]}}>{ influencer.order_influencer}</Avatar>
        </ListItemIcon>
        <ListItemText  primary={influencer.name_influencer} />
      </ListItemButton>
    </ListItem>
    <Divider  component="li" />
    </>
    )}
  </>
  );
};