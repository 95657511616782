import { useState } from 'react';
import {AppBar, Box, Toolbar, Typography, IconButton, Icon, Theme, useMediaQuery, useTheme} from '@mui/material';
import { useDrawerContext } from '../../contexts';
import * as microsoftTeams from '@microsoft/teams-js';
import React from 'react';

interface IEASNavbarProps {
  titulo: string;
  textHelp?: string;
  iconTitle?: string;
}
export const EASNavbar: React.FC<IEASNavbarProps> = ({ 
  titulo, 
  textHelp, 
  iconTitle,
}) => {
  const theme = useTheme();
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  

  const [appContext, setAppContext] = useState<microsoftTeams.Context>();

  microsoftTeams.getContext((context) => {
    setAppContext(context);
  });

  const { toggleDrawerOpen } = useDrawerContext();

  return (
    <AppBar elevation={0} sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)',  color: theme.palette.text.primary,  background: theme.palette.background.paper }} position='static' >
      <Toolbar>
        {mdDown && (
          <IconButton onClick={toggleDrawerOpen} sx={{mt:'10px'}}> 
            <Icon>menu</Icon>
          </IconButton>
        )}
        {!xsDown && !smDown && !mdDown && (
          <Icon sx={{fontSize:'32px', mt:'10px'}}>{iconTitle}</Icon>
        )}    
        <Typography
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipses"
          variant={'h6'}
          sx={{ fontSize:(!mdDown  ? 'large' : 'medium' ), mt:'10px'}}
        
        >
          {titulo}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { md: 'flex' } }}>
          {/*
          <IconButton size={(xsDown || smDown ? 'small' : 'large')} aria-label="show 4 new mails"  sx={{mt:'10px'}}>
            <Badge badgeContent={4} color="primary">
              <MailIcon />
            </Badge>
          </IconButton>
          <IconButton size={(xsDown || smDown ? 'small' : 'large')} aria-label="show 17 new notifications" sx={{mt:'10px'}}>
            <Badge badgeContent={17} color="primary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
