import { useState, useEffect } from 'react';
import { CircularProgress, Box, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button }from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';

import { VariantType, useSnackbar } from 'notistack';

import * as yup from 'yup';

import { ITaskPlan } from '../../contexts/TaskPlanContext/types';
import { TaskPlanService } from '../../services/api/task-plan/TaskPlanService';

interface ITaskPlanEditProps {
  taskPlan: ITaskPlan | null;
  finishEditTaskPlan:(TaskPlan : ITaskPlan) => void;
  cancelEditTaskPlan:() => void;
}

export const EASTaskPlanEdit: React.FC<ITaskPlanEditProps> = ({ taskPlan, finishEditTaskPlan, cancelEditTaskPlan }) => {
  
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [editTaskPlan, setEditTaskPlan] = useState<ITaskPlan>();

  const [textEditTaskPlan, setTextEditTaskPlan] = useState<string>('');
  const [dateEditTaskPlan, setDateEditTaskPlan] = useState<string>('');
  
  const [textTaskPlanError, setTextTaskPlanError] = useState<string>('');
  const [dateTaskPlanError, setDateTaskPlanError] = useState<string>('');

  const taskEditPlanSchema = yup.object().shape({
    textEditTaskPlan: yup.string().required('Descreva a tarefa'),
    dateEditTaskPlan: yup.string().required('Informe uma Data')
  });

  useEffect(() => {
    function loadEditTaskPlan() {
      if(taskPlan){
        setEditTaskPlan(taskPlan);
        if(taskPlan.text_task_plan)
          setTextEditTaskPlan(taskPlan.text_task_plan);
        if(taskPlan.date_task_plan)
          setDateEditTaskPlan(new Date(taskPlan.date_task_plan+'T23:28:56.782Z').toString());
        setOpenDialog(true);
      }
      
    }
    loadEditTaskPlan();
  }, [taskPlan]);


  const dialogSave = () => {
    if (!loading) {
    taskEditPlanSchema
      .validate({ textEditTaskPlan, dateEditTaskPlan }, { abortEarly: false })
      .then(validateEditValues => {
        if(editTaskPlan) {
          setSuccess(false);
          setLoading(true);
          const taskPlan: ITaskPlan = {
            id_task_plan: editTaskPlan.id_task_plan,
            id_inscricao: editTaskPlan.id_inscricao,
            id_equipe: editTaskPlan.id_equipe,
            id_mentoria_tean: editTaskPlan.id_mentoria_tean,
            status_task_plan: editTaskPlan.status_task_plan,
            text_task_plan: validateEditValues.textEditTaskPlan,
            date_task_plan: new Date(validateEditValues.dateEditTaskPlan).toLocaleDateString('pt-BR'),
            schedule_task_plan: editTaskPlan.schedule_task_plan,
            unread_task_plan: editTaskPlan.unread_task_plan,
            register_task_plan: editTaskPlan.register_task_plan,
            presentation_task_plan: editTaskPlan.presentation_task_plan
          };   
          TaskPlanService.updateTaskPlan(taskPlan)
          .then((taskPlanResponse) => {
            if (taskPlanResponse instanceof Error) {
              setLoading(false);
              setSuccess(false);
              enqueueSnackbar('Erro ao atualizar tarefa.', { variant:'error'});
            } else {
              setLoading(false);
              setSuccess(true);
              enqueueSnackbar('Tarefa atualizada com sucesso!', { variant:'success'});
              setOpenDialog(false);
              finishEditTaskPlan(taskPlan);
              //if(allTaskPlans){
              //  const newAllTaskPlans = allTaskPlans.filter((taskPlan) => taskPlan.id_task_plan !== editTaskPlan.id_task_plan);
               // setAllTaskPlans(newAllTaskPlans);
              //} 
            }
          }).catch((error) => {
            //alert(taskPlan);
            setLoading(false);
            setSuccess(false);
            enqueueSnackbar('Erro ao atualizar tarefa.', { variant:'error'});
          });
        }
        else{
          setLoading(false);
          setSuccess(false);
          enqueueSnackbar('Erro ao atualizar tarefa.', { variant:'error'});
        }
      })
      .catch((errors: yup.ValidationError) => {
        setLoading(false);
        //enqueueSnackbar('Erro ao cadastrar tarefa.', { variant:'error'});
        errors.inner.forEach(error => {
          if (error.path === 'textTaskPlan') {
            setTextTaskPlanError(error.message);
          } else if (error.path === 'dateTaskPlan') {
            setDateTaskPlanError(error.message);
          }
        });
      });
    }
  };

  function cancelEdit()
  {
    cancelEditTaskPlan();
    setOpenDialog(false);
  }

  return (
    <Dialog 
      open={openDialog}
      keepMounted
      onClose={() => cancelEdit()}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>{'Editar Tarefa'}</DialogTitle>
      <DialogContent>
        {loading && (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress variant='indeterminate' />
        </Box>
        )}
        {!loading && (
        <Box style={{ display: 'flex', justifyContent: 'center', padding:'8px 0 0 8px' }}>
          <TextField
            label="Tarefa"
            variant="outlined"
            value={textEditTaskPlan}
            onChange={(event) => setTextEditTaskPlan(event.target.value)}
            onFocus={() => setTextTaskPlanError('')}
            fullWidth
            error={!!textTaskPlanError}
            helperText={textTaskPlanError}
          />
          <LocalizationProvider  adapterLocale={brLocale} dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Executr em"
              value={ dateEditTaskPlan === '' ? null : dateEditTaskPlan} 
              onChange={(newValue) => {
                if(newValue){
                  setDateEditTaskPlan(newValue);
                }
              }}
              renderInput={(params) => <TextField onFocus={() => setDateTaskPlanError('')}
                                                  value={dateEditTaskPlan} 
                                                  helperText={dateTaskPlanError}
                                                  {...params}
                                                  error={!!dateTaskPlanError} />}
            />
          </LocalizationProvider>  
        </Box> 
        )}
      </DialogContent>
      <DialogActions sx={{mr:2, mb:1}}>
        <Button onClick={() => cancelEdit()} variant="contained">Cancelar</Button>
        <Button onClick={dialogSave} variant="contained" autoFocus >Salvar</Button>
      </DialogActions>
    </Dialog>
  );
};
