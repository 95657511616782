import React, { useState , useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import {Grid, Alert, LinearProgress, Paper, Typography } from '@mui/material/';

import { useCourseContext , useTeamContext} from '../../contexts';
import { IObjective } from '../../contexts/ObjectiveContext/types';

import { ObjectiveService } from '../../services/api/objective/ObjectiveService';

import { EASObjectivesNew } from '../eas-objectives-new/EASObjectivesNew';

import { EASObjectivesSkills } from '../eas-objectives-skills/EASObjectivesSkills';
import { EASObjectivesDetail } from '../eas-objectives-detail/EASObjectivesDetail';


interface IObjectivesProps {
  children?: () => void;
}  

export const EASObjectives: React.FC<IObjectivesProps> = () => {

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();

  const [searchParams, setSearchParams] = useSearchParams();


  const [isLoading, setIsLoading] = useState(true);
  const [updateList, setUpdateList] = useState(false);
  const [idObjective, setIdObjective] = useState<string | null>(null);
  const [allObjectives, setAllObjectives] = useState<Array<IObjective> | null>(null);
  const [objective, setObjective] = useState<IObjective | null>(null);
  const [filterObjective, setFilterObjective] = useState<number>(0);
  const [totalCount, setTotalCount] = useState(0);


  const [valueTab, setValueTab] = useState<number>(0);

  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);


  useEffect(() => {
    async function getObjectives() {
      
      if(enrollment.id_inscricao){
        setIsLoading(true);
        const objectives = await ObjectiveService.getAllObjective((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), team.id_equipe, team.id_mentoria_tean, filterObjective, pagina);
        if (objectives instanceof Error) {
          setAllObjectives(null);
          setTotalCount(0);
          setIdObjective('error');
          setObjective(null);
          setIsLoading(false);
        } else {

          if(objectives.data && objectives.total){
            const myObjectives = JSON.parse(JSON.stringify(objectives.data));
            setAllObjectives(myObjectives);
            setValueTab(myObjectives[0].id_objective);
            setIsLoading(false);
            if(objectives.total)
              setTotalCount(objectives.total);
          }
          else{
            setAllObjectives(null);
            setTotalCount(0);
            setIdObjective('error');
            setObjective(null);
            setIsLoading(false);
          }
        }
      }
    }
    getObjectives();
    
  }, [updateList, filterObjective,  team.id_equipe ]);

  const addObjective = (objective:IObjective) => {
    //const teste = filterObjective + 1 - 1;
    setUpdateList(!updateList); 
  };

  return (
    <Grid container spacing={2} sx={{marginTop:'-10px'}}>
      
      <Grid item xs={12} lg={4} xl={3} >

        {(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' ) && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography variant="h5">Este programa foi encerrado</Typography>
              {((team.type_tean === '0')  && (
                <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
              ))}
            </Paper>
        )}
        {(!(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' )) && (
        <Grid sx={{mb: 2}}>
          <EASObjectivesNew addObjective={(createdObjective) => addObjective(createdObjective) } />
        </Grid> 
        )} 
        <Grid sx={{mb: 2}}>
          {isLoading && (
            <LinearProgress variant='indeterminate' />
          )}
          {!isLoading && (allObjectives && allObjectives.length ? (
            <EASObjectivesSkills allObjectives={allObjectives} valueTab={valueTab} handleChangeTable={(e) => setValueTab(e)} />
          ) :
            <Alert severity="warning">Não há competências!</Alert>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={8} xl={9} >
        {isLoading && (
          <LinearProgress variant='indeterminate' />
        )}
        {!isLoading && (allObjectives && allObjectives.length ? (
          <EASObjectivesDetail allObjectives={allObjectives} valueTab={valueTab} removeObjective={() => setUpdateList(!updateList)}/>
        ) :
          <Alert severity="warning">Nenhuma competência foi cadastrada. Escolha uma, para começar.</Alert>
        )}
        </Grid>
    </Grid> 
  );
};