import { createContext, useCallback, useContext, useMemo, useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';
import * as microsoftTeams from '@microsoft/teams-js';
import { DarkTheme, LightTheme } from '../../themes';

interface IThemeContextData {
  themeName: 'light' | 'dark' | 'contrast';
  toggleTheme: () => void;
  emailName? : string | null;
}

const ThemeContext = createContext({} as IThemeContextData);

export const useAppThemeContext = () => {
  return useContext(ThemeContext);
};

interface IAppThemeProps {
  children: React.ReactNode;
}

export const AppThemeProvider: React.FC<IAppThemeProps> = ({ children }) => {
  const [themeName, setThemeName] = useState<'light' | 'dark' | 'contrast' >('light');
  //const [appContext, setAppContext] = useState<microsoftTeams.Context>();

  const [emailName, setEmailName] = useState<string | null>();

  const toggleTheme = useCallback(() => {
    setThemeName(oldThemeName => oldThemeName === 'light' ? 'dark' : 'light');
  }, []);

  const theme = useMemo(() => {
    if (themeName === 'light') return LightTheme;
    if (themeName === 'contrast') return DarkTheme;
    return DarkTheme;
  }, [themeName]);

  useEffect(() => {

    microsoftTeams.getContext((context) => {
      
      setThemeName(initTeamsTheme(context.theme));
      
      if(context.loginHint)
        setEmailName(context.loginHint);   
      
      microsoftTeams.appInitialization.notifySuccess();
    });
    microsoftTeams.registerOnThemeChangeHandler((theme) => {setThemeName(initTeamsTheme(theme));
    });
  }, []);

  return (
    <ThemeContext.Provider value={{ themeName, toggleTheme, emailName }}>
      <ThemeProvider theme={theme}>
        <Box width="100vw" height="100vh" bgcolor={theme.palette.background.default}>
          {children}
        </Box>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const initTeamsTheme = (theme: string | undefined) => {
  switch (theme) {
  case 'dark':
    return 'dark';
  case 'contrast':
    return 'dark';
  default:
    return 'light';
  }
};