import { useState } from 'react';
import { styled, Link, Box, Button, Card, Container, LinearProgress, CardContent, CircularProgress, TextField, Typography, Theme, useMediaQuery, useTheme, CardMedia} from '@mui/material';
import { EASCard } from '../eas-card/EASCard';

import { useAuthContext , useAppThemeContext } from '../../contexts';

import * as yup from 'yup';

const ContentStyle = styled('div')(() => ({
  maxWidth: 640,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  textAlign: 'center', 
  alignItems: 'center',
}));

const ContentStyleLogin = styled('div')(() => ({
  maxWidth: 730,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  textAlign: 'center', 
  alignItems: 'center',
}));

interface ILoginProps {
  children: React.ReactNode;
}
export const EASLogin: React.FC<ILoginProps> = ({ children }) => {

  const theme = useTheme();
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { themeName, emailName } = useAppThemeContext();
  const { isAuthenticated, login } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  const loginSchema = yup.object().shape({
    email: yup.string().required('O campo e-mail é obrigatório.').email('Informe um e-mail válido'),
    password: yup.string().required('Informe sua senha.'),
  });

  const handleSubmit = () => {
    setIsLoading(true);

    loginSchema
      .validate({ email, password }, { abortEarly: false })
      .then(dadosValidados => {
        login(dadosValidados.email, dadosValidados.password)
        .then(() => {
          setIsLoading(false);
        });
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false);

        errors.inner.forEach(error => {
          if (error.path === 'email') {
            setEmailError(error.message);
          } else if (error.path === 'password') {
            setPasswordError(error.message);
          }
        });
      });
  };

  if (isAuthenticated) return (
    <>{children}</>
  );
  
  
  if (isAuthenticated === false) 
  return (
    <Box width='100vw' display='flex' alignItems='center' justifyContent='center' sx={{overflowX:'hidden',}}>
      <Container>
        <ContentStyleLogin>
          <Card sx={{ display: 'flex', background: theme.palette.background.paper}}>
            {!xsDown && !smDown && !mdDown && (
            <CardMedia
              sx={{width:'300px', height:'548px'}}
              component="img"
              image="/login.jpg"
              alt="EASistemas Developers"
            />
            )}    
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Box display='flex' flexDirection='column' alignItems='left' gap={2}  sx={{p:'10px 20px'}}>
                  <Box
                    display='flex' 
                    flexDirection='column'
                    component="img"
                    alt="SBDC"
                    alignItems='center'
                    height={'100px'}
                    width={'250px'}
                    src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
                    sx={{alignContent:'center', m:'auto'}}
                  />
                  {emailName ? (
                    <>
                      <Typography align='left'>Não há nenhum curso para esta conta Microsoft.</Typography>
                      <Typography align='left'>Se o seu curso está vinculado a outra conta, modifique o usuário ativo em Gerenciar Contas.</Typography>
                      <Typography align='left'>Se você tem os dados de acesso, faça o login para continuar.</Typography>
                    </>
                  ) : (
                    <>
                      <Typography align='left'>Olá, bem vindo ao aplicativo Aura ExO Mentoring.</Typography>
                      <Typography align='left'>Para continuar você deverá informar seu login e senha fornecidos na compra se deu programa.</Typography>
                      <Typography align='left'>Se você tem os dados de acesso, faça o login para continuar.</Typography>
                    </>
                  )}
                  <Box display='flex' flexDirection='column' alignItems='left' gap={2} width='100%'>
                    <TextField
                      fullWidth
                      type='email'
                      label='Email'
                      value={email}
                      disabled={isLoading}
                      error={!!emailError}
                      helperText={emailError}
                      onKeyDown={() => setEmailError('')}
                      onChange={e => setEmail(e.target.value)}
                    />
                    <TextField
                      fullWidth
                      label='Senha'
                      type='password'
                      value={password}
                      disabled={isLoading}
                      error={!!passwordError}
                      helperText={passwordError}
                      onKeyDown={() => setPasswordError('')}
                      onChange={e => setPassword(e.target.value)}
                    />
                    <Button
                      variant='contained'
                      disabled={isLoading}
                      onClick={handleSubmit}
                      endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
                    >
                      Entrar
                    </Button>
                  </Box>
                </Box>
                <Link href="https://materiais.sbdc.com.br/organizational-mentoring-program" target="_blank" underline="hover" sx={{fontSize:'16px', p:'4px 0 0 20px', float:'left', textAlign:'left'}}>
                  Conheça os programas de mentoria Aura ExO
                </Link>
              </CardContent>
            </Box>
          </Card>
        </ContentStyleLogin>
      </Container>
    </Box>
  );


  return (
    <Box width='100vw' display='flex' alignItems='center' justifyContent='center' sx={{overflowX:'hidden',}}>
      <Container>
        <ContentStyle>
          <EASCard>
            <Typography variant="h4" paragraph>
              Efetuando seu Login!
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>
              Aguarde uns instantes, estamos sincronizando seu usuário para o login altomático.
              Este processo näo deve demorar mais que alguns segundos.
            </Typography>

            <Box
              component="img"
              src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
              sx={{  mx: 'auto', my: { xs: 5, sm: 5 } }}
            />
            <LinearProgress color="primary" />
          </EASCard>
        </ContentStyle>
      </Container>
    </Box> 
  );
};
