import { LayoutBaseDePagina } from '../../shared/layouts';
import { EASLearning } from '../../shared/components';

export const Learning = () => {

  return (
    <LayoutBaseDePagina
      titulo='&nbsp;Diário de Aprendizagem'
      iconTitle='historyEdu'
    >
      <EASLearning />
    </LayoutBaseDePagina>
  );
};
