import { useState , useEffect } from 'react';
import {Grid, Box, LinearProgress, Container } from '@mui/material/';

import { LayoutBaseDePagina } from '../../shared/layouts';
import { useCourseContext , useTeamContext} from '../../shared/contexts';
import { IDashboard } from '../../shared/contexts/DashboardContext/types';

import { EASPanelWelcome,
         EASPanelTips,
         EASPanelSliderWhats, 
         EASPanelCardsVideos, 
         EASPanelChart, 
         EASPanelCardsFiles, 
         EASPanelRowsWeblinks } from '../../shared/components';

import { DashboardService } from '../../shared/services/api/dashboard/DashboardService';


export const Dashboard = () => {

  const [isLoading, setIsLoading] = useState(true);
  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();
  const [dashboard, setDashboard] = useState<IDashboard | null>(null);

  useEffect(() => {
    async function getEvaluationsFinal() {
      if(enrollment.id_inscricao){
        setIsLoading(true);
        const dashboardServiceResponse = await DashboardService.getDashboardService((team.type_tean=='1' ? team.id_inscricao : enrollment.id_inscricao), team.id_equipe, team.id_mentoria_tean, team.type_tean);
        if (dashboardServiceResponse instanceof Error) {
          setIsLoading(false);
        } else {
          if(dashboardServiceResponse.data){
            const myDashboard = JSON.parse(JSON.stringify(dashboardServiceResponse.data));
            setDashboard(myDashboard);
            setIsLoading(false);              
          }
          else{
            setIsLoading(false);
          }
        }
      }
    }
    getEvaluationsFinal();
  }, [team.id_equipe]);

  return (
    <LayoutBaseDePagina
      titulo='&nbsp;Página inicial'
      iconTitle='how_to_reg'
    >
      
      <Container>
      <Box sx={{ flexGrow: 1 }}>
        {isLoading && (
        <LinearProgress variant='indeterminate' sx={{ mt:'15px'}} />
        )}
        {!isLoading && (
          <>
            <EASPanelWelcome /> 
            <EASPanelTips Tips={dashboard?.tips} />
            {/*
            <Grid container spacing={2}>
              <Grid item md={12} lg={6}>
                <EASPanelSliderWhats />
              </Grid>
              <Grid item md={12} lg={6}>
                <EASPanelChart chartItems={dashboard?.chart}/>
              </Grid>
             </Grid>
             */}
          </>
          /*
          <Grid container spacing={2}>
            <Grid item md={12} lg={8}>
              <EASPanelWelcome textTips={dashboard?.tips?.text_tips} />       
              <EASPanelCardsVideos videos={dashboard?.videos} />         
              <EASPanelCardsFiles files={dashboard?.files} />
            </Grid>
            <Grid item md={12} lg={4}>
              <EASPanelSliderWhats />
              <EASPanelChart chartItems={dashboard?.chart}/>
              <EASPanelRowsWeblinks weblinks={dashboard?.links} />
            </Grid>
          </Grid>
         */)}
      </Box>
      </Container>
    </LayoutBaseDePagina>
  );
};
