import { IInfluencer } from '../../../contexts/InfluencersContext/types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';

interface IInfluencersService {
  Teams?: string;
}

interface IInfluencersListResponse extends IInfluencer {
  page?: number | null;
  per_page?: number | null;
  total?: number | null;
  total_pages?: number | null;
  data?: IInfluencer | null;
}


const createInfluencers = async (influencers: object): Promise<IInfluencersService | Error> => {
  try {
    const { data } = await Api.post('/influencers', { influencers: influencers } );
  
    if (data) 
      return data;
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateInfluencers = async (Influencers: IInfluencer): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/influencers/${Influencers.id_influencer}`, Influencers);
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const moveInfluencer = async (Influencers: IInfluencer): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/influencers/${Influencers.id_influencer}`, Influencers);
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const deleteInfluencers = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api.delete(`/influencers/${id}`);
    if (data) 
      return data;   
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getInfluencers = async (userEnrollment: string, client_id: string): Promise<IInfluencersService | Error> => {
  try {
    const { data } = await Api.post('/influencers', { userEnrollment: userEnrollment, client_id: client_id } );
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const getAllInfluencers = async (id_inscricao = '', id_equipe = '', id_mentoria_tean = '', filterInfluencers = 0, page = 1, filter = '' ): Promise<IInfluencersListResponse | Error> => {
  try {
    if(!id_inscricao || !id_equipe || !id_mentoria_tean)
      return new Error('Requisição imcompleta.');

    const urlRelativa = `/influencers?public_token=${Environment.PUBLIC_TOKEN}&i=${id_inscricao}&e=${id_equipe}&t=${id_mentoria_tean}&f=${filterInfluencers}&page=${page}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${filter}`;

    const  {data} = await Api.get(urlRelativa);
    
    if (data) {
      return data;
    }
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

export const InfluencersService = {
  getAllInfluencers,
  getInfluencers,
  createInfluencers,
  updateInfluencers,
  deleteInfluencers,
  moveInfluencer,

};
