import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IResultsPresentation, IPresentationFinal } from '../../../contexts/ResultsPresentationContext/types';

interface IResultsPresentationService {
  id?: string;
}

interface IResultsPresentationListResponse extends IResultsPresentation {
  total?: number | null;
  data?: IResultsPresentation | null;
}


const createResultsPresentation = async (presentationFinal: IPresentationFinal): Promise<IResultsPresentationService | Error> => {
  try {
    const { data } = await Api.post('/resultspresentation', { presentationFinal: presentationFinal } );
  
    if (data) 
      return data;

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateResultsPresentation = async (presentationFinal: IPresentationFinal): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/resultspresentation/${presentationFinal.id_presentation_final}`, presentationFinal);
    
    if (data) 
      return data;  

  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const deleteResultsPresentation = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api.delete(`/resultspresentation/${id}`);
    if (data) 
      return data;   
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getResultsPresentation = async (userEnrollment: string, client_id: string): Promise<IResultsPresentationService | Error> => {
  try {
    const { data } = await Api.post('/objectivedetail', { userEnrollment: userEnrollment, client_id: client_id } );
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getAllResultsPresentation = async (id_inscricao = '', id_equipe = '', id_mentoria_tean = '', filterResultsPresentation = 0, page = 1, filter = '' ): Promise<IResultsPresentationListResponse | Error> => {
  try {
    if(!id_inscricao || !id_equipe || !id_mentoria_tean)
      return new Error('Requisição imcompleta.');

    const urlRelativa = `/resultspresentation?public_token=${Environment.PUBLIC_TOKEN}&i=${id_inscricao}&e=${id_equipe}&t=${id_mentoria_tean}&f=${filterResultsPresentation}&page=${page}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${filter}`;

    const  {data} = await Api.get(urlRelativa);
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

export const ResultsPresentationService = {
  getAllResultsPresentation,
  getResultsPresentation,
  createResultsPresentation,
  updateResultsPresentation,
  deleteResultsPresentation,
};
