import { Api } from '../axios-config';

interface ITeams {
  Teams?: string;
}

const getTeams = async (userEnrollment: string, client_id: string): Promise<ITeams | Error> => {
  try {
    const { data } = await Api.post('/courseteams', { userEnrollment: userEnrollment, client_id: client_id } );
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

export const TeamService = {
  getTeams,
};
