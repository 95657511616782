import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IWeblink } from '../../../contexts/WeblinksContext/types';

interface IWeblinksListResponse extends IWeblink {
  page?: number | null;
  per_page?: number | null;
  total?: number | null;
  total_pages?: number | null;
  data?: Array<IWeblink> | null;
}



const getAllWeblinks = async (id_inscricao = '', id_equipe = '', id_mentoria_tean = '', filter = '', page = 1 ): Promise<IWeblinksListResponse | Error> => {
  try {
   
    if(!id_inscricao || !id_equipe || !id_mentoria_tean)
      return new Error('Requisição imcompleta.');

    const urlRelativa = `/weblinks?public_token=${Environment.PUBLIC_TOKEN}&i=${id_inscricao}&e=${id_equipe}&t=${id_mentoria_tean}&f=${filter}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}`;

    const  {data} = await Api.get(urlRelativa);
  
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


export const WeblinksService = {
  getAllWeblinks,
};
