import React, { useState , useEffect } from 'react';

import {Rating, Grid, Paper, Typography, StepButton, ListItemAvatar, Avatar, Box, Container, List, ListItem, Button, ListItemText, LinearProgress  } from '@mui/material/';


import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import { IEvaluationPartial, IEvaluationPartialDetailResponse, IUserEvaluationPartialExternal } from '../../contexts/EvaluationPartialContext/types';

import { EASEvaluationPartialEditItem } from './EASEvaluationPartialEditItem';
import { EASEvaluationPartialEditText } from './EASEvaluationPartialEditText';

import { EvaluationPartialExternalService } from '../../services/api/evaluation-partial-external/EvaluationPartialExternalService';

import { useSnackbar } from 'notistack';
import { EASCard } from '../eas-card/EASCard';

interface IEvaluationPartialEditProps {
  children: React.ReactNode;
}  

export const EASEvaluationPartialExternal: React.FC<IEvaluationPartialEditProps> = ({ children }) => {

  const { enqueueSnackbar } = useSnackbar();
  const [allEvaluationPartialDetailResponse, setAllEvaluationPartialDetailResponse] = useState<Array<IEvaluationPartialDetailResponse> | null>(null);

  const [activeStep, setActiveStep] = useState(0);

  const [idEvaluationPartial, setIdEvaluationPartial] = useState<string>('');
  //const [dateEvaluationPartial, setDateEvaluationPartial] = useState<string>('');
 // const [textEvaluationPartial, setTextEvaluationPartial] = useState<string>('');
 // const [typeEvaluationPartial, setTypeEvaluationPartial] = useState<string>('');
  const [statusEvaluationPartial, setStatusEvaluationPartial] = useState<string>('');

  const [userEvaluationPartial, setUserEvaluationPartial] = useState<IUserEvaluationPartialExternal | null>(null);

  const [externalEvaluation, setExternalEvaluation] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {

    async function getEvaluationsPartial() {
      setIsLoading(true);

      const search = window.location.search;
      const params = new URLSearchParams(search);
      const foo = params.get('evaluation');

      if(foo){
        setExternalEvaluation(foo?.toString());

        const evaluationPartialResponse = await EvaluationPartialExternalService.getAllEvaluationPartialExternal(foo.toString());
        if (evaluationPartialResponse instanceof Error) {
          setAllEvaluationPartialDetailResponse(null);
          setIsLoading(false);
          enqueueSnackbar('Não foi possivel carregar a Avaliação parcial', { variant:'error'});
        } else {
          if(evaluationPartialResponse.data && evaluationPartialResponse.total){
            const myEvaluationPartial = JSON.parse(JSON.stringify(evaluationPartialResponse.data));
            setAllEvaluationPartialDetailResponse(myEvaluationPartial);
            setIsLoading(false);

            if(evaluationPartialResponse.id_evaluation_partial)
              setIdEvaluationPartial(evaluationPartialResponse.id_evaluation_partial);
            
           // if(evaluationPartialResponse.date_evaluation_partial)
           //   setDateEvaluationPartial(evaluationPartialResponse.date_evaluation_partial);
            
           // if(evaluationPartialResponse.text_evaluation_partial)
           //   setTextEvaluationPartial(evaluationPartialResponse.text_evaluation_partial);

           // if(evaluationPartialResponse.type_evaluation_partial)
           //   setTypeEvaluationPartial(evaluationPartialResponse.type_evaluation_partial);
            
            if(evaluationPartialResponse.status_evaluation_partial)
              setStatusEvaluationPartial(evaluationPartialResponse.status_evaluation_partial);  

            if(evaluationPartialResponse.user)
              setUserEvaluationPartial(evaluationPartialResponse.user); 
              
              
          }
          else{
            setAllEvaluationPartialDetailResponse(null);
            setIsLoading(false);
          }
        }
      }
    }
    getEvaluationsPartial();

  }, []);


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if((allEvaluationPartialDetailResponse && (activeStep >= (allEvaluationPartialDetailResponse.length - 1)))) {
      setIsLoading(true);
      if(idEvaluationPartial) {
        const evaluationPartial: IEvaluationPartial = {
          id_evaluation_partial: idEvaluationPartial,
          status_evaluation_partial:'2'
        };
        EvaluationPartialExternalService.updateEvaluationPartial(evaluationPartial).then((responseEvaluationPartialDetail) => {
          if (responseEvaluationPartialDetail instanceof Error) {
            enqueueSnackbar('Erro ao tentar finalizar avaliação parcial!', { variant:'error'});
          } else {
            enqueueSnackbar('Avaliação parcial finalizada com sucesso', { variant:'success'});
            setStatusEvaluationPartial('2');
          }
          setIsLoading(false);
        });
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };


  return ( 
  <>
    {externalEvaluation == '' ? children :
    (
        <Container>
        {isLoading && (
          <LinearProgress variant='indeterminate' sx={{ mt:'15px'}} />
        )}
          {(!isLoading && userEvaluationPartial) && (
          <>
          <Box style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0' }}>
            <EASCard>
              <ListItem sx={{p:0,m:0}}>
                <ListItemAvatar sx={{marginRight:'15px'}}>
                  <Avatar sx={{ border:'1px solid #EA5B0C', height: '60px', width: '60px' }} 
                            alt={userEvaluationPartial.name} 
                            src={ userEvaluationPartial.avatar && ('https://online.sbdc.com.br/media/images/avatar/' + userEvaluationPartial.avatar)} />
                </ListItemAvatar>
                <ListItemText  sx={{p:0, m:0}}
                  primary={<Typography variant="h6" sx={{lineHeight: '20px', fontSize:'18px'}}>{userEvaluationPartial.name}</Typography>} 
                  secondary={<Box>
                              <Box>
                                <Typography  sx={{fontSize:'15px'}} >
                                  <strong>Mentorado no {userEvaluationPartial.curso}</strong>
                                </Typography> 
                              </Box>
                              <Box>
                                <Typography  sx={{fontSize:'12px'}} >
                                  Solicitação de Avaliação Parcial
                                </Typography> 
                              </Box>
                            </Box>}
                  />
              </ListItem> 
              <Typography variant="h6" sx={{fontSize:'18px', lineHeight: '20px',textAlign:'center', mb:1, mt:1}}>
                Você recebeu um convite para realizar a avaliação parcial do processo de mentoria de {userEvaluationPartial.name}
              </Typography>
              <Typography sx={{fontSize:'14px', lineHeight: '20px',textAlign:'center', mb:1, mt:1}}>
                Abaixo estão as competências trabalhadas na mentoria com sua respectiva nota inicial. Indique, em cada item, como você percebe a evolução deste(a) mentorado(a), conforme indicado abaixo.
              </Typography>
              <Typography sx={{fontSize:'14px', lineHeight: '20px',textAlign:'center', mb:1, mt:1}}>
                Obs.: Importante lembrar que esta avaliação é realizada em conceitos (ex.: percebido, pouco percebido), e não em notas.
              </Typography>
              <Grid container style={{ display: 'flex', justifyContent: 'center'}} spacing={1}>
                <Grid item xs={6} sx={{textAlign:'right', height:'20px'}}>
                  <Rating 
                    value={1}
                    readOnly={true}
                    max={1}
                  />
                </Grid>
                <Grid item xs={6}  sx={{textAlign:'left', mt:'5px', height:'20px'}}>
                  <Typography  sx={{fontSize:'12px'}} >
                    Não Percebido
                  </Typography> 
                </Grid>
                <Grid item xs={6} sx={{textAlign:'right', height:'20px'}}>
                  <Rating 
                    value={2}
                    readOnly={true}
                    max={2}
                  />
                </Grid>
                <Grid item xs={6}  sx={{textAlign:'left', mt:'5px', height:'20px'}}>
                  <Typography  sx={{fontSize:'12px'}} >
                    Pouco Percebido
                  </Typography> 
                </Grid>
                <Grid item xs={6} sx={{textAlign:'right', height:'20px'}}>
                  <Rating 
                    value={3}
                    readOnly={true}
                    max={3}
                  />
                </Grid>
                <Grid item xs={6}  sx={{textAlign:'left', mt:'5px', height:'20px'}}>
                  <Typography  sx={{fontSize:'12px'}} >
                    Percebido
                  </Typography> 
                </Grid>
                <Grid item xs={6} sx={{textAlign:'right', height:'20px'}}>
                  <Rating 
                    value={4}
                    readOnly={true}
                    max={4}
                  />
                </Grid>
                <Grid item xs={6}  sx={{textAlign:'left', mt:'5px', height:'20px'}}>
                  <Typography  sx={{fontSize:'12px'}} >
                    Muito Percebido
                  </Typography> 
                </Grid>
                <Grid item xs={6} sx={{textAlign:'right', height:'20px'}}>
                  <Rating 
                    value={5}
                    readOnly={true}
                    max={5}
                  />
                </Grid>
                <Grid item xs={6}  sx={{textAlign:'left', mt:'5px', height:'20px'}}>
                  <Typography  sx={{fontSize:'12px'}} >
                    Excedeu as Expectativas
                  </Typography> 
                </Grid>
              </Grid>
            </EASCard>
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0' }}>
          <EASCard>
            <Stepper activeStep={activeStep} orientation="vertical" nonLinear={(statusEvaluationPartial !== '2')  ? false : true} >
              {allEvaluationPartialDetailResponse && (allEvaluationPartialDetailResponse.map((objective, index) => (
                <Step key={objective.id_objective}  completed={statusEvaluationPartial === '2' || activeStep > index ? true : false} >
                  {(statusEvaluationPartial === '2') ? (
                    <StepButton color="inherit" onClick={handleStep(index)}>
                    {objective.text_objective}
                    </StepButton>
                  ) : (
                    <StepLabel>
                    {objective.text_objective}
                    </StepLabel>
                  )}
                  <StepContent>
                    <List>
                    {objective.evaluation_partial_detail && (objective.evaluation_partial_detail.map((evaluationPartialDetail, index) => (
                      <EASEvaluationPartialEditItem key={evaluationPartialDetail.id_objective_detail} evaluationPartialDetail={evaluationPartialDetail} typeEvaluationPartial={'0'} statusEvaluationPartial={statusEvaluationPartial} teamEvaluationPartial={'0'}/>
                    )))}
                    </List>
                    
                    <EASEvaluationPartialEditText 
                      disabled={(statusEvaluationPartial === '2')} 
                      evaluationPartialComment={objective.evaluation_partial_comment} 
                    />
                    
                    {((statusEvaluationPartial !== '2')) && (
                    <Box sx={{ mb: 2 }}>
                      <div>
                       
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === allEvaluationPartialDetailResponse.length - 1 ? 'Finalizar Avaliação' : 'Próxima Competência'}
                        </Button>
                       
                        {index !== 0 && allEvaluationPartialDetailResponse.length > 1 && (
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Voltar a Competência Anterior
                        </Button>
                          )}
                      </div>
                    </Box>
                    )}
                  </StepContent>
                </Step>
              )))}
            </Stepper>
            {((statusEvaluationPartial === '2')  && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography variant="h5">Esta avaliação parcial foi marcada como concluída</Typography>
            </Paper>
            ))}
          </EASCard>
        </Box> 
          </>
        )}
        </Container>
    )} 
  </>
  );
};