
import React, { useState } from 'react';
import {Box, Typography, Container, Alert, List } from '@mui/material/';

import { useTeamContext, useCourseContext } from '../../contexts';
import { EASInfluencersEvaluationsEdit } from './EASInfluencersEvaluationsEdit';
import { EASInfluencersEvaluationsItem } from './EASInfluencersEvaluationsItem';

import { IInfluencer } from '../../contexts/InfluencersContext/types';

interface IInfluencersEvaluationsProps {
  allInfluencers: Array<IInfluencer> | null;
  setAllInfluencers:(allInfluencers : Array<IInfluencer>) => void;
  updateList:() => void;
}  

export const EASInfluencersEvaluations: React.FC<IInfluencersEvaluationsProps> = ({ allInfluencers, setAllInfluencers, updateList }) => {

  const [influencer, setInfluencer] = useState<IInfluencer | null>(null);

  const { ...enrollment } = useCourseContext();
  const { ...team } = useTeamContext();

  const updateInfluencer = (influencerUpdate?:IInfluencer) => {
    if (influencerUpdate && allInfluencers) {
      const newAllInfluencers = allInfluencers.map(influencer => {
        if (influencerUpdate.id_influencer !== influencer.id_influencer) {
          return influencer;
        } else {
          return influencerUpdate;
        }
      });
      setAllInfluencers(newAllInfluencers);
    }
  };


  const handleClickCancelEdit = () => {
      setInfluencer(null);
  };
   
  const finishEditInfluencer = () => {
    setInfluencer(null);
    updateList();
  };


  return (
    <Container>
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" sx={{textAlign: 'center', marginBottom:'5px'}}>
          Quais são os rótulos positivos e negativos que estes influenciadores têm a seu respeito, na sua percepção?
        </Typography>
      </Box>
      {(team.type_tean=='1' && team.status_mentory_inscricao =='1' || team.type_tean=='0' && enrollment.status_mentory_inscricao =='1' ) ? (
      <Box sx={{ p: 3 }}>
        <Typography variant="h5">Este programa foi encerrado</Typography>
        {((team.type_tean === '0')  && (
          <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
        ))}
      </Box>
      ):(
      <Box sx={{ p: 3 }}>
          
      </Box>
      )}
      <EASInfluencersEvaluationsEdit finishEditInfluencer={() => finishEditInfluencer()} cancelEditInfluencer={() => handleClickCancelEdit()} influencer={influencer} />
      <List>
          {(allInfluencers && allInfluencers.length) ? (allInfluencers.map((influencer) => (
          <EASInfluencersEvaluationsItem
            key={influencer.id_influencer} 
            influencer={influencer} 
            updateListItem={() => updateList()} 
            setInfluencer={(e) => setInfluencer(e)} 
            //handleClickDelete={(e) => handleClickDelete(e)}
            updateInfluencer={(e) => updateInfluencer(e)}
          />
          ))):(
          <Alert severity="warning">Nenhum influenciador cadastrado!</Alert>
          )}
      </List>
    </Container>
  );
};