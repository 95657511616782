import { EASCard } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { EASEvaluationPartial } from '../../shared/components';

export const EvaluationPartial = () => {

  return (
    <LayoutBaseDePagina
      titulo='&nbsp;Avaliação Parcial'
      iconTitle='person_search'
    >
      <EASEvaluationPartial />
    </LayoutBaseDePagina>
  );
};
