
import React from 'react';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { EASLibrary } from '../../shared/components';

export const Library = () => {


  return (
    <LayoutBaseDePagina
      titulo='&nbsp;Biblioteca'
      iconTitle='video_library'
    >
      <EASLibrary />
    </LayoutBaseDePagina>
  );
};
