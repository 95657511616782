import React, { useState, MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import { MenuItem, Menu, Typography,  Avatar, Box, useTheme, IconButton, Grid, Link } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTeamContext  } from '../../contexts';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const SBDCBoxSidebar = styled(Box)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '16px',
  margin:'8px 16px',
  borderRadius:'4px',
  lineHeight: 1.5,
  backgroundColor: 'transparent',
  color:'#000000',
  border: '1px solid #EA5B0C',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ]
});



interface IEASBtnSidebarProps {
  onClick: (() => void) | undefined;
}

export const EASBoxSidebar: React.FC<IEASBtnSidebarProps> = ({ onClick }) => {

  const theme = useTheme();
  const { ...team } = useTeamContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleTeamSelect = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <SBDCBoxSidebar width={theme.spacing(40)} >

{team.allTeams != null && team.allTeams.length > 1 && (
      <Box sx={{ position:'absolute', right:'16px' , marginTop:'-15px', display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          onClick={handleTeamSelect}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
        {(team.allTeams.map((teamSelect) => (
          teamSelect.id_equipe != team.id_equipe && (
          <MenuItem key={teamSelect.id_inscricao} onClick={() => team.accessTeam(teamSelect)}>
            <Avatar sx={{ border:'3px solid #EA5B0C', height: theme.spacing(9), width: theme.spacing(9) }} 
                  alt={teamSelect.name} 
                  src={ teamSelect.avatar ? 'https://online.sbdc.com.br/media/images/avatar/' + teamSelect.avatar : ''} />
            <Box>
              <Typography  sx={{fontSize:'12px'}} >
                <strong>{teamSelect.name}</strong>
              </Typography>
              <Typography  sx={{fontSize:'8px'}} >
                  {teamSelect.type_tean === '1' ? 'MENTORADO' : 'MENTOR'}
              </Typography>  
            </Box>
          </MenuItem>
          ))))}
        </Menu>
      </Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={4.5}>
          <Avatar sx={{ border:'3px solid #EA5B0C', height: theme.spacing(9), width: theme.spacing(9) }} 
                  alt={team.name} 
                  src={team.avatar ? 'https://online.sbdc.com.br/media/images/avatar/' + team.avatar : ''} />
        </Grid>
        <Grid item xs={7.5}>
          <Box>
            <Typography  sx={{mt:'3px', fontSize:'14px'}} >
              <strong>{team.type_tean === '1' ? 'MENTORADO' : 'MENTOR'}</strong>
            </Typography> 
          </Box>
          <Box>
            <Typography  sx={{fontSize:'12px'}} >
              {team.name}
            </Typography> 
          </Box>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={1.5} sx={{mt:'3px'}}>
                <WhatsAppIcon sx={{ fontSize:'18px', color:'#EA5B0C'}} />
              </Grid>
              <Grid item xs={8}>
                <Typography sx={{ mt:'3px',fontSize:'14px'}}>
                {team.phone}
                </Typography> 
              </Grid>
            </Grid>
          </Box>
          {/*
          <Box>
            <SBDCButtonChat onClick={handleClick}>
              Iniciar Chat
            </SBDCButtonChat>
          </Box>*/}
        </Grid> 
      </Grid>
    </SBDCBoxSidebar>
  );
};
   

